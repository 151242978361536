import React,{useState} from 'react';
import styled from 'styled-components';
import { Footer, Navbar } from '../../components';
import axios from 'axios';

let data = [
  { id: 1, title: "Marketing Manager", location: "Perinthalmanna,Chavakad,Kondotty,Malapuram", skils: ["Strategic thinking and planning", "Digital marketing proficiency (SEO, SEM, social media)", "Market research and analysis", "Strong communication and interpersonal skills", "Campaign management and budgeting"] },
  { id: 2, title: "Registered Nurse", location: "Cochin,Guruvayur,Perinthalmanna", skils: ["Patient care and bedside manner", "Clinical knowledge and expertise", "Critical thinking and decision-making in healthcare settings", "Attention to detail and accuracy in documentation", "Ability to work under pressure and in a team environment"] },
  { id: 3, title: "Financial Analyst", location: "Kottakal,Manarkkad,Ottapalam", skils: ["Financial modeling and forecasting", "Data analysis and proficiency in Excel or other analytical tools", "Understanding of financial statements and ratios", "Risk management and investment analysis", "Knowledge of economic trends and market conditions"] },
  { id: 4, title: "Graphic Designer", location: "Ponnani,Cochin,Trivandrum,Vytila", skils: ["Proficiency in design software (Adobe Creative Suite, Sketch)", "Creativity and artistic ability", "Typography and layout design skills", "Attention to detail and aesthetics", "Ability to interpret client needs and collaborate with teams"] },
  { id: 5, title: "Software Engineer", location: "Cochin,Trissure,Kozikode,Trivandrum", skils: ["Proficiency in programming languages (e.g., Python, Java, C++)", "Problem-solving and debugging skills", "Knowledge of software development methodologies (Agile, Scrum)", "Familiarity with version control systems (e.g., Git)", "Understanding of data structures and algorithms"] }
]
export default function Career() {
  const handleApplyNowClick = (title, location) => {
    const fixedWhatsAppNumber = '8301829642';
    const encodedText = encodeURIComponent(`Job Description: ${title}%0ALocation: ${location}`);
    const whatsappWebURL = `https://web.whatsapp.com/send?phone=${fixedWhatsAppNumber}&text=${encodedText}`;

    window.open(whatsappWebURL, '_blank');
  };

  return (
    <div className="mainDiv">
      <div className="Navbar">
        <Navbar />
      </div><br /><br />
      <div className='container'>
        <Wrapper>
          <h1>Building Your Career in Life</h1>
          <h2>Navigating Choices, Challenges, and Opportunities</h2>
          <ContentWrapper>
            {data.map((item, i) => {
              return (
                <CareerBox key={i}>
                  <h1 className='title'>{item.title}</h1><br/>
                  <h2 className='sub'><b>Location : </b>{item.location}</h2><br/>
                  <ListWrapper>
                  <h2 className='title' style={{fontSize:"25px"}}><b>Skills</b></h2>
                    {item.skils.map((list, i) => {
                      return (
                        <div className='flex-row' key={i}><i style={{ color: "#38b772" }} className="fa fa-check-circle-o text" /><p className='text'>{list}</p></div>
                      )
                    })}
                  </ListWrapper><br/>
                  <Button onClick={() => handleApplyNowClick(item.title, item.location)}>Apply Now</Button>
                </CareerBox>
              )
            })}
          </ContentWrapper>
        </Wrapper>
      </div>
      <Footer />
    </div>
  )
}
const Wrapper = styled.div`
    margin-top: 120px;
    h1 {
        font-size: 35px;
        font-weight: 700;
        color: #38b772;
    }
    h2 {
        font-size: 16px;
        font-weight: 400;
        color: #494949;
    }
`;
const ContentWrapper = styled.div`
padding:60px 0;
display:flex;
flex-direction:column;
gap:20px;
`;
const CareerBox = styled.div`
padding:20px;
border:1px solid #879b8f;
border-radius:12px;
width:100%;
display:flex;
flex-direction:column;
align-items:left;
.title {
font-size:30px;
font-weight:700;
 color: #38b772;
 text-align:left;
}
.sub {
font-size: 16px;
font-weight: 400;
color: #879b8f;
 text-align:left;
}
.row {
display:flex;
flex-directrion:row;
gap:10px;
}
`;
const ListWrapper = styled.div`
display:flex;
flex-direction:column;
gap:10px;
margin-top:30px;
&:first-child {
  margin-top:50px;
}
.flex-row{
  display:flex;
  flex-direction:row;
  gap:20px;
  align-items:baseline;
}
.text {
  color: #879b8f;
  text-align:left;
  // line-height:30px;
}
`;
const Button = styled.button`
  cursor: pointer;
  background-color: #38b772;
  color: #fff;
  border-radius: 10px;
  padding: 10px 20px;
  width: fit-content;
  border: 0px solid;
  text-decoration:none;
  box-shadow: inset 0 0 0 0 #38b772;
  transition: color .5s ease-in-out, box-shadow .5s ease-in-out;
  &:hover {
    background-color: #fff;
    border: 1px solid #38b772;
    color: #38b772;
    box-shadow: inset 200px 0 0 0 #fff;
  }
`;