import React, { useState } from 'react';
import styled from 'styled-components';
import Modal from '../Modal/Modal';
import InputBox from '../InputBox/InputBox';
import SelectBox from '../SelectBox/SelectBox';
import TextArea from '../TextArea/TextArea';

const countryData = [
    { id: 1, label: "Australia" },
    { id: 2, label: "United Kingdom" },
    { id: 3, label: "United States" },
    { id: 4, label: "Switzerland" },
    { id: 5, label: "Sweden" },
    { id: 6, label: "Ireland" },
    { id: 7, label: "Georgia" },
    { id: 8, label: "France" },
    { id: 8, label: "China" },
    { id: 8, label: "Canada" },
];
export default function SubNavbar() {
    const [isOpen, setIsOpen] = useState(false);
    const [country, setCountry] = useState(null);
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        phone: "",
        message: "",
    });
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };
    const handleSendEmail = () => {
        const recipientEmail = "saneen577@gmail.com"; // Update with the recipient's email address
        const subject = "New Message from contact us page";
        const body = `Name: ${formData.name}\nEmail: ${formData.email}\nPhone: ${formData.phone}\Country: ${country}\Message: ${formData.message}`;

        // Open default email client with pre-filled email template
        window.open(
            `mailto:${recipientEmail}?subject=${encodeURIComponent(
                subject
            )}&body=${encodeURIComponent(body)}`
        );
        // Clear form data
        setFormData({
            name: "",
            email: "",
            phone: "",
            message: "",
        });
        setCountry(null);

        // Optionally, you can redirect or perform any other action after sending the email
    };
    return (
        <Wrapper>
            <ContactRow>
                <div className='row'>
                    <span class="material-icons">call</span>
                    <span>+91 9876543210</span>
                </div>
                <div className='line'></div>
                <div className='row'>
                    <span class="material-icons">mail</span>
                    <span>sample@gmail.com</span>
                </div>
                <div className='line'></div>
                <div className='row'>
                    <i class="fa fa-whatsapp" style={{ fontSize: "24px", color: "#fff" }}></i>
                    <span>+91 8765432109</span>
                </div>
            </ContactRow>
            <TalkRow>
                <i class="fa fa-facebook-square" style={{ fontSize: "24px", color: "#fff" }}></i>
                <i class="fa fa-instagram" style={{ fontSize: "24px", color: "#fff" }}></i>
                <Button onClick={() => setIsOpen(!isOpen)}>Let's Talk</Button>
            </TalkRow>
            <Modal
                isVisible={isOpen}
                isBackdrop={true}
                close={(value) => setIsOpen(value)}
            >
                <ModalWrapper>
                    <MsgBox>
                        <Header>
                            <Title>Aeron Contacts</Title>
                            <CloseButton onClick={() => setIsOpen(false)} className="material-icons">cancel</CloseButton>
                        </Header>
                        <div className='row' style={{marginTop:20}}>
                            <div className='col-lg-6 col-sm-6 col-xs-12'>
                                <InputBox
                                    type="text"
                                    placeholder="Name"
                                    value={formData.name}
                                    onChange={handleChange}
                                    name="name"
                                />
                            </div>
                            <div className='col-lg-6 col-sm-6 col-xs-12'>
                                <InputBox
                                    type="text"
                                    placeholder="Phone"
                                    value={formData.email}
                                    onChange={handleChange}
                                    name="phone"
                                />
                            </div>
                            <div className='col-lg-6 col-sm-6 col-xs-12'>
                                <InputBox
                                    type="email"
                                    placeholder="Enter Your Email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    name="email"
                                />
                            </div>
                            <div className='col-lg-6 col-sm-6 col-xs-12' style={{marginTop:10}}>
                                <SelectBox
                                    placeholder="Prefered Country"
                                    data={countryData}
                                    value={country}
                                    onSelect={setCountry}
                                />
                            </div>
                            <div className='col-12'>
                                <TextArea
                                    name="message"
                                    id="message"
                                    placeholder="Enter Message"
                                    value={formData.message}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                        <FooterButton>
                            <ModalButton onClick={handleSendEmail}>Send</ModalButton>
                        </FooterButton>
                    </MsgBox>
                </ModalWrapper>
            </Modal>
        </Wrapper>
    )
}
const Wrapper = styled.div`
display: flex;
flex-direction: row;
justify-content: space-between;
align-items:center;
background-color:#1a3325;
padding:20px;
@media (max-width: 576px){
    justify-content: center;
}
`;
const ContactRow = styled.div`
display: flex;
flex-direction: row;
align-items: center;
gap:10px;
justify-content: center; 
@media (max-width: 576px){
    flex-direction: column;
    background-color: #2a473b;
    padding: 15px;
    border-radius: 10px;
    margin-bottom: 10px;
}
.line {
    border:1px solid #879b8f;
    height: 10vh;
    @media (max-width: 568px){
        height:auto;
        width:200px;
    }
}
.row {
    display: flex;
    flex-direction: row;
    gap:5px;
    span {
        color:#fff;
    }
    i {
        color:#fff;
    }
}
`;
const TalkRow = styled.div`
display: flex;
flex-direction: row;
align-items: center;
gap:10px;
@media (max-width: 768px){
    display:none;
}
`;
const Button = styled.div`
cursor: pointer;
background-color: #38b772;
color: #fff;
border-radius: 10px;
padding: 10px 20px;
width: fit-content;
border: 0px solid;
text-decoration:none;
box-shadow: inset 0 0 0 0 #38b772;
  transition: color .5s ease-in-out, box-shadow .5s ease-in-out;
  &:hover {
    background-color: #fff;
    border: 1px solid #38b772;
    color: #38b772;
    box-shadow: inset 200px 0 0 0 #fff;
  }
`;
const ModalWrapper = styled.div`
position: absolute;
margin: auto;
left: 50%;
right: auto;
top: 50%;
bottom: auto;
transform: translate(-50%, -50%);
background-color: #fff;
border-radius: 10px;
width: 500px;
@media (max-width: 576px) {
  width: 90%;
  height:auto;
}
button {
  margin: auto;
  display: block;
  }
  .flex-row {
    display: flex;
    align-items: center;
  }
`;
const MsgBox = styled.div`
  background: ${(p) => p.theme.colorWhite};
  border-radius: 10px;
  padding: 16px;
`;
const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;
`;
const Title = styled.h3`
  margin: 0;
  font-weight: 600;
  font-size: 20px;
  color: #494949;
`;
const CloseButton = styled.span`
  font-weight: 600;
  font-size: 20px;
  line-height: 17px;
  color: red;
  cursor: pointer;
`;
const FooterButton = styled.div`
  margin-top: 28px;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;
const ModalButton = styled.button`
cursor: pointer;
background-color: #38b772;
color: #fff;
border-radius: 10px;
padding: 10px 20px;
width: fit-content;
border: 0px solid;
text-decoration:none;
box-shadow: inset 0 0 0 0 #38b772;
  transition: color .5s ease-in-out, box-shadow .5s ease-in-out;
  &:hover {
    background-color: #fff;
    border: 1px solid #38b772;
    color: #38b772;
    box-shadow: inset 200px 0 0 0 #fff;
  }
`;