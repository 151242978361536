import React,{useState,useEffect} from "react";
import "./About.css";
import { Navbar, Footer } from "../../components";
import { Aboutus } from "../../assets/images";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

function About() {
  const navigate = useNavigate(); // Initialize navigate
  const handleHomeOpenClick = () => {
    navigate("/");
  };
  
  const [visaCount, setVisaCount] = useState(0);
  const [universitiesCount, setUniversitiesCount] = useState(0);
  const [countriesCount, setCountriesCount] = useState(0);
  const [coursesCount, setCoursesCount] = useState(0);
  useEffect(() => {
    const finalValues = {
      visaCount: 50000,
      universitiesCount: 80,
      countriesCount: 29,
      coursesCount: 300,
    };
    const animationDuration = 5000; // Duration of animation in milliseconds
    const incrementPerKey = {};

    // Calculate increment per key
    for (const key in finalValues) {
      incrementPerKey[key] = finalValues[key] / (animationDuration / 100);
    }

    let currentValues = {
      visaCount: 0,
      universitiesCount: 0,
      countriesCount: 0,
      coursesCount: 0,
    };

    const timer = setInterval(() => {
      // Update counts for each key until it reaches the final value
      for (const key in finalValues) {
        currentValues[key] += incrementPerKey[key];
        const roundedValue = Math.round(currentValues[key]);
        if (roundedValue <= finalValues[key]) {
          switch (key) {
            case "visaCount":
              setVisaCount(roundedValue);
              break;
            case "universitiesCount":
              setUniversitiesCount(roundedValue);
              break;
            case "countriesCount":
              setCountriesCount(roundedValue);
              break;
            case "coursesCount":
              setCoursesCount(roundedValue);
              break;
            default:
              break;
          }
        } else {
          // If the final value is reached, set the state to the final value and clear the interval
          switch (key) {
            case "visaCount":
              setVisaCount(finalValues[key]);
              break;
            case "universitiesCount":
              setUniversitiesCount(finalValues[key]);
              break;
            case "countriesCount":
              setCountriesCount(finalValues[key]);
              break;
            case "coursesCount":
              setCoursesCount(finalValues[key]);
              break;
            default:
              break;
          }
          clearInterval(timer);
        }
      }
    }, 100); // Run every 100 milliseconds

    return () => clearInterval(timer); // Cleanup on component unmount
  }, []); // Empty dependency array ensures the effect runs only once

  // const [modalOpen, setModalOpen] = useState(false);

  // useEffect(() => {
  //   setModalOpen(true); 
  // }, []);

  return (
    <div className="mainDiv">
      <div className="Navbar">
        <Navbar />
      </div><br /><br />
      {/* <ModalEvent isOpenInitially={modalOpen} /> */}
      <div className="aboutMainDiv">
        <div className="aboutMainDivSub">
          <div className="aboutMainDivSec1">
            <div></div>
            <span className="aboutMainDivRoute">
              <span className="ampHeadRouteSpan" onClick={handleHomeOpenClick}>
                Home
              </span>{" "}
              {">"} About
            </span>
            <Wrapper>
              <div className="div1">
                <span className="aboutMainDivHead">About Us</span>
                <span className="aboutMainDivHeadContent">
                  Aeron Education Consultancy is a premier destination for
                  students aspiring to pursue their higher education abroad.
                  With a steadfast commitment to guiding and supporting students
                  in realizing their academic dreams, we offer comprehensive
                  services tailored to individual needs. Our team of experienced
                  counselors and advisors provides personalized assistance at
                  every step of the application process, from selecting the
                  right institution and course to securing admissions and
                  navigating visa procedures.
                </span>
              </div>
              <img className="img" src={Aboutus} alt="" />
            </Wrapper>
            <p className="aboutMainDivHeadContent"> At Aeron, we prioritize integrity,
              transparency, and student-centricity, ensuring that each
              student receives accurate information and expert guidance to
              make informed decisions about their academic future.With a
              track record of successful placements in renowned universities
              worldwide, we take pride in being a trusted partner in shaping
              promising educational journeys.</p>
            <p className="aboutMainDivHeadContent">
              At Aeron Education Consultancy, we believe in empowering students with the knowledge
              and skills they need to thrive in a global academic environment. Beyond the
              application process, we offer ongoing support to students, helping them adapt
              to new cultures, connect with fellow scholars, and excel in their chosen fields
              of study. Our commitment extends far beyond securing admissions; we are dedicated
              to nurturing well-rounded individuals poised for success in today's competitive
              world. Whether it's advice on academic planning, assistance with scholarship
              applications, or guidance on career pathways, Aeron is here to ensure that each
              student's educational journey is not just fulfilling but transformative.
            </p>
          </div>
          <div className="container" style={{ marginTop: "60px" }}>
            <div className="homeGridRow">
              <div className="homeGridColumn">
                <h1 className="homeGridTitle">{visaCount}+</h1>
                <span className="homeGridSubTitle">Student Visa</span>
              </div>
              <DivLine></DivLine>
              <div className="homeGridColumn">
                <h1 className="homeGridTitle">{universitiesCount}+</h1>
                <span className="homeGridSubTitle">Universities</span>
              </div>
              <DivLine></DivLine>
              <div className="homeGridColumn">
                <h1 className="homeGridTitle">{countriesCount}+</h1>
                <span className="homeGridSubTitle">Countries</span>
              </div>
              <DivLine></DivLine>
              <div className="homeGridColumn">
                <h1 className="homeGridTitle">{coursesCount}+</h1>
                <span className="homeGridSubTitle">Courses</span>
              </div>
            </div>
          </div >
          <div className="aboutMainDivSec2">
            <div></div>
            <span className="aboutMainDivHead">Vision</span>
            <span className="aboutMainDivHeadContent">
              The vision of studying abroad in various foreign countries
              encompasses a commitment to fostering international cooperation,
              cultural exchange, and personal development. It aims to create a
              global community of students who are equipped with the skills,
              knowledge, and cultural understanding necessary to navigate an
              increasingly interconnected world. By providing access to diverse
              academic opportunities, promoting cross-cultural communication,
              and encouraging experiential learning, studying abroad seeks to
              empower individuals to become compassionate, informed global
              citizens who can contribute positively to society. Ultimately, the
              vision is to cultivate a generation of leaders who are capable of
              addressing complex global challenges and promoting peace and
              understanding across borders.
            </span>
            <div className="aboutMainDivSec3">
              <span className="aboutMainDivHead">Mission</span>
              <span className="aboutMainDivHeadContent">
                Our mission is to provide students with transformative
                international education experiences that foster academic
                excellence, cultural understanding, and personal growth. We aim
                to:
              </span>
              <span className="aboutMainDivHeadContent">
                <b>1.Promote Academic Excellence:</b>
              </span>
              <span className="aboutMainDivHeadContent">
                Partnering with esteemed universities and institutions
                worldwide, we offer a diverse range of academic programs and
                opportunities for students to excel in their chosen fields of
                study.
              </span>
              <span className="aboutMainDivHeadContent">
                <b>2.Facilitate Cultural Exchange:</b>
              </span>
              <span className="aboutMainDivHeadContent">
                Through immersive cultural experiences, language immersion
                programs, and interaction with local communities, we aim to
                broaden students' perspectives and promote mutual understanding
                and respect across cultures.
              </span>
              <span className="aboutMainDivHeadContent">
                <b>3.Encourage Personal Development:</b>
              </span>
              <span className="aboutMainDivHeadContent">
                We provide comprehensive support services, including mentorship,
                counseling, and experiential learning opportunities, to help
                students navigate the challenges of studying abroad and develop
                essential life skills such as adaptability, resilience, and
                intercultural competence.
              </span>
              <span className="aboutMainDivHeadContent">
                <b>4.Empower Global Citizenship:</b>
              </span>
              <span className="aboutMainDivHeadContent">
                Our programs emphasize the importance of global citizenship and
                social responsibility, encouraging students to engage in
                service-learning projects, internships, and community engagement
                activities that address pressing global issues and contribute to
                positive social change.
              </span>
              <span className="aboutMainDivHeadContent">
                <b>5.Cultivate Leadership Skills: </b>
              </span>
              <span className="aboutMainDivHeadContent">
                We strive to empower students to become effective leaders and
                change-makers in their communities and beyond, equipping them
                with the skills, knowledge, and confidence to tackle complex
                global challenges and make a meaningful impact in the world.
              </span>
              <span className="aboutMainDivHeadContent">
                <b>6.Promote Diversity and Inclusion:</b>
              </span>
              <span className="aboutMainDivHeadContent">
                We are committed to creating inclusive and welcoming
                environments where students from diverse backgrounds feel
                valued, respected, and empowered to fully participate in the
                study abroad experience.
              </span>
              <span className="aboutMainDivHeadContent">
                <b>7.Forge Lifelong Connections:</b>
              </span>
              <span className="aboutMainDivHeadContent">
                By fostering meaningful connections and networks among students,
                alumni, faculty, and partners, we aim to create a supportive and
                vibrant global community that extends beyond the duration of the
                study abroad program.
              </span>
              <span className="aboutMainDivHeadContent">
                Through our mission, we aspire to inspire students to become
                lifelong learners, compassionate global citizens, and leaders
                who are committed to making a positive difference in the world.
              </span>
            </div>
          </div>
          <div className="aboutMainDivSec3">
            <span className="aboutMainDivHead">What we Offer</span>
            <span className="aboutMainDivHeadContent">
              <b> 1. Comprehensive Support Services:</b>
            </span>
            <span className="aboutMainDivHeadContent">
              Our programs provide extensive support services tailored to each
              student's needs, including mentorship, counseling, and access to
              resources that promote personal growth and well-being.
            </span>
            <span className="aboutMainDivHeadContent">
              <b>2. Experiential Learning Opportunities:</b>
            </span>
            <span className="aboutMainDivHeadContent">
              We offer a variety of experiential learning opportunities, such as
              internships, service-learning projects, and community engagement
              activities, that enable students to apply their knowledge in
              real-world contexts and develop practical skills while making a
              positive impact on local and global communities.
            </span>
            <span className="aboutMainDivHeadContent">
              Through these offerings, we aim to empower students to navigate
              the challenges of studying abroad, cultivate essential life
              skills, and become compassionate, informed global citizens who are
              equipped to make a meaningful impact in the world.
            </span>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default About;
const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  // align-items: center;
  gap: 20px;
  flex-wrap: wrap;
  margin-top: 60px;
  @media (max-width:1024px){
    gap:10px;
    justify-content:left;
  }
  .div1 {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 50%;
    @media (max-width: 1024px) {
      width:50%;
    }
    @media (max-width: 768px) {
      width: 100%;
    }
  }
  .img {
    width: 40%;
    // height:50vh;
    border-radius: 12px;
    @media (max-width: 1024px) {
      width:45%;
      // height:70vh;
    }
    @media (max-width: 768px) {
      width: 100%;
    }
  }
`;
const DivLine = styled.div`
border: 3px solid white;
height: 15vh;
border-radius: 12px;
@media (max-width:576px){
  width: 70%;
  height: 3px;
}
`;