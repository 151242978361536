import React from "react";
import styled from "styled-components";
import { Link, NavLink } from "react-router-dom";

let data = [
  {
    id: 1,
    icon: "fa fa-line-chart",
    title: "Career Counselling",
    para: " Personalized sessions to explore your interests, skills, and ambitions, ensuring your academic path aligns perfectly with your career aspirations.",
  },
  {
    id: 2,
    icon: "fa fa-graduation-cap",
    title: "University Admission",
    para: "Step-by-step guidance through the rigorous application procedures of renowned universities, maximizing your chances of acceptance.",
  },
  {
    id: 3,
    icon: "fa fa-building-o",
    title: "Visa Consultancy",
    para: "Proficient assistance in navigating the intricate visa processes, providing peace of mind as you prepare to study abroad.",
  },
  {
    id: 4,
    icon: "fa fa-home",
    title: "Accommodation",
    para: "Tailored support to secure comfortable and convenient living arrangements near your chosen university, helping you settle into your new academic home with ease.",
  },
  {
    id: 5,
    icon: "fa fa-plane",
    title: "Pre-Departure Briefing",
    para: "Thorough orientation sessions covering all aspects of studying overseas, equipping you with essential knowledge and confidence for a successful international academic journey.",
  },
];
export default function ServiceCard() {
  return (
    <>
      {data.map((item, i) => {
        return (
          <ServiceColumn key={i}>
            <FlexRow>
              <i className={item.icon} />
              <Title>{item.title}</Title>
            </FlexRow>
            <ColumnPara>{item.para}</ColumnPara>
            <Button to="/service">
              <span>LearnMore</span>
              <span className="material-icons">arrow_forward</span>
            </Button>
          </ServiceColumn>
        )
      })}
    </>
  );
}
const ServiceColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
  border-radius: 12px;
  width: 300px;
  padding: 20px;
  color: #494949;
  line-height:30px;
  transition: transform 0.3s ease-in-out, background-color 0.3s ease-in-out;
  &:hover {
      cursor: pointer;
      transform: scale(1.05);
      background-color: #38b772;
      color:#fff;
  }
  @media (max-width: 576px) {
    width: 90%;
  }
`;
const FlexRow = styled.div`
display:flex;
flex-direction:row;
gap:20px;
i {
  font-size:25px;
}
@media (max-width: 576px){
  gap:10px;
}
`;
const Title = styled.h1`
  font-size: 18px;
  font-weight: 700;
`;
const ColumnPara = styled.span`
  font-size: 16px;
  font-weight: 400;
`;
const Button = styled(Link)`
color: #494949;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
  text-decoration:none;
  &:hover {
    color:#fff;
  }
  span {
    font-size: 20px;
  }
`;
