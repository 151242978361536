import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Navbar, Footer } from '../../components';
import { useInView } from 'react-intersection-observer';

import video1 from "../../assets/images/gallery/video1.mp4";

const videos = [video1];

export default function Gallery() {
    return (
        <div className="mainDiv">
            <div className="Navbar">
                <Navbar />
            </div><br /><br />
            <div className='container'>
                <Wrapper>
                    <h1>Exploring the Unseen Beauty</h1>
                    <h2>An Exhibition of the Sublime and the Unexpected</h2>
                    <GalleryContainer>
                        {videos.map((video, index) => (
                            <LazyVideo key={index} src={video} />
                        ))}
                    </GalleryContainer>
                </Wrapper>
            </div>
            <Footer />
        </div>
    );
}

const LazyVideo = ({ src }) => {
    const { ref, inView } = useInView({
        triggerOnce: true,
        threshold: 0.25,
    });

    return (
        <Video ref={ref} width="320" height="240" controls>
            {inView && <source src={src} type="video/mp4" />}
        </Video>
    );
};

const Wrapper = styled.div`
    margin-top: 120px;
    h1 {
        font-size: 35px;
        font-weight: 700;
        color: #38b772;
    }
    h2 {
        font-size: 16px;
        font-weight: 400;
        color: #494949;
    }
`;

const GalleryContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 30px;
    justify-content: center;
    padding: 60px 0;

    .img {
        width: 400px;
        height: 300px;

        @media (max-width: 576px) {
            width: 100%;
        }
    }
`;

const Video = styled.video`
    width: 320px;
    height: 240px;
    @media (max-width: 576px) {
        width: 100%;
    }
`;
