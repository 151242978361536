import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import {
  course_home,
  course1,
  course2,
  course3,
  course4,
  course5,
  course6,
} from "../../assets/images";

const data = [
  {
    id: 1,
    crs: course1,
    title: "Business and Management",
    list: [
      { id: 1, item: "Undergraduate" },
      { id: 2, item: "Postgraduate" },
      { id: 3, item: "Level 4 Apprenticeship" },
      { id: 4, item: "Level 3 Apprenticeship" },
      { id: 5, item: "BA" },
      { id: 6, item: "MA" },
      { id: 7, item: "Degree with Foundation Year" },
      { id: 8, item: "Masters" },
      { id: 9, item: "Double degree including honours" },
      { id: 10, item: "Bachelors Degree" },
      { id: 11, item: "Masters Degree" },
      { id: 12, item: "MScPOSTGRADUATE" },
      { id: 13, item: "BA (Hons)UNDERGRADUATE" },
      { id: 14, item: "BSc (Hons)UNDERGRADUATE" },
      { id: 15, item: "BA (Hons)DEGREE APPRENTICESHIP" },
      { id: 16, item: "BA (Hons) Top UpUNDERGRADUATE" },
      { id: 17, item: "MBAPOSTGRADUATE" },
      { id: 18, item: "Ontario College Diploma" },
      { id: 19, item: "Ontario College Certificate" },
      { id: 20, item: "Honours Bachelor Degree" },
      { id: 21, item: "Honours Baccalaureate Degree" },
      { id: 22, item: "Advanced Diploma" },
      { id: 23, item: "PG Diploma" },
      { id: 24, item: "Post-Baccalaureate Diploma" },
      { id: 25, item: "Diploma" },
      { id: 26, item: "Baccalaureate Degree" },
      { id: 27, item: "Post Degree Diploma" },
      { id: 28, item: "Ontario College Diploma, Co-op" },
      { id: 29, item: "Ontario College Advanced Diploma, Co-op" },
      { id: 30, item: "Ontario Graduate Certificate" },
      { id: 31, item: "Honours Degree" }
    ]
  },
  {
    id: 7,
    crs: course2,
    title: "Hospitality and Tourism",
    list: [
      { id: 1, item: "BA" },
      { id: 2, item: "MA" },
      { id: 3, item: "Degree" },
      { id: 4, item: "Degree with Foundation Year" },
      { id: 5, item: "UG" },
      { id: 6, item: "PG" },
      { id: 7, item: "Bachelors Degree" },
      { id: 8, item: "Graduate certificate" },
      { id: 9, item: "Masters Degree" },
      { id: 10, item: "Diploma" },
      { id: 11, item: "Associate Degree" },
      { id: 12, item: "Advanced Diploma" },
      { id: 13, item: "MScPOSTGRADUATE" },
      { id: 14, item: "MAPOSTGRADUATE" },
      { id: 15, item: "BA (Hons)UNDERGRADUATE" },
      { id: 16, item: "BA (Hons) Top UpUNDERGRADUATE" },
      { id: 17, item: "Ontario College Diploma" },
      { id: 18, item: "Honours Degree" },
      { id: 19, item: "GRADUATION" },
      { id: 20, item: "Post-secondary program" },
      { id: 21, item: "Post-Degree Diploma" },
      { id: 22, item: "Post-Baccalaureate Diploma" },
      { id: 23, item: "Ontario College Certificate" },
      { id: 24, item: "Ontario College Diploma, Co-op" },
      { id: 25, item: "Ontario College Graduate Certificate" },
      { id: 26, item: "Ontario College Advanced Diploma, Co-op" },
      { id: 27, item: "Ontario Graduate Certificate" }
    ]
  },
  {
    id: 3,
    crs: course3,
    title: "Business and Finance",
    list: [
      { id: 1, item: "Undergraduate" },
      { id: 2, item: "bach hons" },
      { id: 3, item: "postgraduate" }
    ],
  },
  {
    id: 14,
    crs: course4,
    title: "Environmental Science",
    list: [
      { id: 1, item: "UG" },
        { id: 2, item: "PG" },
        { id: 3, item: "MA" },
        { id: 4, item: "BA" },
        { id: 5, item: "BSc" },
        { id: 6, item: "MSc" },
        { id: 7, item: "Degree with Foundation Year" },
        { id: 8, item: "Diploma" },
    ],
  },
  {
    id: 10,
    crs: course5,
    title: "Health and Medicine",
    list: [
      { id: 1, item: "UG" },
        { id: 2, item: "PG" },
        { id: 3, item: "MA" },
        { id: 4, item: "BA" },
        { id: 5, item: "BSc" },
        { id: 6, item: "MSc" },
        { id: 7, item: "Degree with Foundation Year" },
        { id: 8, item: "Diploma" },
    ],
  },
  {
    id: 6,
    crs: course6,
    title: "Engineering",
    list: [
      { id: 1, item: "Postgraduate" },
      { id: 2, item: "Undergraduate" },
      { id: 3, item: "BA" },
      { id: 4, item: "MA" },
      { id: 5, item: "PG Diploma" },
      { id: 6, item: "PG Diploma /MA" },
      { id: 7, item: "Degree" },
      { id: 8, item: "Integrated Master's" },
      { id: 9, item: "Degree with Foundation Year" },
      { id: 10, item: "Foundation Degree" },
      { id: 11, item: "Masters" },
      { id: 12, item: "Honours Bachelors Degree" },
      { id: 13, item: "Bachelors Degree" },
      { id: 14, item: "Masters Degree" },
      { id: 15, item: "Graduate Diploma" },
      { id: 16, item: "Associate Degree" },
      { id: 17, item: "Honours Degree" },
      { id: 18, item: "Graduate Certificate" },
      { id: 19, item: "Expert master degree" },
      { id: 20, item: "Professional entry master degree" },
      { id: 21, item: "Degree including honours" },
      { id: 22, item: "UniLink Diploma" },
      { id: 23, item: "Advanced Diploma" },
      { id: 24, item: "Double degree including honours" },
      { id: 25, item: "Degree including honours and work placement" },
      { id: 26, item: "UniLink Diplomas and Foundation Years" },
      { id: 27, item: "Degrees and double degrees degree including honours" },
      { id: 28, item: "BSc (Hons)DEGREE APPRENTICESHIP" },
      { id: 29, item: "MEngUNDERGRADUATE" },
      { id: 30, item: "Ontario College Diploma" },
      { id: 31, item: "Ontario College Advanced Diploma" },
      { id: 32, item: "Ontario College Graduate Certificate" },
      { id: 31, item: "Baccalaureate Degree" },
      { id: 32, item: "Diploma/Advanced Diploma (3) N/A" },
      { id: 33, item: "Traditional apprenticeship program" },
      { id: 34, item: "Post Baccalaureate Diploma" },
      { id: 35, item: "Ontario College Diploma, Co-op" },
      { id: 36, item: "Ontario College Advanced Diploma, Co-op" }
    ]
  },
];

export default function Courses() {
  return (
    <Wrapper>
      <div className="row">
        <div className="rowDiv1">
          <h1 className="title">
            Popular <span className="text-aeron">Courses</span>
          </h1>
          <br />
          <span className="para">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book. It has survived not
            only five centuries, but also the leap into electronic typesetting,
            remaining essentially unchanged.
          </span>
        </div>
        <img className="img" src={course_home} alt="" />
      </div>
      <br />
      <br />
      <FlexRow>
        {data.map((item, i) => (
          <BorderBox key={i} bgImage={item.crs}>
            <ListWrapper>
              {item.list.map((li, j) => (
                <ListItems key={j}>
                  {li.item}
                </ListItems>
              ))}
            </ListWrapper>
            <BottomContent>
              <Title>{item.title}</Title>
              <ViewBtn  to={`/course/${item.id}`}>
                <span>View Courses</span>
                <span className="material-icons">arrow_forward</span>
              </ViewBtn>
            </BottomContent>
          </BorderBox>
        ))}
      </FlexRow>
      <br />
      <br />
      <Button to="/course/course">More Courses</Button>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 20px;
  .row {
    display: flex;
    flex-direction: row;
    align-items: center;
    @media (max-width: 768px) {
      flex-direction: column;
    }
  }
  .rowDiv1 {
    width: 50%;
    text-align: left;
    @media (max-width: 768px) {
      width: 100%;
    }
  }
  .img {
    width: 50%;
    height: 300px;
    @media (max-width: 768px) {
      width: 100%;
    }
  }
  .title {
    font-size: 45px;
    font-weight: 700;
    color: #494949;
    @media (max-width: 576px) {
      font-size: 35px;
    }
  }
  .text-aeron {
    color: #38b772;
    font-size: 45px;
    font-weight: 700;
    @media (max-width: 576px) {
      font-size: 35px;
    }
  }
  .para {
    font-size: 16px;
    font-weight: 400;
    color: #879b8f;
    line-height: 30px;
  }
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15px;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
`;

const BorderBox = styled.div`
  position: relative;
  width: 300px;
  height: 350px;
  padding: 10px;
  border-radius: 12px;
  overflow: hidden;
  margin-bottom: 20px;
  transition: filter 0.3s;
  @media (max-width:576px){
    height:400px;
  }

  &:hover {
    filter: brightness(100%);
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url(${(props) => props.bgImage});
    background-size: cover;
    background-repeat: no-repeat;
    filter: brightness(80%);
  }
`;

const ListWrapper = styled.div`
background-color: rgb(29 101 63 / 80%);
  // background-color: #38b772;
  padding: 10px;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 10px;
    background-color: #d3d5d2;
  }
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background: #879b8f;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #686d69;
  }
  width: 280px;
  height: 250px;
  border-radius: 12px;
  position: absolute;
  bottom: -300px; /* Initially off-screen */
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.5s ease-in-out, bottom 0.5s ease-in-out;
  pointer-events: none;
  @media (max-width:576px){
    top:20px;
    width: 220px; 
    height: 270px;
  }

  ${BorderBox}:hover & {
    opacity: 1;
    bottom: 80px; /* Slide up to 30px from the bottom */
    pointer-events: auto;
  }

  /* Directly set filter for ListWrapper */
  filter: brightness(100%);
`;

const ListItems = styled.span`
  font-size: 16px;
  color: #fff;
  cursor: default;
  filter: brightness(100%);
`;

const BottomContent = styled.div`
  position: absolute;
  bottom: 10px;
  display: flex;
  flex-direction: column;
  gap: 0;
  justify-content: center;
  align-items: center;
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: 700;
  color: #fff;
  margin: 0;
  /* Directly set filter for Title */
  filter: brightness(100%);
`;

const ViewBtn = styled(Link)`
display: flex;
  align-items: center;
  justify-content: center;
  background-color: #38b772;
  color: #ffffff;
  padding: 8px 15px;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s;
  text-decoration:none;
  span.material-icons {
    font-size: 16px;
    margin-left: 5px;
  }
  &:hover {
    background-color: #228f58;
  }
  /* Directly set filter for ViewBtn */
  filter: brightness(100%);
`;

const Button = styled(Link)`
  cursor: pointer;
  background-color: #38b772;
  color: #fff;
  border-radius: 10px;
  padding: 10px 20px;
  width: fit-content;
  border: 0px solid;
  text-decoration: none;
  box-shadow: inset 0 0 0 0 #38b772;
  transition: color .5s ease-in-out, box-shadow .5s ease-in-out;
  &:hover {
    background-color: #fff;
    border: 1px solid #38b772;
    color: #38b772;
    box-shadow: inset 200px 0 0 0 #fff;
  }
`;