import React, { useState } from 'react';
import styled from 'styled-components';

let data = [
    { id: 1, question: "What are the benefits of studying abroad?", answer: "Studying abroad offers a wide range of benefits, including exposure to diverse cultures, language acquisition, personal growth, and the opportunity to gain a global perspective. Additionally, it can enhance career prospects by demonstrating adaptability, independence, and cross-cultural communication skills to potential employers." },
    { id: 2, question: "How do I choose the right study destination?", answer: "Choosing the right study destination depends on various factors such as academic interests, language proficiency, budget, cultural preferences, and career goals. Researching universities, programs, living costs, and visa requirements can help in making an informed decision." },
    { id: 3, question: "What financial resources are available for studying abroad?", answer: "There are several financial resources available for studying abroad, including scholarships, grants, student loans, and part-time work opportunities. Many universities and governments also offer financial aid and support for international students." },
    { id: 4, question: "What should I consider when selecting a study program?", answer: "When selecting a study program abroad, consider factors such as the academic reputation of the institution, the curriculum relevance to your field of study, internship or research opportunities, language of instruction, and potential for cultural immersion." },
    { id: 5, question: "How can I prepare for living in a different country?", answer: "Preparing for living abroad involves practical considerations such as obtaining the necessary visas and permits, arranging accommodation, understanding the local culture and customs, learning basic phrases of the local language, and familiarizing yourself with the local transportation system." },
    { id: 6, question: "What are the challenges of studying abroad?", answer: "Challenges of studying abroad may include language barriers, homesickness, adjusting to a new education system, cultural differences, and being far from family and friends. However, these challenges often lead to personal growth and development." },
    { id: 7, question: "What opportunities exist for cultural immersion while studying abroad?", answer: "Studying abroad offers numerous opportunities for cultural immersion, such as participating in cultural exchange programs, joining student clubs or organizations, attending local events and festivals, living with host families, and exploring historical sites and landmarks." },
    { id: 9, question: "How can I make the most of my study abroad experience?", answer: "To make the most of your study abroad experience, engage with the local community, step out of your comfort zone, try new activities and foods, travel within the region, build relationships with fellow students and locals, and keep an open mind to new experiences." },
    { id: 10, question: "What are the language requirements for studying abroad?", answer: "Language requirements vary depending on the country and institution. Some programs may require proficiency in the local language, while others offer courses in English or provide language support for international students. It's essential to check the language requirements of the specific program or institution." },
    { id: 11, question: "How can studying abroad impact my career?", answer: "Studying abroad can have a positive impact on your career by enhancing your skills and attributes such as cultural awareness, adaptability, independence, language proficiency, and a global mindset. Employers often value these qualities in an increasingly interconnected world." },
];

export default function QA() {
    const [activeId, setActiveId] = useState(null);

    const handleToggle = (id) => {
        setActiveId(activeId === id ? null : id);
    };

    return (
        <Wrapper>
            <Title>Frequently <span className='text-aeron'>Asked</span> Questions</Title>
            <FlexColumn>
                {data.map((item) => (
                    <MainDiv key={item.id}>
                        <Header onClick={() => handleToggle(item.id)} isActive={activeId === item.id}>
                            <h1 className='title'>{item.question}</h1>
                            {activeId === item.id ? (
                                <i className="fa fa-minus-circle"></i>
                            ) : (
                                <i className="fa fa-plus-circle"></i>
                            )}
                        </Header>
                        <FooterWrapper isActive={activeId === item.id}>
                            <Footer>{item.answer}</Footer>
                        </FooterWrapper>
                    </MainDiv>
                ))}
            </FlexColumn>
        </Wrapper>
    );
}

const Wrapper = styled.div`
    padding: 60px 0;
    .text-aeron {
        font-size: 45px;
    font-weight: 700;
        letter-spacing: 2px;
        color:#38b772;
        text-align:center;
        @media (max-width: 576px) {
            font-size: 30px;
        }
    }
`;

const Title = styled.h1`
    font-size: 45px;
    font-weight: 700;
    color: #494949;
    text-align: center;
    letter-spacing: 2px;
    @media (max-width: 576px) {
        font-size: 30px;
    }
`;

const FlexColumn = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: center;
    justify-content: center;
    padding-top: 60px;
`;

const MainDiv = styled.div`
    width: 900px;
    @media (max-width: 920px) {
        width: 90%;
    }
`;

const Header = styled.div`
    border-radius: 12px;
    padding: 20px;
    border: 1px solid rgba(0, 0, 0, .02);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    transition: all .3s ease-in-out;
    color: ${(props) => (props.isActive ? '#fff' : '#494949')};
    background-color: ${(props) => (props.isActive ? '#38b772' : '#F7F8FA')};
    &:hover {
        background-color: #38b772;
        color: #fff;
    }
    .title {
        font-size: 16px;
    }
`;

const FooterWrapper = styled.div`
    max-height: ${(props) => (props.isActive ? '500px' : '0')};
    overflow: hidden;
    transition: max-height 0.5s ease;
`;

const Footer = styled.div`
    padding: 20px;
    color: #879b8f;
    text-align: left;
    line-height: 30px;
`;
