import React from 'react';
import styled from 'styled-components';
import { aeron_white } from '../../assets/images';
import { Link } from "react-router-dom";

let courseData = [
  { id: 10, course: "Health and Medicine" },
  { id: 7, course: "Hospitality and Tourism" },
  { id: 6, course: "Engineering" },
  { id: 1, course: "Business and Management " },
  { id: 8, course: "Accounting and Finance" },
  { id: 14, course: "Environmental Science" },
  { id: 11, course: "Building and Architecture" },
  { id: 13, course: "Welfare Studies and Services" },
  { id: 16, course: "Media and Communication" }
]
let countryData = [
  { id: 1, country: "Australia" },
  { id: 2, country: "United Kingdom" },
  { id: 3, country: "United States" },
  { id: 4, country: "Switzerland" },
  { id: 5, country: "Sweden" },
  { id: 6, country: "Ireland" },
  { id: 7, country: "Georgia" },
  { id: 8, country: "France" },
  { id: 9, country: "China" },
  { id: 10, country: "Canada" },
]
export default function Footer() {
  return (
    <div style={{ marginTop: "60px" }}>
      <Wrapper>
        <div className='container'>
          <MainDiv>
            <img src={aeron_white} alt='' />
            <span style={{ color: "#fff" }}>Kerala's leading overseas education facilitator</span>
            <div className='row'>
              <div className="cover"><a href="https://api.whatsapp.com/send?phone=8301829642"><i className="fa fa-whatsapp"></i></a></div>
              <div className="cover"><a href="https://www.facebook.com/profile.php?id=61553428405214"><i className="fa fa-facebook"></i></a></div>
              <div className="cover"><a href="https://www.instagram.com/aeroneducationservice"><i className="fa fa-instagram"></i></a></div>
              <div className="cover"><a href="mailto:aeroneducationpmna@gmail.com"><i className="fa fa-envelope"></i></a></div>
              <div className="cover"><a href="https://www.linkedin.com/in/yourprofile"><i className="fa fa-linkedin"></i></a></div>
              <div className="cover"><a href="https://telegram.me/yourchannel"><i className="fa fa-telegram"></i></a></div>
            </div>
          </MainDiv>
          <BorderLine />
          <FooterDiv>
            <FlexColumn>
              <h1 className='title'>Qucik Links</h1><br />
              <SubLink to='about'>About Us</SubLink>
              <SubLink to='/about/team'>Team</SubLink>
              <SubLink to='/about/testimonial'>Testimonials</SubLink>
              <SubLink>Scholarship</SubLink>
              <SubLink to='/about/gallery'>Gallery</SubLink>
              <SubLink to='/about/career'>Career</SubLink>
              <SubLink>Events</SubLink>
              <SubLink>Blogs</SubLink>
            </FlexColumn>
            <FlexColumn>
              <h1 className='title'>Countries</h1><br />
              {countryData.map((item, i) => {
                return (
                  <SubLink to={`/country/${item.id}`} key={i}>{item.country}</SubLink>
                )
              })}
            </FlexColumn>
            <FlexColumn>
              <h1 className='title'>Courses</h1><br />
              {courseData.map((item, i) => {
                return (
                  <SubLink to={`/course/${item.id}`} key={i}>{item.course}</SubLink>
                )
              })}
            </FlexColumn>
            <FlexColumn>
              <h1 className='title'>Address</h1><br />
              <FlexRow>
                <span className="material-icons">location_on</span>
                <span>Aeron Education Services
                  Third Floor, Aysiha Commercial Building,
                  Perinthalmanna</span>
              </FlexRow>
              <FlexRow>
                <span className="material-icons">phone_in_talk</span>
                <span>+91 8301829642</span>
              </FlexRow>
              <FlexRow>
                <span className="material-icons">mail</span>
                <span>aeroneducationpmna@gmail.com</span>
              </FlexRow>
            </FlexColumn>
          </FooterDiv>
          <BorderLine />
          <FooterDiv>
            <FlexRow>
              <SubLink to='/privacy'>Privacy Ploicy</SubLink>
              <div style={{ border: "1px solid #fff" }} />
              <SubLink to='/terms'>Terms & Conditions</SubLink>
            </FlexRow>
            <div>
              <span style={{ color: "#fff" }}>© 2024 All Rights Reserved. Developed By </span><SubLink>T & T Solutions</SubLink>
            </div>
          </FooterDiv>
        </div>
      </Wrapper>
    </div>
  )
}
const Wrapper = styled.div`
background-color:#2c905c;
padding:60px 0;
width:100%;
// color:#59cc9a;
`;
const MainDiv = styled.div`
display:flex;
flex-direction:row;
justify-content:space-between;
align-items:center;
@media (max-width:768px){
  flex-direction:column;
  gap:20px;
}
img {
  width:15%;
}
.row {
  display:flex;
  flex-direction:row;
  align-items:center;
  justify-content:center;
  gap:10px;
  width:50%;
  .cover {
    display:flex;
    align-items:center;
    justify-content:center;
    background-color:#fff;
    border-radius:50%;
    width:40px;
    height:40px;
    i {
      color:#2c905c;
      font-size:24px;
    }
}
}
`;
const BorderLine = styled.div`
margin:40px 0;
border:1px solid #77ae91;
`;
const FooterDiv = styled.div`
display:flex;
flex-direction:row;
justify-content:space-between;
flex-wrap:wrap;
gap:20px;
`;
const FlexColumn = styled.div`
display:flex;
flex-direction:column;
gap:10px;
text-align:left;
&:last-child {
  width:20%;
  @media (max-width: 576px){
    width:fit-content;
  }
}
.title {
  font-size:20px;
  font-weight:500;
  position:relative;
  color:#fff;
  &::after {
    border-radius: 3px;
    display: block;
    width: 100%;
    height: 3px;
    background: #38b772;
    content: "";
    position: absolute;
    bottom: -10px;
}
`;
const FlexRow = styled.div`
  display:flex;
  flex-direction:row;
  gap:10px;
  color:#fff;
`;
const SubLink = styled(Link)`
text-decoration:none;
color:#fff;
cursor:pointer;
&:hover {
  color:#59cc9a;
}
`;