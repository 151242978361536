import React from "react";
import { Navbar, Footer } from "../components";
import styled from "styled-components";

function Privacy() {
  return (
    <div className="mainDiv">
      <div className="Navbar">
        <Navbar />
      </div><br /><br /><br />
      <div className="container">
        <Wrapper>
          <Title>Privacy Policy</Title>
          <Sub>
            Thank you for choosing Aeron Educational Consultancy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website www.aeronedu.com, including any other media form, media channel, mobile website, or mobile application related or connected thereto (collectively, the "Site"). Please read this Privacy Policy carefully. If you do not agree with the terms of this Privacy Policy, please do not access the site.
            <br /><br />

            We reserve the right to make changes to this Privacy Policy at any time and for any reason. We will alert you about any changes by updating the "Last Updated" date of this Privacy Policy. You are encouraged to periodically review this Privacy Policy to stay informed of updates. You will be deemed to have been made aware of, will be subject to, and will be deemed to have accepted the changes in any revised Privacy Policy by your continued use of the Site after the date such revised Privacy Policy is posted.
          </Sub><br /><br />
          <Title>Collection of Your Information</Title>
          <Sub>We may collect information about you in a variety of ways. The information we may collect on the Site includes: </Sub>
          <ul>
            <li>
              Personal Data: Personally identifiable information, such as your name, email address, and phone number, that you voluntarily give to us when you choose to participate in various activities related to the Site, such as newsletters, downloads, and inquiries. You are under no obligation to provide us with personal information of any kind, however, your refusal to do so may prevent you from using certain features of the Site.
            </li>
            <li>
              Derivative Data: Information our servers automatically collect when you access the Site, such as your IP address, browser type, the dates and times of your visits, the pages you view on the Site, among other data.
            </li>
          </ul><br /><br />
          <Title>Use of Your Information</Title>
          <Sub>We may use information collected about you in the following ways:</Sub>
          <ul>
            <li>
              To operate and maintain the Site;
            </li>
            <li>To send you promotional information, such as newsletters. Each email promotion will provide information on how to opt-out of future mailings;</li>
            <li>To respond to inquiries and offer support;</li>
            <li>To provide you with updates, news, and other relevant information related to studying abroad.</li>
          </ul><br /><br />
          <Title>Disclosure of Your Information</Title>
          <Sub>We do not sell, rent, or lease our customer lists or personal data to third parties. We may share your information with third parties that perform services for us or on our behalf, including data analysis, email delivery, hosting services, customer service, and marketing assistance.</Sub>
          <br /><br /><Title>Third-Party Websites</Title>
          <Sub>The Site may contain links to third-party websites and applications of interest. We do not have control over these third-party websites and are not responsible for their privacy statements. When you leave our website, we encourage you to read the privacy policy of every website you visit.</Sub>
          <br /><br /><Title>Security of Your Information</Title>
          <Sub>We take reasonable precautions to protect your information. When you submit sensitive information via the website, your information is protected both online and offline.</Sub>
          <br /><br /><Title>Changes to This Privacy Policy</Title>
          <Sub>We reserve the right to change this Privacy Policy at any time. You acknowledge and agree that it is your responsibility to review this Site and this Privacy Policy periodically and to be aware of any modifications. Your continued use of this Site after such modifications will constitute acknowledgment of the modified Privacy Policy and agreement to abide and be bound by the modified Privacy Policy.</Sub>
          <br /><br /><Title>Contact Information</Title>
          <Sub>If you have any questions about this Privacy Policy, please contact us at:</Sub>
          <Sub style={{ marginTop: 0 }}>Aeron Educational Consultancy<br />
            Aeron Education Services<br />
            Third Floor, Aysiha Commercial Building,<br />
            Perinthalmanna<br />
            Email: sample@gmail.com<br />
            Phone: +91 8301829642</Sub>
        </Wrapper>
      </div>
      <Footer />
    </div>
  );
}
const Wrapper = styled.div`
 display:flex;
 flex-direction:column;
 padding-top:120px;
 text-align:left;
 li {
  color:#879b8f;
line-height:30px;
 }
`;
const Title = styled.h1`
font-size:35px;
font-weight:700;
color:#494949;
`;
const Sub = styled.p`
color:#879b8f;
line-height:30px;
margin-top:40px;
`;
export default Privacy;
