import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { travel_man } from "../../assets/images";


export default function Testimonials({ data }) {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % data.length);
    }, 2000);

    return () => {
      clearInterval(interval);
    };
  }, [data]);
  const activeReview = data[currentIndex];
  return (
    <Wrapper>
      <div className="container">
        <MainDiv>
          <h1 className="title">Testimonials</h1>
          <h3 className="sub">What Students Say </h3>
          <Review>
            <ReviewImg src={activeReview.image} alt="" />
            <ReviewContent>
              <h2 className="person">{activeReview.name}</h2><br />
              <span className="sub-para">{activeReview.reviewContent}</span>
              <br /><br />
              <Slider>
                {data.map((_, index) => (
                  <div
                    key={index}
                    className={`circle ${index === currentIndex ? "active" : ""}`}
                  ></div>
                ))}
              </Slider>
            </ReviewContent>
          </Review>
        </MainDiv>
      </div>
    </Wrapper>
  );
}
const Wrapper = styled.div`
background-color:#F4F4F4;
margin-top:60px;
`;
const MainDiv = styled.div`
  padding:60px 0;
  .title {
    font-size: 35px;
    font-weight: 700;
    color: #38b772;
    text-align: center;
  }
  .sub {
    font-size: 16px;
    font-weight: 400;
    color: #879b8f;
    text-align: center;
  }
`;
const Review = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top:40px;
  gap:20px;
  @media (max-width: 576px) {
    flex-direction: column;
    justify-content: center;
    gap: 20px;
  }
`;
const ReviewImg = styled.img`
  width: 200px;
  height: 200px;
  border-radius: 12px;
`;
const ReviewContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width:50%;
  @media (max-width: 576px){
    width:100%;
  }
  .person {
    font-size: 35px;
    font-weight: 700;
    color: #494949;
    text-align:left;
  }
  .sub-para {
    font-size: 16px;
    font-weight: 400;
    color: #879b8f;
    text-align:left;
    line-height: 30px;
  }
`;
const Slider = styled.div`
display:flex;
flex-direction:row;
gap:4px;
transition: transform 0.5s ease-in-out;
.circle {
    cursor:pointer;
    border-radius:50%;
    padding:5px;
    background-color:#C3C3C3;
    &:hover {
        background-color:#38b772;   
    }
    transition: background-color 0.3s ease-in-out;
}
.circle.active {
    background-color: #38b772;
  }
`;