import React from "react";
import styled from "styled-components";
import { Navbar, Footer } from "../../components";
import { course1, course2, course3, course4, course5, course6, course9, course_accounting, course_agriculture, course_architec, course_arts, course_bio, course_chemistry, course_communication, course_culture, course_design, course_economics, course_geography, course_health_medicine, course_history, course_info, course_information, course_it, course_law, course_maths, course_media, course_nurse, course_physics, course_social, course_teacher, course_welfair } from "../../assets/images";
import { Link } from "react-router-dom";

let couseJson =
  [
    {
      id: 1,
      crs: course1,
      title: "Business and Management",
      list: [
        { id: 1, item: "Undergraduate" },
        { id: 2, item: "Postgraduate" },
        { id: 3, item: "Level 4 Apprenticeship" },
        { id: 4, item: "Level 3 Apprenticeship" },
        { id: 5, item: "BA" },
        { id: 6, item: "MA" },
        { id: 7, item: "Degree with Foundation Year" },
        { id: 8, item: "Masters" },
        { id: 9, item: "Double degree including honours" },
        { id: 10, item: "Bachelors Degree" },
        { id: 11, item: "Masters Degree" },
        { id: 12, item: "MScPOSTGRADUATE" },
        { id: 13, item: "BA (Hons)UNDERGRADUATE" },
        { id: 14, item: "BSc (Hons)UNDERGRADUATE" },
        { id: 15, item: "BA (Hons)DEGREE APPRENTICESHIP" },
        { id: 16, item: "BA (Hons) Top UpUNDERGRADUATE" },
        { id: 17, item: "MBAPOSTGRADUATE" },
        { id: 18, item: "Ontario College Diploma" },
        { id: 19, item: "Ontario College Certificate" },
        { id: 20, item: "Honours Bachelor Degree" },
        { id: 21, item: "Honours Baccalaureate Degree" },
        { id: 22, item: "Advanced Diploma" },
        { id: 23, item: "PG Diploma" },
        { id: 24, item: "Post-Baccalaureate Diploma" },
        { id: 25, item: "Diploma" },
        { id: 26, item: "Baccalaureate Degree" },
        { id: 27, item: "Post Degree Diploma" },
        { id: 28, item: "Ontario College Diploma, Co-op" },
        { id: 29, item: "Ontario College Advanced Diploma, Co-op" },
        { id: 30, item: "Ontario Graduate Certificate" },
        { id: 31, item: "Honours Degree" }
      ]
    },
    {
      id: 2,
      crs: course_it,
      title: "Computer & IT",
      list: [
        { id: 1, item: "Ontario College Advanced Diploma" },
        { id: 2, item: "Degree" },
        { id: 3, item: "Degree with Foundation Year" },
        { id: 4, item: "Masters" },
        { id: 5, item: "MSc" },
        { id: 6, item: "BSc" },
        { id: 7, item: "BA" },
        { id: 8, item: "UG" },
        { id: 9, item: "PG" },
        { id: 10, item: "Bachelors Degree" },
        { id: 11, item: "Honours Bachelors Degree" },
        { id: 12, item: "Masters Degree" },
        { id: 13, item: "GRADUATE DIPLOMA" },
        { id: 14, item: "Graduate Certificate" },
        { id: 15, item: "Diploma" },
        { id: 16, item: "MA" },
        { id: 17, item: "Honours Degree" },
        { id: 18, item: "Honours year" },
        { id: 19, item: "Double degree" },
        { id: 20, item: "UniLink Diploma" },
        { id: 21, item: "BSc (Hons) UNDERGRADUATE" },
        { id: 22, item: "Post-Graduate Certificate" },
        { id: 23, item: "Graduate Certificate" },
        { id: 24, item: "Advanced Diploma" },
        { id: 25, item: "Honours Bachelor Degree" },
        { id: 26, item: "Honours Baccalaureate Degree" },
        { id: 27, item: "POST-DIPLOMA CERTIFICATE" },
        { id: 28, item: "Post-secondary program" },
        { id: 29, item: "CERTIFICATION" },
        { id: 30, item: "Post-Degree Diploma" },
        { id: 31, item: "Post-Degree" },
        { id: 32, item: "Post-Baccalaureate Diploma" },
        { id: 31, item: "Ontario College Diploma" },
        { id: 32, item: "Ontario College graduate certificate" },
        { id: 33, item: "Ontario College Diploma, Co-op" }
      ]
    },
    {
      id: 3,
      crs: course3,
      title: "Business and Finance",
      list: [
        { id: 1, item: "Undergraduate" },
        { id: 2, item: "bach hons" },
        { id: 3, item: "postgraduate" }
      ]
    },
    {
      id: 4,
      crs: course_nurse,
      title: "Nurse",
      list: [
        { id: 1, item: "NURSING DEGREE" },
        { id: 2, item: "Bachelor of Science in Nursing" },
      ]
    },
    {
      id: 5,
      crs: course5,
      title: "Health",
      list: [
        { id: 1, item: "BA" },
      ]
    },
    {
      id: 6,
      crs: course6,
      title: "Engineering",
      list: [
        { id: 1, item: "Postgraduate" },
        { id: 2, item: "Undergraduate" },
        { id: 3, item: "BA" },
        { id: 4, item: "MA" },
        { id: 5, item: "PG Diploma" },
        { id: 6, item: "PG Diploma /MA" },
        { id: 7, item: "Degree" },
        { id: 8, item: "Integrated Master's" },
        { id: 9, item: "Degree with Foundation Year" },
        { id: 10, item: "Foundation Degree" },
        { id: 11, item: "Masters" },
        { id: 12, item: "Honours Bachelors Degree" },
        { id: 13, item: "Bachelors Degree" },
        { id: 14, item: "Masters Degree" },
        { id: 15, item: "Graduate Diploma" },
        { id: 16, item: "Associate Degree" },
        { id: 17, item: "Honours Degree" },
        { id: 18, item: "Graduate Certificate" },
        { id: 19, item: "Expert master degree" },
        { id: 20, item: "Professional entry master degree" },
        { id: 21, item: "Degree including honours" },
        { id: 22, item: "UniLink Diploma" },
        { id: 23, item: "Advanced Diploma" },
        { id: 24, item: "Double degree including honours" },
        { id: 25, item: "Degree including honours and work placement" },
        { id: 26, item: "UniLink Diplomas and Foundation Years" },
        { id: 27, item: "Degrees and double degrees degree including honours" },
        { id: 28, item: "BSc (Hons)DEGREE APPRENTICESHIP" },
        { id: 29, item: "MEngUNDERGRADUATE" },
        { id: 30, item: "Ontario College Diploma" },
        { id: 31, item: "Ontario College Advanced Diploma" },
        { id: 32, item: "Ontario College Graduate Certificate" },
        { id: 31, item: "Baccalaureate Degree" },
        { id: 32, item: "Diploma/Advanced Diploma (3) N/A" },
        { id: 33, item: "Traditional apprenticeship program" },
        { id: 34, item: "Post Baccalaureate Diploma" },
        { id: 35, item: "Ontario College Diploma, Co-op" },
        { id: 36, item: "Ontario College Advanced Diploma, Co-op" }
      ]
    },
    {
      id: 7,
      crs: course2,
      title: "Hospitality & Tourism",
      list: [
        { id: 1, item: "BA" },
        { id: 2, item: "MA" },
        { id: 3, item: "Degree" },
        { id: 4, item: "Degree with Foundation Year" },
        { id: 5, item: "UG" },
        { id: 6, item: "PG" },
        { id: 7, item: "Bachelors Degree" },
        { id: 8, item: "Graduate certificate" },
        { id: 9, item: "Masters Degree" },
        { id: 10, item: "Diploma" },
        { id: 11, item: "Associate Degree" },
        { id: 12, item: "Advanced Diploma" },
        { id: 13, item: "MScPOSTGRADUATE" },
        { id: 14, item: "MAPOSTGRADUATE" },
        { id: 15, item: "BA (Hons)UNDERGRADUATE" },
        { id: 16, item: "BA (Hons) Top UpUNDERGRADUATE" },
        { id: 17, item: "Ontario College Diploma" },
        { id: 18, item: "Honours Degree" },
        { id: 19, item: "GRADUATION" },
        { id: 20, item: "Post-secondary program" },
        { id: 21, item: "Post-Degree Diploma" },
        { id: 22, item: "Post-Baccalaureate Diploma" },
        { id: 23, item: "Ontario College Certificate" },
        { id: 24, item: "Ontario College Diploma, Co-op" },
        { id: 25, item: "Ontario College Graduate Certificate" },
        { id: 26, item: "Ontario College Advanced Diploma, Co-op" },
        { id: 27, item: "Ontario Graduate Certificate" }
      ]
    },
    {
      id: 8,
      crs: course_accounting,
      title: "Accounting & Finance",
      list: [
        { id: 1, item: "Postgraduate" },
        { id: 2, item: "Undergraduate" },
        { id: 3, item: "BA" },
        { id: 4, item: "MA" },
        { id: 5, item: "Integrated Master's" },
        { id: 6, item: "Degree" },
        { id: 7, item: "Degree + Foundation year" },
        { id: 8, item: "Masters" },
        { id: 9, item: "BSc" },
        { id: 10, item: "MSc" },
        { id: 11, item: "TOP-UP" },
        { id: 12, item: "Diploma" },
        { id: 13, item: "Advanced Diploma" },
        { id: 14, item: "Bachelor degree" },
        { id: 15, item: "Master degree" },
        { id: 16, item: "Graduate Certificate" },
        { id: 17, item: "MAPOSTGRADUATE" },
        { id: 18, item: "BA (Hons)UNDERGRADUATE" },
        { id: 19, item: "MScPOSTGRADUATE" },
        { id: 20, item: "Post-Graduate Certificate" },
        { id: 21, item: "Graduate Certificate" },
        { id: 22, item: "Honours Bachelor Degree" },
        { id: 23, item: "Advanced Diploma" },
        { id: 24, item: "N/A" },
        { id: 25, item: "Post-Degree Diploma" },
        { id: 26, item: "Post-Baccalaureate Diploma" },
        { id: 27, item: "Post-Baccalaureate Diploma" },
        { id: 28, item: "Ontario College Diploma" },
        { id: 29, item: "Honours Degree" },
        { id: 30, item: "Ontario Graduate Certificate" },
        { id: 31, item: "Honours Bachelors Degree" },
        { id: 32, item: "Graduate Diploma" }
      ]
    },
    {
      id: 9,
      crs: course9,
      title: "Pharmacy",
      list: [
        { id: 1, item: "Postgraduate" },
        { id: 2, item: "Undergraduate" },
        { id: 3, item: "Degree" },
        { id: 4, item: "Degree with Foundation Year" },
        { id: 5, item: "Masters" },
        { id: 6, item: "Honours Bachelors Degree" },
        { id: 7, item: "Masters Degree" },
        { id: 8, item: "Bachelor degree" },
        { id: 9, item: "Bachelor degree (honours)" },
        { id: 10, item: "Graduate Certificate" },
        { id: 11, item: "Associate Degree" },
        { id: 12, item: "Diploma" },
        { id: 13, item: "Ontario College Diploma" },
        { id: 14, item: "Ontario College Certificate" }
      ]
    },

    {
      id: 10,
      crs: course_health_medicine,
      title: "Health & Medicine",
      list: [
        { id: 1, item: "UG" },
        { id: 2, item: "PG" },
        { id: 3, item: "Apprenticeship" },
        { id: 4, item: "BA" },
        { id: 5, item: "MA" },
        { id: 6, item: "Degree" },
        { id: 7, item: "Degree with Foundation Year" },
        { id: 8, item: "Masters" },
        { id: 9, item: "BSc" },
        { id: 10, item: "MChem" },
        { id: 11, item: "MSci" },
        { id: 12, item: "Honours program" },
        { id: 13, item: "Bachelors Degree" },
        { id: 14, item: "Honours Bachelors Degree" },
        { id: 15, item: "Master degree" },
        { id: 16, item: "Graduate Certificate" },
        { id: 17, item: "Graduate Diploma" },
        { id: 18, item: "Diploma" },
        { id: 19, item: "Associate Degree" },
        { id: 20, item: "Bachelor degree (honours)" },
        { id: 21, item: "Expert master degree" },
        { id: 22, item: "Honours Bachelor Degree" },
        { id: 23, item: "Advanced Diploma" },
        { id: 24, item: "N/A" },
        { id: 25, item: "Post-Degree Diploma" },
        { id: 26, item: "Post-Baccalaureate Diploma" },
        { id: 27, item: "Post-Baccalaureate Diploma" },
        { id: 28, item: "Ontario College Diploma" },
        { id: 29, item: "Honours Degree" },
        { id: 30, item: "Ontario Graduate Certificate" },
        { id: 31, item: "Honours Bachelors Degree" },
        { id: 32, item: "Graduate Diploma" }
      ]
    },
    {
      id: 11,
      crs: course_architec,
      title: "Building & Architecture",
      list: [
        { id: 1, item: "UG" },
        { id: 2, item: "PG" },
        { id: 3, item: "MA" },
        { id: 4, item: "BA" },
        { id: 5, item: "BSc" },
        { id: 6, item: "MSc" },
        { id: 7, item: "Degree with Foundation Year" },
        { id: 8, item: "Diploma" },
      ]
    },
    {
      id: 12,
      crs: course_economics,
      title: "Economics",
      list: [
        { id: 1, item: "UG" },
        { id: 2, item: "PG" },
        { id: 3, item: "MA" },
        { id: 4, item: "BA" },
        { id: 5, item: "BSc" },
        { id: 6, item: "MSc" },
        { id: 7, item: "Degree with Foundation Year" },
        { id: 8, item: "Diploma" },
      ]
    },
    {
      id: 13,
      crs: course_welfair,
      title: "Welfair Studies & Services",
      list: [
        { id: 1, item: "UG" },
        { id: 2, item: "PG" },
        { id: 3, item: "MA" },
        { id: 4, item: "BA" },
        { id: 5, item: "BSc" },
        { id: 6, item: "MSc" },
        { id: 7, item: "Degree with Foundation Year" },
        { id: 8, item: "Diploma" },
      ]
    },
    {
      id: 14,
      crs: course4,
      title: "Environmental Science",
      list: [
        { id: 1, item: "UG" },
        { id: 2, item: "PG" },
        { id: 3, item: "MA" },
        { id: 4, item: "BA" },
        { id: 5, item: "BSc" },
        { id: 6, item: "MSc" },
        { id: 7, item: "Degree with Foundation Year" },
        { id: 8, item: "Diploma" },
      ]
    },

    {
      id: 15,
      crs: course_law,
      title: "Law & Legal Studies",
      list: [
        { id: 1, item: "UG" },
        { id: 2, item: "PG" },
        { id: 3, item: "MA" },
        { id: 4, item: "BA" },
        { id: 5, item: "BSc" },
        { id: 6, item: "MSc" },
        { id: 7, item: "Degree with Foundation Year" },
        { id: 8, item: "Diploma" },
      ]
    },
    {
      id: 16,
      crs: course_media,
      title: "Media & Communication",
      list: [
        { id: 1, item: "UG" },
        { id: 2, item: "PG" },
        { id: 3, item: "MA" },
        { id: 4, item: "BA" },
        { id: 5, item: "BSc" },
        { id: 6, item: "MSc" },
        { id: 7, item: "Degree with Foundation Year" },
        { id: 8, item: "Diploma" },
      ]
    },
    {
      id: 17,
      crs: course_chemistry,
      title: "Chemistry",
      list: [
        { id: 1, item: "UG" },
        { id: 2, item: "PG" },
        { id: 3, item: "MA" },
        { id: 4, item: "BA" },
        { id: 5, item: "BSc" },
        { id: 6, item: "MSc" },
        { id: 7, item: "Degree with Foundation Year" },
        { id: 8, item: "Diploma" },
      ]
    },
    {
      id: 18,
      crs: course_physics,
      title: "Physics",
      list: [
        { id: 1, item: "UG" },
        { id: 2, item: "PG" },
        { id: 3, item: "MA" },
        { id: 4, item: "BA" },
        { id: 5, item: "BSc" },
        { id: 6, item: "MSc" },
        { id: 7, item: "Degree with Foundation Year" },
        { id: 8, item: "Diploma" },
      ]
    },
    {
      id: 19,
      crs: course_teacher,
      title: "Teaching & Education",
      list: [
        { id: 1, item: "UG" },
        { id: 2, item: "PG" },
        { id: 3, item: "MA" },
        { id: 4, item: "BA" },
        { id: 5, item: "BSc" },
        { id: 6, item: "MSc" },
        { id: 7, item: "Degree with Foundation Year" },
        { id: 8, item: "Diploma" },
      ]
    },
    {
      id: 20,
      crs: course_arts,
      title: "Performing Arts & Music",
      list: [
        { id: 1, item: "UG" },
        { id: 2, item: "PG" },
        { id: 3, item: "MA" },
        { id: 4, item: "BA" },
        { id: 5, item: "BSc" },
        { id: 6, item: "MSc" },
        { id: 7, item: "Degree with Foundation Year" },
        { id: 8, item: "Diploma" },
      ]
    },

    {
      id: 21,
      crs: course_info,
      title: "Info-Science & Libarianship",
      list: [
        { id: 1, item: "UG" },
        { id: 2, item: "PG" },
        { id: 3, item: "MA" },
        { id: 4, item: "BA" },
        { id: 5, item: "BSc" },
        { id: 6, item: "MSc" },
        { id: 7, item: "Degree with Foundation Year" },
        { id: 8, item: "Diploma" },
      ]
    },
    {
      id: 22,
      crs: course_culture,
      title: "Language & Culture",
      list: [
        { id: 1, item: "UG" },
        { id: 2, item: "PG" },
        { id: 3, item: "MA" },
        { id: 4, item: "BA" },
        { id: 5, item: "BSc" },
        { id: 6, item: "MSc" },
        { id: 7, item: "Degree with Foundation Year" },
        { id: 8, item: "Diploma" },
      ]
    },
    {
      id: 23,
      crs: course_social,
      title: "Political & Social Science",
      list: [
        { id: 1, item: "UG" },
        { id: 2, item: "PG" },
        { id: 3, item: "MA" },
        { id: 4, item: "BA" },
        { id: 5, item: "BSc" },
        { id: 6, item: "MSc" },
        { id: 7, item: "Degree with Foundation Year" },
        { id: 8, item: "Diploma" },
      ]
    },
    {
      id: 24,
      crs: course_design,
      title: "Art & Design",
      list: [
        { id: 1, item: "UG" },
        { id: 2, item: "PG" },
        { id: 3, item: "MA" },
        { id: 4, item: "BA" },
        { id: 5, item: "BSc" },
        { id: 6, item: "MSc" },
        { id: 7, item: "Degree with Foundation Year" },
        { id: 8, item: "Diploma" },
      ]
    },
    {
      id: 25,
      crs: course_geography,
      title: "Geography",
      list: [
        { id: 1, item: "UG" },
        { id: 2, item: "PG" },
        { id: 3, item: "MA" },
        { id: 4, item: "BA" },
        { id: 5, item: "BSc" },
        { id: 6, item: "MSc" },
        { id: 7, item: "Degree with Foundation Year" },
        { id: 8, item: "Diploma" },
      ]
    },
    {
      id: 26,
      crs: course_history,
      title: "History",
      list: [
        { id: 1, item: "UG" },
        { id: 2, item: "PG" },
        { id: 3, item: "MA" },
        { id: 4, item: "BA" },
        { id: 5, item: "BSc" },
        { id: 6, item: "MSc" },
        { id: 7, item: "Degree with Foundation Year" },
        { id: 8, item: "Diploma" },
      ]
    },
    {
      id: 27,
      crs: course_communication,
      title: "Marketing Media & Communications",
      list: [
        { id: 1, item: "UG" },
        { id: 2, item: "PG" },
        { id: 3, item: "MA" },
        { id: 4, item: "BA" },
        { id: 5, item: "BSc" },
        { id: 6, item: "MSc" },
        { id: 7, item: "Degree with Foundation Year" },
        { id: 8, item: "Diploma" },
      ]
    },

    {
      id: 28,
      crs: course_maths,
      title: "Mathematics",
      list: [
        { id: 1, item: "UG" },
        { id: 2, item: "PG" },
        { id: 3, item: "MA" },
        { id: 4, item: "BA" },
        { id: 5, item: "BSc" },
        { id: 6, item: "MSc" },
        { id: 7, item: "Degree with Foundation Year" },
        { id: 8, item: "Diploma" },
      ]
    },
    {
      id: 29,
      crs: course_agriculture,
      title: "Agriculture Science",
      list: [
        { id: 1, item: "UG" },
        { id: 2, item: "PG" },
        { id: 3, item: "MA" },
        { id: 4, item: "BA" },
        { id: 5, item: "BSc" },
        { id: 6, item: "MSc" },
        { id: 7, item: "Degree with Foundation Year" },
        { id: 8, item: "Diploma" },
      ]
    },
    {
      id: 30,
      crs: course_information,
      title: "Information Science",
      list: [
        { id: 1, item: "UG" },
        { id: 2, item: "PG" },
        { id: 3, item: "MA" },
        { id: 4, item: "BA" },
        { id: 5, item: "BSc" },
        { id: 6, item: "MSc" },
        { id: 7, item: "Degree with Foundation Year" },
        { id: 8, item: "Diploma" },
      ]
    },
    {
      id: 31,
      crs: course_bio,
      title: "Biological & Life Science",
      list: [
        { id: 1, item: "UG" },
        { id: 2, item: "PG" },
        { id: 3, item: "MA" },
        { id: 4, item: "BA" },
        { id: 5, item: "BSc" },
        { id: 6, item: "MSc" },
        { id: 7, item: "Degree with Foundation Year" },
        { id: 8, item: "Diploma" },
      ]
    }

  ]
export default function Course() {
  // const [modalOpen, setModalOpen] = useState(false);

  // useEffect(() => {
  //   setModalOpen(true);
  // }, []);
  return (
    <div className="mainDiv">
      <div className="Navbar">
        <Navbar />
      </div>
      {/* <ModalEvent isOpenInitially={modalOpen} /> */}
      <br />
      <br />
      <div className="container">
        <Wrapper>
          <h1>Chart Your Global Journey</h1>
          <h2>Find Your Path to International Education</h2>
        </Wrapper>
        <FlexRow>
          {couseJson.map((data, i) => (
            <BorderBox key={i} bgImage={data.crs}>
              <ListWrapper>
                {data.list.map((subData, j) => (
                  <ListItems key={j}>{subData.item}</ListItems>
                ))}
              </ListWrapper>
              <BottomContent>
                <Title>{data.title}</Title>
                <ViewBtn to={`/course/${data.id}`}>
                  <span>View Courses</span>
                  <span className="material-icons">arrow_forward</span>
                </ViewBtn>
              </BottomContent>
            </BorderBox>
          ))}
        </FlexRow>
      </div>
      <Footer />
    </div>
  );
}

const Wrapper = styled.div`
  margin-top: 120px;
  h1 {
    font-size: 35px;
    font-weight: 700;
    color: #38b772;
  }
  h2 {
    font-size: 16px;
    font-weight: 400;
    color: #494949;
  }
`;

const FlexRow = styled.div`
  margin-top: 60px;
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
`;

const BorderBox = styled.div`
  position: relative;
  width: 300px;
  height: 390px;
  padding: 10px;
  border-radius: 12px;
  overflow: hidden;
  margin-bottom: 20px;
  transition: filter 0.3s;
  @media (max-width: 576px) {
    height: 400px;
  }

  &:hover {
    filter: brightness(100%);
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url(${(props) => props.bgImage});
    background-size: cover;
    background-repeat: no-repeat;
    filter: brightness(80%);
  }
`;

const ListWrapper = styled.div`
  background-color: rgb(29 101 63 / 80%);
  padding: 10px;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 10px;
    background-color: #d3d5d2;
  }
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background: #879b8f;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #686d69;
  }
  width: 280px;
  height: 280px;
  border-radius: 12px;
  position: absolute;
  bottom: -300px; /* Initially off-screen */
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.5s ease-in-out, bottom 0.5s ease-in-out;
  pointer-events: none;
  @media (max-width: 576px) {
    top: 20px;
    width: 220px;
    height: 270px;
  }

  ${BorderBox}:hover & {
    opacity: 1;
    bottom: 100px; /* Slide up to 30px from the bottom */
    pointer-events: auto;
  }

  /* Directly set filter for ListWrapper */
  &:hover {
    filter: brightness(100%);
  }
`;

const ListItems = styled.p`
  font-size: 13px;
  font-weight: 400;
  color: #ffffff;
  margin: 5px 0;
`;

const BottomContent = styled.div`
  position: absolute;
  bottom: 20px;
  left: 25%;
  transform: translateX(-25%);
`;

const Title = styled.h3`
  color: #ffffff;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 10px;
  text-align:center;
`;

const ViewBtn = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #38b772;
  color: #ffffff;
  padding: 8px 15px;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s;
  text-decoration:none;
  span.material-icons {
    font-size: 16px;
    margin-left: 5px;
  }
  &:hover {
    background-color: #228f58;
  }
`;
