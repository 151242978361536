import React, { useState } from 'react';
import styled from 'styled-components';
import InputBox from '../InputBox/InputBox';
import SelectBox from '../SelectBox/SelectBox';
import TextArea from '../TextArea/TextArea';
import { Fromimg } from '../../assets/images';


const countryData = [
    { id: 1, label: "Australia" },
    { id: 2, label: "United Kingdom" },
    { id: 3, label: "United States" },
    { id: 4, label: "Switzerland" },
    { id: 5, label: "Sweden" },
    { id: 6, label: "Ireland" },
    { id: 7, label: "Georgia" },
    { id: 8, label: "France" },
    { id: 8, label: "China" },
    { id: 8, label: "Canada" },
];
export default function RegisterForm() {
    const [country, setCountry] = useState(null);
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        phone: "",
        subject: "",
        message: "",
    });
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };
    const handleSendEmail = () => {
        const recipientEmail = "saneen577@gmail.com"; // Update with the recipient's email address
        const subject = "New Message from contact us page";
        const body = `Name: ${formData.name}\nEmail: ${formData.email}\nPhone: ${formData.phone}\Country: ${country}\Subject: ${formData.subject}\Message: ${formData.message}`;

        // Open default email client with pre-filled email template
        window.open(
            `mailto:${recipientEmail}?subject=${encodeURIComponent(
                subject
            )}&body=${encodeURIComponent(body)}`
        );
        // Clear form data
        setFormData({
            name: "",
            email: "",
            phone: "",
            message: "",
        });
        setCountry(null);

        // Optionally, you can redirect or perform any other action after sending the email
    };
    return (
        <Wrapper>
            <div className='container'>
                <MainDiv>
                    <Image src={Fromimg} alt='' />
                    <FlexColumn>
                        <Title>Dreams soar. Register now!</Title>
                        <div className='container' style={{ marginTop: 20 }}>
                            <div className='row'>
                                <div className='col-lg-6 col-sm-6 col-xs-12'>
                                    <InputBox
                                        type="text"
                                        placeholder="Name"
                                        value={formData.name}
                                        onChange={handleChange}
                                        name="name"
                                    />
                                </div>
                                <div className='col-lg-6 col-sm-6 col-xs-12'>
                                    <InputBox
                                        type="email"
                                        placeholder="Email"
                                        value={formData.email}
                                        onChange={handleChange}
                                        name="email"
                                    />
                                </div>
                                <div className='col-lg-6 col-sm-6 col-xs-12'>
                                    <InputBox
                                        type="text"
                                        placeholder="Phone"
                                        value={formData.phone}
                                        onChange={handleChange}
                                        name="phone"
                                    />
                                </div>
                                <div className='col-lg-6 col-sm-6 col-xs-12'>
                                    <InputBox
                                        type="text"
                                        placeholder="Subject"
                                        value={formData.subject}
                                        onChange={handleChange}
                                        name="subject"
                                    />
                                </div>
                                <div className='col-12' style={{ marginTop: 10 }}>
                                    <SelectBox
                                        placeholder="Prefered Country"
                                        data={countryData}
                                        value={country}
                                        onSelect={setCountry}
                                    />
                                </div>
                                <div className='col-12'>
                                    <TextArea
                                        name="message"
                                        id="message"
                                        placeholder="Enter Message"
                                        value={formData.message}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                        </div>
                        <Button onClick={handleSendEmail}>Submit</Button>
                    </FlexColumn>
                </MainDiv>
            </div>
        </Wrapper>
    )
}
const Wrapper = styled.div`
background-color:#F4F4F4;
color:#494949;
margin-top:60px;
`;
const MainDiv = styled.div`
margin:60px 0;
display:flex;
flex-direction:row;
justify-content:center;
align-items:center;
@media (max-width: 768px){
    flex-direction:column;
}
`;
const Image = styled.img`
width:40%;
border-radius:25px;
height:100%;
padding:0px 20px;
@media (max-width:768px) {
    width:100%;
    padding:20px;
    height:70%;
    border-radius:30px;
}
`;
const FlexColumn = styled.div`
display:flex;
flex-direction:column;
width:60%;
gap:20px;
margin-top:30px;
padding:20px;
@media (max-width:768px) {
    width:100%;
}
`;
const Title = styled.h1`
font-size:35px;
font-weight:700;
`;
const Button = styled.button`
  cursor: pointer;
  background-color: #38b772;
  color: #fff;
  border-radius: 10px;
  padding: 10px 20px;
  width: 100%;
  border: 0px solid;
  box-shadow: inset 0 0 0 0 #38b772;
  transition: color .5s ease-in-out, box-shadow .5s ease-in-out;
  &:hover {
    background-color: #fff;
    border: 1px solid #38b772;
    color: #38b772;
    box-shadow: inset 200px 0 0 0 #fff;
  }
`;
