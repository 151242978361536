import React from "react";
import styled, { css } from "styled-components";
import { rgba } from "polished";

function Modal({ children, isVisible, close, isBackdrop }) {
  const backDropRef = (e) => {
    if (isBackdrop) {
      if (e.target === e.currentTarget) {
        close(false);
      }
    }
  };

  return (
    <Wrapper onClick={backDropRef} isVisible={isVisible}>
      {children}
    </Wrapper>
  );
}
const Wrapper = styled.div`
  position: ${(p) => p.position || "fixed"};
  z-index: ${(p) => p.zIndex || 2};
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  visibility: hidden;
  opacity: 0;
  background: ${rgba("black", 0.5)};
  transition-property: visibility, opacity;
  transition-duration: 0.5s;
  ${(p) => p.isVisible && visible};
  overflow-x: hidden;
  ::-webkit-scrollbar {
    display: none;
  }
  z-index: 9999;
  ${"" /* overflow-y:hidden; */}
  .
`;
const visible = css`
  visibility: visible;
  opacity: 1;
`;

export default Modal;

