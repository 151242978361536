import React, { useState } from "react";
import styled from "styled-components";
import { Link, NavLink } from "react-router-dom";
import { aeron_logo } from "../../assets/images";
import SelectNavbar from "../SelectBox/SelectNavbar";
import InputBox from "../InputBox/InputBox";
import SelectBox from "../SelectBox/SelectBox";
import TextArea from "../TextArea/TextArea";
import Modal from "../Modal/Modal";

const aboutData = [
  { id: 1, label: "Team", subRoute: "/about/team" },
  { id: 2, label: "Core Values", subRoute: "/about/core-values" },
  { id: 3, label: "Testimonials", subRoute: "/about/testimonial" },
  { id: 4, label: "Career", subRoute: "/about/career" },
  { id: 5, label: "Gallery", subRoute: "/about/gallery" },
];
const countryData = [
  { id: 1, label: "Australia", subRoute: "/country/1" },
  { id: 2, label: "United Kingdom", subRoute: "/country/2" },
  { id: 3, label: "United States", subRoute: "/country/3" },
  { id: 4, label: "Switzerland", subRoute: "/country/4" },
  { id: 5, label: "Sweden", subRoute: "/country/5" },
  { id: 6, label: "Ireland", subRoute: "/country/6" },
  { id: 7, label: "Georgia", subRoute: "/country/7" },
  { id: 8, label: "France", subRoute: "/country/8" },
  { id: 9, label: "China", subRoute: "/country/9" },
  { id: 10, label: "Canada", subRoute: "/country/10" },
];
let courseData = [
  { id: 10, label: "Health and Medicine", subRoute: "/course/10" },
  { id: 7, label: "Hospitality and Tourism", subRoute: "/course/7" },
  { id: 6, label: "Engineering", subRoute: "/course/6" },
  { id: 1, label: "Business and Management ", subRoute: "/course/1" },
  { id: 8, label: "Accounting and Finance", subRoute: "/course/8" },
  { id: 14, label: "Environmental Science", subRoute: "/course/14" },
  { id: 11, label: "Building and Architecture", subRoute: "/course/11" },
  { id: 13, label: "Welfare Studies and Services", subRoute: "/course/13" },
  { id: 16, label: "Media and Communication", subRoute: "/course/16" },
]

export default function Navbar() {
  const [isOpen, setIsOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [country, setCountry] = useState(null);
  const [course, setCourse] = useState(null);
  const [about, setAbout] = useState(null);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  const [countryModal, setCountryModal] = useState(null);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleSendEmail = () => {
    const recipientEmail = "saneen577@gmail.com"; // Update with the recipient's email address
    const subject = "New Message from contact us page";
    const body = `Name: ${formData.name}\nEmail: ${formData.email}\nPhone: ${formData.phone}\Country: ${countryModal}\Message: ${formData.message}`;

    // Open default email client with pre-filled email template
    window.open(
      `mailto:${recipientEmail}?subject=${encodeURIComponent(
        subject
      )}&body=${encodeURIComponent(body)}`
    );
    // Clear form data
    setFormData({
      name: "",
      email: "",
      phone: "",
      message: "",
    });
    setCountryModal(null);

    // Optionally, you can redirect or perform any other action after sending the email
  };
  return (
    <Wrapper><MainDiv>
      <Link exact to="/">
        <BrandImg src={aeron_logo} alt="" />
      </Link>
      <NavListWrapper open={isMenuOpen}>
        <CloseButton onClick={toggleMenu}>
          <CloseIcon>&times;</CloseIcon>
        </CloseButton>
        <NavList>
          <ListItems exact to="/" onClick={toggleMenu}>
            Home
          </ListItems>
          <SelectNavbar
            placeholder="About Us"
            data={aboutData}
            value={about}
            onSelect={setAbout}
            route='/about/about'
            isLength={true}
          />
          <SelectNavbar
            placeholder="Country"
            data={countryData}
            value={country}
            onSelect={setCountry}
            route='/country/country'
          />
          <SelectNavbar
            placeholder="Course"
            data={courseData}
            value={course}
            onSelect={setCourse}
            route='/course/course'
          />
          <ListItems to="/service" onClick={toggleMenu}>
            Services
          </ListItems>
          <ListItems to="/contact" onClick={toggleMenu}>
            Contact Us
          </ListItems>
        </NavList>
      </NavListWrapper>
      <MobileView>
        <Button onClick={() => setIsOpen(!isOpen)}>Let's Talk</Button>
        <BurgerButton onClick={toggleMenu}>
          <BurgerBar />
          <BurgerBar />
          <BurgerBar />
        </BurgerButton>
      </MobileView>
      <Modal
        isVisible={isOpen}
        isBackdrop={true}
        close={(value) => setIsOpen(value)}
      >
        <ModalWrapper>
          <MsgBox>
            <Header>
              <Title>Aeron Contacts</Title>
              <Close onClick={() => setIsOpen(false)} className="material-icons">cancel</Close>
            </Header>
            <div className='row' style={{ marginTop: 20 }}>
              <div className='col-lg-6 col-sm-6 col-xs-12'>
                <InputBox
                  type="text"
                  placeholder="Name"
                  value={formData.name}
                  onChange={handleChange}
                  name="name"
                />
              </div>
              <div className='col-lg-6 col-sm-6 col-xs-12'>
                <InputBox
                  type="text"
                  placeholder="Phone"
                  value={formData.phone}
                  onChange={handleChange}
                  name="phone"
                />
              </div>
              <div className='col-lg-6 col-sm-6 col-xs-12'>
                <InputBox
                  type="email"
                  placeholder="Enter Your Email"
                  value={formData.email}
                  onChange={handleChange}
                  name="email"
                />
              </div>
              <div className='col-lg-6 col-sm-6 col-xs-12' style={{ marginTop: 10 }}>
                <SelectBox
                  placeholder="Prefered Country"
                  data={countryData}
                  value={countryModal}
                  onSelect={setCountryModal}
                />
              </div>
              <div className='col-12'>
                <TextArea
                  name="message"
                  id="message"
                  placeholder="Enter Message"
                  value={formData.message}
                  onChange={handleChange}
                />
              </div>
            </div>
            <FooterButton>
              <ModalButton onClick={handleSendEmail}>Send</ModalButton>
            </FooterButton>
          </MsgBox>
        </ModalWrapper>
      </Modal>
    </MainDiv>
    </Wrapper>

  );
}

const Wrapper = styled.div`
position:fixed;
z-index:9;
height:20vh;
width:100%;
box-shadow: rgba(0, 0, 0, 0.25) 0px 5px 15px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width:992px){
    display:block;
  }
`;
const MainDiv = styled.div`
display: flex;
flex-direction: row;
justify-content: space-between;
align-items: center;
@media (max-width: 768px) {
  padding-top: 30px;
  align-items: center;
  padding-right: 20px;
}
`;
const BrandImg = styled.img`
  width: 250px;
  @media (max-width: 576px) {
    width: 130px;
  }
`;
const BurgerButton = styled.div`
  display: none;
  flex-direction: column;
  cursor: pointer;
  @media (max-width: 992px) {
    display: flex;
  }
`;
const BurgerBar = styled.div`
  width: 25px;
  height: 3px;
  background-color: #38b772;
  margin: 3px 0;
`;
const CloseButton = styled.div`
  display: none;
  @media (max-width: 768px) {
    display: flex;
    justify-content: flex-end;
    padding: 10px;
  }
`;
const CloseIcon = styled.span`
  font-size: 24px;
  cursor: pointer;
  color: #fff;
`;
const MobileView = styled.div`
@media (max-width:992px){
  position: absolute;
  right: 20px;
  display: flex;
  flex-direction:row;
  align-items:center;
  gap:10px;

}

  `;
const NavListWrapper = styled.div`
  @media (max-width: 992px) {
    position: fixed;
    top: 0;
    left: ${({ open }) => (open ? "0" : "-250px")};
    width: 250px;
    height: 100%;
    background-color: ${({ open }) =>
    open ? "rgba(52, 52, 52, 0.8)" : "transparent"};
    transition: left 0.3s ease-in-out;
    z-index: 100;
  }
`;
const NavList = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  gap: 20px;
  padding: 40px;
  @media (max-width: 992px) {
    flex-direction: column;
  }
`;
const ListItems = styled(NavLink)`
  position: relative;
  font-size: 16px;
  color: #000;
  background-color: transparent;
  margin-bottom: 20px;
  text-decoration: none;
  transition: color 0.3s ease-in-out;
  &:hover {
    color: #38b772;
    &::after {
      width: 100%;
    }
  }
  &::after {
    content: "";
    position: absolute;
    width: 0;
    height: 2px;
    bottom: -5px;
    left: 0;
    background-color: #38b772;
    transition: width 0.3s ease-in-out;
  }
  &.active::after {
    width: 100%;
  }
  @media (max-width: 992px) {
    color: #fff;
    &:hover {
      color: #fff;
      &::after {
        background-color: #fff;
      }
    }
    &.active::after {
      background-color: #fff;
    }
  }
`;
const Button = styled.div`
cursor: pointer;
background-color: #38b772;
color: #fff;
border-radius: 10px;
padding: 10px 20px;
width: fit-content;
border: 0px solid;
text-decoration:none;
box-shadow: inset 0 0 0 0 #38b772;
  transition: color .5s ease-in-out, box-shadow .5s ease-in-out;
  &:hover {
    background-color: #fff;
    border: 1px solid #38b772;
    color: #38b772;
    box-shadow: inset 200px 0 0 0 #fff;
  }
`;
const ModalWrapper = styled.div`
position: absolute;
margin: auto;
left: 50%;
right: auto;
top: 50%;
bottom: auto;
transform: translate(-50%, -50%);
background-color: #fff;
border-radius: 10px;
width: 500px;
@media (max-width: 576px) {
  width: 90%;
  height:auto;
}
button {
  margin: auto;
  display: block;
  }
  .flex-row {
    display: flex;
    align-items: center;
  }
`;
const MsgBox = styled.div`
  background: ${(p) => p.theme.colorWhite};
  border-radius: 10px;
  padding: 16px;
`;
const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;
`;
const Title = styled.h3`
  margin: 0;
  font-weight: 600;
  font-size: 20px;
  color: #494949;
`;
const Close = styled.span`
  font-weight: 600;
  font-size: 20px;
  line-height: 17px;
  color: red;
  cursor: pointer;
`;
const FooterButton = styled.div`
  margin-top: 28px;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;
const ModalButton = styled.button`
cursor: pointer;
background-color: #38b772;
color: #fff;
border-radius: 10px;
padding: 10px 20px;
width: fit-content;
border: 0px solid;
text-decoration:none;
box-shadow: inset 0 0 0 0 #38b772;
  transition: color .5s ease-in-out, box-shadow .5s ease-in-out;
  &:hover {
    background-color: #fff;
    border: 1px solid #38b772;
    color: #38b772;
    box-shadow: inset 200px 0 0 0 #fff;
  }
`;
