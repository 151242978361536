import React from 'react';
import styled from 'styled-components';
import { Navbar, Footer } from '../../components';
import {
  australia,
  canada,
  china,
  class_color,
  france,
  georgia,
  ireland,
  sweden,
  switzerland,
  uk,
  us,
} from "../../assets/images";
import { Link } from "react-router-dom";
let countryImage = [
  { id: 1, un: australia, flag: "Australia", courses: 3061, university: 41 },
  { id: 2, un: uk, flag: "United Kingdom", courses: 3612, university: 89 },
  { id: 3, un: us, flag: "United States", courses: 2000, university: 20 },
  { id: 4, un: switzerland, flag: "Switzerland", courses: 5, university: 2 },
  { id: 5, un: sweden, flag: "Sweden", courses: 2000, university: 20 },
  { id: 6, un: ireland, flag: "Ireland", courses: 0, university: 0 },
  { id: 7, un: georgia, flag: "Georgia", courses: 2000, university: 20 },
  { id: 8, un: france, flag: "France", courses: 10, university: 1 },
  { id: 9, un: china, flag: "China", courses: 2000, university: 20 },
  { id: 10, un: canada, flag: "Canada", courses: 2006, university: 52 },
];

export default function Country() {
  // const [modalOpen, setModalOpen] = useState(false);

  //   useEffect(() => {
  //     setModalOpen(true);
  //   }, []);
  return (
    <div className="mainDiv">
      <div className="Navbar">
        <Navbar />
      </div>
      {/* <ModalEvent isOpenInitially={modalOpen} /> */}
      <br />
      <br />
      <div className="container">
        <Wrapper>
          <h1>Select Your Study Destination</h1><br />
          <h2>Find the Perfect Country and University for Your Academic Adventure</h2>
        </Wrapper>
        <FlexRow>
          {countryImage.map((item, i) => {
            return (
              <div className="border-box">
                <img key={i} className="un-img" src={item.un} alt="" />
                <div className="column">
                  <div style={{ display: "flex", flexDirection: "row", gap: "15px", alignItems: "center", }}>
                    <div className="flex-row">
                      <i class="fa fa-file-text-o" style={{ color: "#38b772" }} />
                      <span className="text">{item.courses} Courses</span>
                    </div>
                    <div className="flex-row">
                      <i class="fa fa-university" style={{ color: "#38b772" }} />
                      <span className="text">{item.university} University</span>
                    </div>
                  </div>
                  <span className="text" style={{ fontWeight: 700, fontSize: "20px" }}>{item.flag}</span>
                  <ViewBtn to={`/country/${item.id}`}>
                    <span>Explore</span>
                    <span className="material-icons">arrow_forward</span>
                  </ViewBtn>
                </div>
              </div>
            );
          })}
        </FlexRow>
      </div>
      <Footer />
    </div>
  )
}
const Wrapper = styled.div`
  margin-top: 120px;
  h1 {
    font-size: 35px;
    font-weight: 700;
    color: #38b772;
  }
  h2 {
    font-size: 16px;
    font-weight: 400;
    color: #494949;
  }
`;
const FlexRow = styled.div`
padding:70px 0;
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  .border-box {
    border-radius: 12px;
    border: 1px solid #879b8f;
    padding: 25px;
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: center;
    @media (max-width: 576px) {
      width:100%;
      flex-direction:column;
    }
    .column {
      display: flex;
    flex-direction: column;
    gap:10px;
    }
    .flex-row {
      display: flex;
    flex-direction: row;
    gap:10px;
    align-items:center;
    color: #879b8f;
    @media (max-width: 576px) {
      flex-direction:column;
    }
    }
  }
  .text {
    font-size: 16px;
    color: #879b8f;
  }
  .un-img {
    width: 150px;
    height:150px;
    border-radius:50%;
  }
`;
const ViewBtn = styled(Link)`
display: flex;
  align-items: center;
  justify-content: center;
  background-color: #38b772;
  color: #ffffff;
  padding: 8px 15px;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s;
  text-decoration:none;
  span.material-icons {
    font-size: 16px;
    margin-left: 5px;
  }
  &:hover {
    background-color: #228f58;
  }
  /* Directly set filter for ViewBtn */
  filter: brightness(100%);
`;