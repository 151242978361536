import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Modal from '../Modal/Modal';
import { Fromimg } from '../../assets/images';

const ModalEvent = ({ isOpenInitially = false }) => {
  const [isOpen, setIsOpen] = useState(isOpenInitially);

  useEffect(() => {
    setIsOpen(isOpenInitially);
  }, [isOpenInitially]);

  return (
    <Modal
      isVisible={isOpen}
      isBackdrop={true}
      close={(value) => setIsOpen(value)}
    >
      <ModalWrapper>
        <MsgBox>
          <Header>
            <Title>Upcoming Events</Title>
            <Close onClick={() => setIsOpen(false)} className="material-icons">cancel</Close>
          </Header><br/>
          <div className='container'>
            <img src={Fromimg} alt='' />
          </div>
          <FooterButton>
            <ModalButton>Send</ModalButton>
          </FooterButton>
        </MsgBox>
      </ModalWrapper>
    </Modal>
  );
}

const ModalWrapper = styled.div`
  position: absolute;
  margin: auto;
  left: 50%;
  right: auto;
  top: 50%;
  bottom: auto;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border-radius: 10px;
  width: 500px;
  @media (max-width: 576px) {
    width: 90%;
    height:auto;
  }
  button {
    margin: auto;
    display: block;
  }
  .flex-row {
    display: flex;
    align-items: center;
  }
`;
const MsgBox = styled.div`
  background: ${(p) => p.theme.colorWhite};
  border-radius: 10px;
  padding: 16px;
  img {
    width:300px;
  }
`;
const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;
`;
const Title = styled.h3`
  margin: 0;
  font-weight: 600;
  font-size: 20px;
  color: #494949;
`;
const Close = styled.span`
  font-weight: 600;
  font-size: 20px;
  line-height: 17px;
  color: red;
  cursor: pointer;
`;
const FooterButton = styled.div`
  margin-top: 28px;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;
const ModalButton = styled.button`
  cursor: pointer;
  background-color: #38b772;
  color: #fff;
  border-radius: 10px;
  padding: 10px 20px;
  width: fit-content;
  border: 0px solid;
  text-decoration:none;
  box-shadow: inset 0 0 0 0 #38b772;
  transition: color .5s ease-in-out, box-shadow .5s ease-in-out;
  &:hover {
    background-color: #fff;
    border: 1px solid #38b772;
    color: #38b772;
    box-shadow: inset 200px 0 0 0 #fff;
  }
`;

export default ModalEvent;
