import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

export default function SelectNavbar({ data, route, placeholder, value,isLength, onSelect }) {
  const [isActive, setIsActive] = useState(false);
  const [isHoveredBtn, setIsHoveredBtn] = useState(false);
  const [isHoveredItems, setIsHoveredItems] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (isActive && ref.current && !ref.current.contains(e.target)) {
        setIsActive(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isActive]);

  const onChange = (value) => {
    onSelect(value);
    setIsActive(false);
  };

  return (
    <Wrapper ref={ref}>
      <div className="dropdown">
        <DropDownBtn
          isOpen={isActive || isHoveredBtn}
          onMouseEnter={() => setIsHoveredBtn(true)}
          onMouseLeave={() => setIsHoveredBtn(false)}
          onClick={() => {
            setIsActive(!isActive);
          }}
          selected={value}
          to={route}
        >
          {value ? value.label : placeholder}
          <span className="material-icons">
            {isActive ? "expand_less" : "expand_more"}
          </span>
        </DropDownBtn>
        <ItemsWrapper
          isOpen={isActive
            || isHoveredBtn
          }
          onMouseEnter={() => setIsHoveredItems(true)}
          onMouseLeave={() => setIsHoveredItems(false)}
          isLength={isLength}
        >
          {data.map((item, i) => {
            return (
              <ItemList
                key={i}
                onClick={() => {
                  onChange(item);
                }}
                to={item.subRoute}
                selected={value ? value.label === item.label : false}
              >
                <span>{item.label}</span>
              </ItemList>
            );
          })}
        </ItemsWrapper>
      </div>
    </Wrapper>
  );
}
const Wrapper = styled.div`
  width: auto;
  .dropdown {
    margin: 0 auto;
    // width: 150px;
    position: relative;
  }
`;
const DropDownBtn = styled(Link)`
  -webkit-transition: all 0.35s ease-in-out;
  -moz-transition: all 0.35s ease-in-out;
  -ms-transition: all 0.35s ease-in-out;
  -o-transition: all 0.35s ease-in-out;
  transition: all 0.35s ease-in-out;
  cursor: pointer;
  background-color: transparent;
  display: flex;
  align-items: center;
  gap: 20px;
  text-decoration:none;
//   justify-content: space-between;
//   padding: 10px 20px;
  border-radius: 4px;
  color: #000;
  font-weight: 400;
  @media (max-width: 768px){
    color:#fff;
  }
`;
const ItemsWrapper = styled.div`
  margin-top: 12px;
  position: absolute;
  z-index: 1;
  left: 0;
  width: 300px;
  height: ${(p) => p.isLength ? "auto" : "300px"};
  overflow-y: ${(p) => (p.isLength ? "auto" : "scroll")};
  &::-webkit-scrollbar {
    width: ${(p) => (p.isLength ? "0px" : "10px")}; /* Hide scrollbar when isLength is true */
    background-color: ${(p) => (p.isLength ? "transparent" : "#d3d5d2")};
  }
&::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 10px;
}
&::-webkit-scrollbar-thumb {
  background: #879b8f; 
  border-radius: 10px;
}
&::-webkit-scrollbar-thumb:hover {
  background: #686d69; 
}
  border-radius: 4px;
  // background-color: #e2e3e2;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 5px 15px;
  display: ${(p) => (p.isOpen ? "block" : "none")};
  background-color:#fff;
  @media (max-width: 576px){
    width:250px;
  }
`;
const ItemList = styled(Link)`
padding: 11px 16px;
cursor: pointer;
display: flex;
flex-direction: row;
gap:10px;
text-decoration:none;
// border-bottom:1px solid #879b8f;
background: ${(p) => (p.selected ? "#38b772" : "#fff")};
color:${(p) => (p.selected ? "#fff" : "#879b8f")};
font-weight: ${(p) => (p.selected ? 600 : 400)};
&:hover {
  background: #38b772;
  font-weight: 600;
  color:#fff;
}
`;
