import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./Home.css";
import { Link, NavLink } from "react-router-dom";
import styled from "styled-components";
import {
  support,
  Search,
  Back_in_time,
  Analysis,
  side_about_img,
  profile1,
  profile3,
  profile2,
  banner,
  banner1,
  banner2,
  banner3,
  course_home,
  get_image,
  get_img,
  banner_men1,
} from "../assets/images";
import {
  Navbar,
  Footer,
  Universities,
  Countries,
  Testimonials,
  ProfileCard,
  Courses,
  SubNavbar,
  Support,
  RegisterForm,
  Events_Blogs,
  QA,
  ModalEvent,
} from "../components";
import ServiceCard from "../components/ServiceCard/ServiceCard";
import { act } from "@testing-library/react";

let sliderData = [
  {
    id: 1,
    title:
      "Exploring the Skies: Welcome to Aeron",
    image: banner1,
    person: banner_men1,
    sub: "Where passion for aviation meets precision in every endeavor, we elevate your flight experience.",
  },
  {
    id: 2,
    title:
      "Fly High with Aeron: Discover Our Services",
    image: banner1,
    person: banner_men1,
    sub: "With us, your journey takes flight to new heights of excellence.",
  },
  {
    id: 3,
    title:
      "Aeron Blog: Insights into Aviation and Beyond",
    image: banner1,
    person: banner_men1,
    sub: "Join us on a journey where innovation meets the horizon, soaring beyond expectations.",
  },
];

let reviewData = [
  {
    id: 1,
    reviewContent:
      "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here',making it look like readable English. ",
    image: profile1,
    name: "Sithara Nair",
  },
  {
    id: 2,
    reviewContent:
      "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here',making it look like readable English. ",
    image: profile3,
    name: "Sarah Thomas",
  },
  {
    id: 3,
    reviewContent:
      "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here',making it look like readable English. ",
    image: profile2,
    name: "Shahil P",
  },
];

function Home() {
  const [isSupportOpen, setIsSupportOpen] = useState(false);

  const toggleSupport = () => {
    setIsSupportOpen(!isSupportOpen);
  };

  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % sliderData.length);
    }, 2000);

    return () => {
      clearInterval(interval);
    };
  }, [sliderData]);
  const activeBanner = sliderData[currentIndex];

  const navigate = useNavigate(); // Initialize navigate
  const handleAboutOpenClick = () => {
    navigate("/about/about");
  };

  const [visaCount, setVisaCount] = useState(0);
  const [universitiesCount, setUniversitiesCount] = useState(0);
  const [countriesCount, setCountriesCount] = useState(0);
  const [coursesCount, setCoursesCount] = useState(0);

  useEffect(() => {
    const finalValues = {
      visaCount: 50000,
      universitiesCount: 80,
      countriesCount: 29,
      coursesCount: 300,
    };
    const animationDuration = 5000; // Duration of animation in milliseconds
    const incrementPerKey = {};

    // Calculate increment per key
    for (const key in finalValues) {
      incrementPerKey[key] = finalValues[key] / (animationDuration / 100);
    }

    let currentValues = {
      visaCount: 0,
      universitiesCount: 0,
      countriesCount: 0,
      coursesCount: 0,
    };

    const timer = setInterval(() => {
      // Update counts for each key until it reaches the final value
      for (const key in finalValues) {
        currentValues[key] += incrementPerKey[key];
        const roundedValue = Math.round(currentValues[key]);
        if (roundedValue <= finalValues[key]) {
          switch (key) {
            case "visaCount":
              setVisaCount(roundedValue);
              break;
            case "universitiesCount":
              setUniversitiesCount(roundedValue);
              break;
            case "countriesCount":
              setCountriesCount(roundedValue);
              break;
            case "coursesCount":
              setCoursesCount(roundedValue);
              break;
            default:
              break;
          }
        } else {
          // If the final value is reached, set the state to the final value and clear the interval
          switch (key) {
            case "visaCount":
              setVisaCount(finalValues[key]);
              break;
            case "universitiesCount":
              setUniversitiesCount(finalValues[key]);
              break;
            case "countriesCount":
              setCountriesCount(finalValues[key]);
              break;
            case "coursesCount":
              setCoursesCount(finalValues[key]);
              break;
            default:
              break;
          }
          clearInterval(timer);
        }
      }
    }, 100); // Run every 100 milliseconds

    return () => clearInterval(timer); // Cleanup on component unmount
  }, []); // Empty dependency array ensures the effect runs only once

  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    setModalOpen(true); // Open the modal when the component mounts
  }, []);

  return (
    <Wrapper>
      <ModalEvent isOpenInitially={modalOpen} />
      <Navbar />
      <SupportBtnStyled onClick={toggleSupport} isOpen={isSupportOpen}>Book Appoinment</SupportBtnStyled>
      <Support isOpen={isSupportOpen} isClose={toggleSupport} />
      <br /><br /><br /><br /><br /><HomeBannerRow bgImage={activeBanner.image}><br /><br />
        <BannerContainer><br />
          <BannerHead>{activeBanner.title}</BannerHead>
          <BannerPara>{activeBanner.sub}</BannerPara>
          <div className="homeViewMore" onClick={handleAboutOpenClick}>
            View More
          </div>
          <div className="flex-row">
            <div className="cover"><a href="https://api.whatsapp.com/send?phone=8301829642"><i className="fa fa-whatsapp"></i></a></div>
            <div className="cover"><a href="https://www.facebook.com/profile.php?id=61553428405214"><i className="fa fa-facebook"></i></a></div>
            <div className="cover"><a href="https://www.instagram.com/aeroneducationservice"><i className="fa fa-instagram"></i></a></div>
            <div className="cover"><a href="mailto:aeroneducationpmna@gmail.com"><i className="fa fa-envelope"></i></a></div>
            <div className="cover"><a href="https://www.linkedin.com/in/yourprofile"><i className="fa fa-linkedin"></i></a></div>
            <div className="cover"><a href="https://telegram.me/yourchannel"><i className="fa fa-telegram"></i></a></div>
          </div>
          {/* <Slider>
            {sliderData.map((_, index) => (
              <div
                key={index}
                className={`circle ${index === currentIndex ? "active" : ""}`}
              ></div>
            ))}
          </Slider> */}
        </BannerContainer><br /><br />
        <BannerPerson alt="" src={activeBanner.person}/>
      </HomeBannerRow>
      <div className="container" style={{ marginTop: "60px" }}>
        <div className="homeLogoDivSub animate-row">
          <div className="homeLogoDivSubCol1">
            <div className="homeCol1Ecclipse">
              <img
                className="homeCol1EcclipseCircle"
                src={Search}
                alt="search"
              />
            </div>
            <h1 className="homeCol1Head">Flexible Learning</h1>
            <span style={{ color: "#879b8f", fontSize: "14px" }}>
              Learn anything,anytime with our online platform and flexible
              scheduling options
            </span>
          </div>
          <div className="homeLogoDivSubCol1">
            <div className="homeCol1Ecclipse">
              <img
                className="homeCol1EcclipseCircle"
                src={Back_in_time}
                alt="time"
              />
            </div>
            <h1 className="homeCol1Head">Certified Global Experts</h1>
            <span style={{ color: "#879b8f", fontSize: "14px" }}>
              Certified experts specialising in study and work abroad for your
              international education and career success{" "}
            </span>
          </div>
          <div className="homeLogoDivSubCol1">
            <div className="homeCol1Ecclipse">
              <img
                className="homeCol1EcclipseCircle"
                src={Analysis}
                alt="analysis"
              />
            </div>
            <h1 className="homeCol1Head">Top Universities Access</h1>
            <span style={{ color: "#879b8f", fontSize: "14px" }}>
              Offers exclusive partnerships{" "}
            </span>
          </div>
        </div>
      </div>
      <div className="container" style={{ marginTop: "60px" }}>
        <div className="homeGridRow">
          <div className="homeGridColumn">
            <h1 className="homeGridTitle">{visaCount}+</h1>
            <span className="homeGridSubTitle">Student Visa</span>
          </div>
          <DivLine></DivLine>
          <div className="homeGridColumn">
            <h1 className="homeGridTitle">{universitiesCount}+</h1>
            <span className="homeGridSubTitle">Universities</span>
          </div>
          <DivLine></DivLine>
          <div className="homeGridColumn">
            <h1 className="homeGridTitle">{countriesCount}+</h1>
            <span className="homeGridSubTitle">Countries</span>
          </div>
          <DivLine></DivLine>
          <div className="homeGridColumn">
            <h1 className="homeGridTitle">{coursesCount}+</h1>
            <span className="homeGridSubTitle">Courses</span>
          </div>
        </div>
      </div >
      <RegisterForm />
      <div className="container">
        <Countries />
        <Courses />
        <Universities />
      </div>
      <Testimonials data={reviewData} />
      <div className="container">
        <Events_Blogs />
      </div>
      <div className="container">
        <QA />
      </div>
      <div className="container" style={{ marginTop: "60px" }}>
        <div className="homeAboutUsDiv">
          {/* <div className="homeAboutUsDivSubImg"> */}
          <img
            className="aeronSideAboutImg"
            src={side_about_img}
            alt="AboutUs"
          />
          {/* </div> */}
          <div className="homeAboutUsDivSub">
            <h1 className="homeAboutUsTitle">Who we are ?</h1>
            <h1 className="homeAboutUsHead">
              Start <span className="text-aeron">Your Journey</span> to Study
              Abroad
            </h1>
            <span className="homeAboutUsHeadSub">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book. It has
              survived not only five centuries, but also the leap into
              electronic typesetting, remaining essentially unchanged. It was
              popularised in the 1960s with the release of Letraset sheets
              containing Lorem Ipsum passages, and more recently with desktop
              publishing software like Aldus PageMaker including versions of
              Lorem Ipsum.
              <br />
              <br />
              We believe that education is the core process that aids in one’s
              personal and mental development. With an aim to aid many aspiring
              students who are working towards their dream of international
              education, we ensure to help you reach your destination. Over the
              years, we have accumulated the certitude of thousands of students
              by using our resources to aid their professional careers.
            </span>
          </div>
        </div>
      </div>
      <div
        style={{
          backgroundColor: "#f1fff7",
          marginTop: "60px",
        }}
      >
        <div className="container">
          <br />
          <br />
          <h1 className="homeServiceTitle">Our Service</h1>
          <h2 className="homeServiceSubTitle">We Provide Multiple Services</h2>
          <div className="homeServiceRow">
            <ServiceCard />
          </div><br /><br />
          <Button to="/service">More Services</Button>
        </div>
        <br />
        <br />
      </div><br /><br />
      <div className="container">
        <div className="homeBoxDiv">
          <div className="homeBoxColumn">
            <h1 className="homeBoxTitle">
              Are You Ready To Fly & Persue Your Dreams
            </h1>
            <span className="homeBoxSub">
              We believe that education is the core process that aids in one’s
              personal and mental development.
            </span>
            <Link to='/contact' className="homeBoxButton">
              <span>Get Started</span>
              <span className="material-icons">arrow_forward</span>
            </Link>
          </div>
          <GetImg src={get_img} alt="" />
        </div>
      </div>
      <Footer />
    </Wrapper>
  );
}

export default Home;
const HomeBannerRow = ({ children, bgImage }) => (
  <div
    style={{
      backgroundImage: `url(${bgImage})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      width: '100%',
      height: '100vh',
      position: "relative",
      // Add other styles as needed
    }}
  >
    {children}
  </div>
);
const Wrapper = styled.div`
background-color:#fff;
`;
const BannerPerson = styled.img`
width:40%;
height:90%;
position:absolute;
right:30px;
bottom:0;
@media (max-width: 576px){
display:none;
}
`;
const BannerContainer = styled.div`
padding: 20px 30px;
display: flex;
flex-direction: column;
gap:20px;
width: 60%;
margin-top:60px;
.flex-row {
  display:flex;
  flex-direction:row;
  gap:15px;
  align-items:center;
  .cover {
    display:flex;
    align-items:center;
    justify-content:center;
    background-color:#000;
    border-radius:50%;
    width:40px;
    height:40px;
    i {
      color:#fff;
      font-size:24px;
    }
  }
}
@media (max-width: 768px){
   width: 60%;
     margin-top:auto;
}
@media (max-width: 576px){
  width: auto;
}
`;
const BannerHead = styled.h1`
font-size: 50px;
color: #fff;
text-align: left;
margin: 0;
@media (max-width: 768px){
  font-size: 35px;
}
`;
const BannerPara = styled.p`
font-size: 16px;
text-align: left;
color: #fff;
@media (max-width: 768px){
  font-size: 14px;
}
`;
const Slider = styled.div`
position:absolute;
top: 50%;
right: 100px;
transform: translate(-50%, -50%) rotate(-90deg); 
display:flex;
flex-direction:row;
gap:4px;
transition: transform 0.5s ease-in-out;
@media (max-width: 576px){
  top:auto;
  bottom:20px;
  left:40px;
  transform: none;
}
.circle {
    cursor:pointer;
    border-radius:50%;
    padding:5px;
    background-color:#C3C3C3;
    &:hover {
        background-color:#38b772;   
    }
    transition: background-color 0.3s ease-in-out;
}
.circle.active {
    background-color: #38b772;
  }
`;
const Button = styled(Link)`
  cursor: pointer;
  background-color: #38b772;
  color: #fff;
  border-radius: 10px;
  padding: 10px 20px;
  width: fit-content;
  border: 0px solid;
  text-decoration:none;
  box-shadow: inset 0 0 0 0 #38b772;
  transition: color .5s ease-in-out, box-shadow .5s ease-in-out;
  &:hover {
    background-color: #fff;
    border: 1px solid #38b772;
    color: #38b772;
    box-shadow: inset 200px 0 0 0 #fff;
  }
`;
const DivLine = styled.div`
border: 3px solid white;
height: 15vh;
border-radius: 12px;
@media (max-width:576px){
  width: 70%;
  height: 3px;
}
`;
const SupportBtnStyled = styled.div`
  position: fixed;
  top: 50%;
  right: 0;
  transform: translate(35%, -50%) rotate(-90deg); /* Adjusted for center alignment */
  cursor: pointer;
  background-color: #38b772;
  color: #fff;
  border-radius: 10px;
  padding: 10px 20px;
  width: fit-content;
  border: 0px solid;
  z-index: 10;
  box-shadow: inset 0 0 0 0 #38b772;
  transition: color .5s ease-in-out, box-shadow .5s ease-in-out;
  &:hover {
    background-color: #fff;
    border: 1px solid #38b772;
    color: #38b772;
    box-shadow: inset 200px 0 0 0 #fff;
  }
  @media (max-width: 768px){
    top:60%;
  }
  ${(p) => p.isOpen && `z-index: 1;`}
`;
const GetImg = styled.img`
position:absolute;
bottom:0px;
right:10px;
width:30%;
@media (max-width:768px){
  width:50%;
}
@media (max-width:576px){
  display:none;
}
`;