import React from 'react';
import styled from 'styled-components';

export default function TextArea({ label, value, onChange,isForm, ...rest }) {
    return (
        <Wrapper><Label>{label}</Label>
            <Input isForm={isForm} id="inp" autoComplete="off" placeholder="&nbsp;" rows="4" cols="50" {...rest}></Input></Wrapper>
    )
}
const Wrapper = styled.div`
display:flex;
flex-direction:column;
width:100%;
`;
const Input = styled.textarea`
-webkit-appearance: none;
-moz-appearance: none;
appearance: none;
border-radius:4px;
padding: 10px 20px;
border:0px solid;
width:auto;
background-color:#E1DEDC;
margin-top:10px;
`;
const Label = styled.span`
font-size:14px;
font-weight:400;
`;
