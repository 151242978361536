import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";

export default function SelectBox({
  data,
  placeholder,
  value,
  onSelect,
  label
}) {
  const [isActive, setIsActive] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (isActive && ref.current && !ref.current.contains(e.target)) {
        setIsActive(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isActive]);

  const onChange = (value) => {
    onSelect(value);
    setIsActive(false);
  };

  return (
    <MainWrapper>
      <Label>{label}</Label>
      <Wrapper ref={ref}>
        <div className="dropdown">
          <DropDownBtn
            isOpen={isActive}
            onClick={() => {
              setIsActive(!isActive);
            }}
            selected={value}
          >
            {value ? value.label : placeholder}
            <span className="material-icons">
              {isActive ? "expand_less" : "expand_more"}
            </span>
          </DropDownBtn>
          <ItemsWrapper isOpen={isActive}>
            {data.map((item, i) => {
              return (
                <ItemList
                  key={i}
                  onClick={() => {
                    onChange(item);
                  }}
                  selected={value ? value.label === item.label : false}
                >
                  {item.label}
                </ItemList>
              );
            })}
          </ItemsWrapper>
        </div>
      </Wrapper>
    </MainWrapper>
  );
}
const MainWrapper = styled.div`
display:flex;
flex-direction:column;
width:100%;
`;
const Label = styled.span`
font-size:14px;
font-weight:400;
`;
const Wrapper = styled.div`
width:auto;
.dropdown {
  margin: 0 auto;
  width: 100%;
  position: relative;
}
`;
const DropDownBtn = styled.div`
-webkit-transition: all 0.35s ease-in-out;
-moz-transition: all 0.35s ease-in-out;
-ms-transition: all 0.35s ease-in-out;
-o-transition: all 0.35s ease-in-out;
transition: all 0.35s ease-in-out;
cursor: pointer;
background-color: ${(p) => (p.isOpen ? "#fff" : "#E1DEDC")};
border: 1px solid ${(p) => (p.isOpen ? "#83807F" : "transparent")};
display: flex;
align-items: center;
justify-content: space-between;
padding: 10px 20px;
border-radius: 4px;
color: ${(p) => p.selected ? "#000" : "#83807F"};
font-weight: ${(p) => (p.selected ? 500 : 400)};
`;
const ItemsWrapper = styled.div`
margin-top: 12px;
position: absolute;
z-index: 1;
left: 0;
width: 100%;
height: 200px;
overflow-y:scroll;
&::-webkit-scrollbar {
  width: 10px;
  background-color:#d3d5d2;
}
&::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 10px;
}
&::-webkit-scrollbar-thumb {
  background: #879b8f; 
  border-radius: 10px;
}
&::-webkit-scrollbar-thumb:hover {
  background: #686d69; 
}
border-radius: 4px;
background-color: #fff;
box-shadow: rgba(0, 0, 0, 0.25) 0px 5px 15px;
border: 1px solid #83807F;
display: ${(p) => (p.isOpen ? "block" : "none")};
`;
const ItemList = styled.div`
padding: 11px 16px;
cursor: pointer;
display: flex;
flex-direction: column;
background: ${(p) => p.selected ? "#BDBCBB" : "#fff"};
font-weight: ${(p) => (p.selected ? 600 : 400)};
&:hover {
  background: #BDBCBB;
  font-weight: 600;
}
`;
