import React from "react";
import styled from "styled-components";
import { Navbar, Footer } from "../components";
import { useNavigate } from "react-router-dom";
import { service1,service2,service3,service4,service5,service6 } from "../assets/images";

let data = [
  {
    id: 1,
    img:service1,
    title: "1. Study Abroad Counseling :",
    sub: "Our experienced counselors provide personalized guidance to help students navigate the complexities of studying abroad. From choosing the right destination and program to understanding visa requirements and scholarship opportunities, we are here to assist students in making informed decisions.",
  },
  {
    id: 2,
    img:service2,
    title: "2. University Selection Assistance :",
    sub: "With a vast network of partner institutions around the world, we offer expert advice on selecting the best-fit university based on academic interests, career aspirations, and budget considerations. We help students explore a wide range of options and find the perfect match for their educational needs.",
  },
  {
    id: 3,
    img:service3,
    title: "3. Application Support :",
    sub: "Our team provides comprehensive support throughout the application process, including assistance with filling out application forms, compiling required documents, and preparing for interviews or admission tests. We strive to streamline the application process and maximize students' chances of acceptance to their desired universities.",
  },
  {
    id: 4,
    img:service4,
    title: "4. Visa Guidance :",
    sub: "Navigating the visa application process can be daunting, but our dedicated visa advisors are here to simplify the process and ensure compliance with immigration regulations. We provide detailed guidance on visa requirements, assist with documentation, and offer support in scheduling visa appointments.",
  },
  {
    id:5,
    img:service5,
    title:"5. Pre-Departure Orientation :",
    sub:"Preparing for life in a new country can be exciting yet overwhelming. Our pre-departure orientation sessions equip students with essential information and resources to ease their transition to studying abroad. We cover topics such as cultural adjustment, academic expectations, health and safety abroad, and practical tips for settling into a new environment."
  },
  {
    id:6,
    img:service6,
    title:"6. Post-Arrival Support :",
    sub:"Our commitment to student success extends beyond the initial arrival. We offer ongoing support to students throughout their study abroad experience, addressing any challenges or concerns that may arise. Whether it's academic advising, housing assistance, or cultural integration support, we are dedicated to ensuring a positive and enriching experience abroad."
  }
];
export default function Service() {
  const navigate = useNavigate(); // Initialize navigate
  const handleHomeOpenClick = () => {
    navigate("/");
  };
  // const [modalOpen, setModalOpen] = useState(false);

  // useEffect(() => {
  //   setModalOpen(true); 
  // }, []);
  return (
    <>
      <Navbar />
      {/* <ModalEvent isOpenInitially={modalOpen} /> */}
      <div className="container">
        <RouteSpan>
          <span className="ampHeadRouteSpan" onClick={handleHomeOpenClick}>
            Home
          </span>{" "}
          {">"} About
        </RouteSpan>
      </div>
      <div className="container">
        <div style={{ paddingTop: "150px" }}>
          <h1 className="homeServiceTitle">Our Service</h1>
          <h2 className="homeServiceSubTitle">We Provide Multiple Services</h2>
          <Para>
            At Aeron Abroad Studies, we are dedicated to helping students
            achieve their academic and professional goals through international
            education opportunities. Our comprehensive range of services is
            designed to support students at every step of their journey towards
            studying abroad.
          </Para>
          <FlexColumn>
            {data.map((items, i) => {
              return (
                <FlexRow key={i}>
                  <img className="image" src={items.img} alt="" />
                  <div className="column">
                    <span className="title">{items.title}</span>
                    <span className="para">{items.sub}</span>
                  </div>
                </FlexRow>
              );
            })}
          </FlexColumn>
        </div>
      </div>
      <Footer />
    </>
  );
}
const RouteSpan = styled.span`
  color: rgb(0, 0, 0);
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0%;
  text-align: left;
`;
const Para = styled.p`
  color: #879b8f;
  font-size: 16px;
  font-weight: 400;
  line-height: 40px;
  margin-top: 60px;
  text-align: left;
`;
const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  margin-top: 60px;
`;
const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
  }
  .column {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 70%;
    @media (max-width: 768px){
        width: 100%;
    }
  }
  .title {
    color: #202121;
    font-size: 16px;
    font-weight: 700;
    text-align:left;
  }
  .para {
    color: #879b8f;
    font-size: 16px;
    font-weight: 400;
    text-align:left;
    line-height:40px;
  }
  .image {
    width: 300px;
    height:200px;
    border-radius:12px;
  }
`;
