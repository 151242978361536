import React from 'react';
import styled from 'styled-components';

export default function InputBox({ label, show, type, placeholder, onChange, iconLeft, iconLeftName, iconRight, iconRightName, ...rest }) {
    return (
        <Wrapper><Label>{label}</Label>
            {iconLeft && <IconLeft className={iconLeft}>{iconLeftName}</IconLeft>}
            <Input autoComplete="off" type={type} placeholder={placeholder} onChange={onChange} {...rest} />
            {iconRight && (
                <IconRight className={iconRight} onClick={show}>
                    {iconRightName}
                </IconRight>
            )}</Wrapper>
    )
}
const Wrapper = styled.div`
display:flex;
flex-direction:column;
position: relative;
width:100%;
`;
const IconLeft = styled.span`
color: #494949;
cursor: pointer;
z-index: 1;
`;
const IconRight = styled.span`
color: #494949;
cursor: pointer;
margin: 0.5em;
position: absolute;
right: 10px;
top: 3px;
`;
const Input = styled.input`
-webkit-appearance: none;
-moz-appearance: none;
appearance: none;
border-radius:4px;
padding: 10px 20px;
border:0px solid;
width:auto;
background-color:#E1DEDC;
margin-top:10px;
`;
const Label = styled.span`
font-size:14px;
font-weight:400;
`;
