import React, { useState } from 'react';
import styled from 'styled-components';
import { Footer, Navbar } from '../../components';
import { core1, core2, core3, core4, core5, core6, core7, core8, core9, core10 } from '../../assets/images';

let data = [
    {
        id: 1,
        title: "Empowerment",
        subTitle: "To empower students with the knowledge and resources they need to pursue education opportunities abroad.",
        img: core1,
        para: "Empowerment is at the heart of our mission. We believe that every student should have the opportunity to pursue education beyond borders. We provide comprehensive resources, guidance, and support to empower students to make informed decisions about their academic journey abroad. Whether it's navigating application processes, understanding financial options, or choosing the right program, we are here to empower students every step of the way."
    },
    {
        id: 2,
        title: "Accessibility",
        subTitle: "Making information about studying abroad accessible to everyone, regardless of background or financial status.",
        img: core2,
        para: "Accessibility is a core value that drives our work. We are committed to breaking down barriers to studying abroad by making information easily accessible to all. Whether you're a first-generation student, have financial constraints, or come from a diverse background, our platform is designed to provide equal access to valuable resources. Studying abroad should be within reach for everyone, and we strive to make that a reality."
    },
    {
        id: 3,
        title: "Diversity",
        subTitle: "Celebrating and promoting cultural diversity by encouraging students to explore a variety of study destinations.",
        img: core3,
        para: "Diversity is not just a value for us; it's a celebration of the rich tapestry of cultures around the world. We encourage students to explore study destinations that reflect this diversity, offering programs in a wide range of countries and regions. By embracing diverse cultures and perspectives, students not only expand their academic horizons but also contribute to a more interconnected and understanding world."
    },
    {
        id: 4,
        title: "Quality",
        subTitle: "Providing high-quality, accurate, and up-to-date information to guide students through their study abroad journey.",
        img: core4,
        para: "Quality is non-negotiable when it comes to the information and support we offer. We understand the importance of accurate and up-to-date information in the study abroad process. Our platform is meticulously curated to provide students with the highest quality resources, from detailed program descriptions to expert advice on visa applications. Trust in the quality of information you find here to guide you on your study abroad journey."
    },
    {
        id: 5,
        title: "Support",
        subTitle: "Offering support and guidance at every step, from choosing a program to settling into a new country.",
        img: core5,
        para: "Support is the backbone of our platform. We know that studying abroad can be both exciting and overwhelming. That's why we offer comprehensive support at every stage of your journey. From helping you choose the right program to providing tips on adjusting to a new culture, our team is here to assist you. Consider us your study abroad companions, dedicated to making your experience as smooth and rewarding as possible."
    },
    {
        id: 6,
        title: "Transparency",
        subTitle: "Being transparent about the challenges and opportunities of studying abroad, ensuring students make informed decisions.",
        img: core6,
        para: "Transparency is key to building trust with our users. We believe in providing honest and clear information about the challenges and opportunities of studying abroad. From costs and cultural differences to academic requirements and potential hurdles, we lay it all out for you. Our goal is to ensure that every student has the knowledge they need to make informed decisions about their study abroad experience."
    },
    {
        id: 7,
        title: "Community",
        subTitle: "Fostering a sense of community among students studying abroad, connecting them with peers and alumni around the world.",
        img: core7,
        para: "Community is what makes studying abroad a truly enriching experience. We understand the importance of connecting with peers and alumni who share similar journeys. Our platform serves as a hub for building these connections, whether it's through forums, networking events, or alumni stories. Join our global community of students and alumni to share experiences, gain insights, and forge lifelong friendships across borders."
    },
    {
        id: 8,
        title: "Innovation",
        subTitle: "Embracing innovation in study abroad programs and technologies to enhance the overall experience for students.",
        img: core8,
        para: "Innovation drives us to constantly improve the study abroad experience. We are at the forefront of new technologies and program innovations that enhance learning and cultural immersion. From virtual exchange programs to cutting-edge research opportunities, we strive to provide students with innovative options. Join us in exploring the latest advancements in study abroad and open new doors to your academic journey."
    },
    {
        id: 9,
        title: "Global Citizenship",
        subTitle: "Encouraging students to become global citizens, promoting cross-cultural understanding and responsible travel.",
        img: core9,
        para: "Global citizenship is more than just studying in another country; it's about embracing a mindset of openness and understanding. We encourage students to become global citizens by immersing themselves in different cultures, languages, and perspectives. Through cross-cultural exchanges and responsible travel, students not only gain academic knowledge but also develop empathy and respect for diverse communities. Join us in the journey towards becoming thoughtful and engaged global citizens."
    },
    {
        id: 10,
        title: "Inspiration",
        subTitle: " Inspiring students to dream big, explore the world, and expand their horizons through education.",
        img: core10,
        para: "Inspiration is the spark that ignites a student's desire to explore the world. We believe in the power of education to transform lives and broaden horizons. Our platform is designed to inspire students to dream big and pursue their academic passions beyond borders. Whether it's discovering new cultures, conducting research in diverse environments, or simply embracing the adventure of studying abroad, let us be your source of inspiration on this incredible journey."
    },
];

export default function Core_Values() {
    const [activeId, setActiveId] = useState(null);

    const handleToggle = (id) => {
        setActiveId((prevId) => (prevId === id ? null : id));
    };

    // const [modalOpen, setModalOpen] = useState(false);

    // useEffect(() => {
    //     setModalOpen(true);
    // }, []);

    return (
        <div className="mainDiv">
            <div className="Navbar">
                <Navbar />
            </div><br /><br />
            {/* <ModalEvent isOpenInitially={modalOpen} /> */}
            <Wrapper>
                <div className='container'>
                    <BoxWrapper>
                        {data.map((item, i) => {
                            return (
                                <Box key={i}>
                                    <div className='div1' onClick={() => handleToggle(item.id)} isActive={activeId === item.id}>
                                        <img src={item.img} alt='' />
                                        <div className='content'>
                                            <h1 className='title'>{item.title}</h1>
                                            <h2 className='sub-title'>{item.subTitle}</h2>
                                        </div>
                                        {activeId === item.id ? (<Icon className="material-icons">keyboard_arrow_up</Icon>) : (<Icon className="material-icons">expand_more</Icon>)}
                                    </div><br /><br />
                                    {activeId === item.id && (
                                        <div>
                                            <p className='para'>{item.para}</p>
                                        </div>
                                    )}
                                </Box>
                            )
                        })}
                    </BoxWrapper>
                </div>
            </Wrapper>
            <Footer />
        </div>
    )
}

const Wrapper = styled.div`
margin-top:150px;
`;

const BoxWrapper = styled.div`
display:flex;
flex-direction:column;
gap:20px;
`;

const Box = styled.div`
box-shadow: rgba(0, 0, 0, 0.25) 0px 5px 15px;
padding:20px;
border-radius:10px;
text-align:left;
position:relative;
.div1 {
    display:flex;
    flex-direction:row;
    gap:10px;
    img {
        width:100px;
    }
    @media(max-width:576px){
        flex-direction:column;
    }
}
.content {
    display:flex;
    flex-direction:column;
    gap:10px; 
}
.title {
    font-size:35px;
    font-weight:700;
    color:#494949;
    letter-spacing:2px;
}
.sub-title {
    font-size:18px;
    font-weight:500;
    color:#494949;
    line-height:30px;
}
.para {
    font-size:16px;
    font-weight:400;
    color:#879b8f;
    line-height:30px;
}
`;

const Icon = styled.span`
position:absolute;
top:10px;
right:10px;
`;
