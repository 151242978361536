import React from 'react';
import styled from 'styled-components';
import { Navbar, Footer } from '../../components';
import { useParams } from 'react-router-dom';
import { australia, uk, us, switzerland, sweden, ireland, georgia, france, china, canada } from '../../assets/images';
let data = [
  {
    id: 1,
    image: australia,
    heading: "Australia",
    para: "Studying abroad in Australia is an attractive option for international students due to its globally recognized education system, welcoming multicultural society, and vibrant lifestyle. Australian universities are known for their high academic standards, offering a wide range of programs and cutting-edge research opportunities. Additionally, Australia provides a safe and friendly environment, with stunning natural landscapes and lively cities, enhancing the overall experience for students. The country also offers strong support services for international students, helping them adapt and thrive during their studies.",
    list_item: [{ id: 1, sub: "Quality Education: ", list: "Australia is home to many world-renowned universities. Institutions like the University of Melbourne, Australian National University, and the University of Sydney are globally recognized for their academic excellence." },
    { id: 2, sub: "Diverse Course Options: ", list: "Australian universities offer a wide range of undergraduate and postgraduate programs across various fields, including engineering, medicine, business, and the arts." },
    { id: 3, sub: "Multicultural Environment: ", list: "Australia is known for its multicultural society, which can make the transition smoother for international students. You'll have the chance to meet people from all over the world and experience different cultures." },
    { id: 4, sub: "Research Opportunities: ", list: "Australia is a leader in research and innovation. Many universities have strong research programs and facilities, providing opportunities for students to engage in cutting-edge research projects." },
    { id: 5, sub: "Beautiful Environment: ", list: "With its stunning landscapes, beautiful beaches, and vibrant cities, Australia offers a high quality of life and numerous opportunities for outdoor activities." }
    ],
    list_item2: [{ id: 1, sub: "Scholarships: ", list: "Many Australian universities and the government offer scholarships specifically for international students. These scholarships can significantly reduce tuition costs and are awarded based on academic merit, financial need, or specific criteria set by the scholarship provider." },
    { id: 2, sub: "Financial Aid: ", list: " In addition to scholarships, some institutions offer financial aid packages that can help cover living expenses and other costs associated with studying in Australia." },
    { id: 3, sub: "Work-Study Programs: ", list: "Some universities offer work-study programs that allow students to work on campus in various roles, providing financial support while also gaining valuable work experience." }
    ],
    para2: "Working while studying in Australia is a valuable opportunity for international students, who are typically on a Subclass 500 Visa. This visa allows students to work up to 48 hours per fortnight during the academic term and unlimited hours during holidays. To work legally, students need to obtain a Tax File Number (TFN) from the Australian Taxation Office. Job opportunities range from on-campus positions in libraries and cafes to off-campus roles in retail, hospitality, and internships related to their field of study. Universities often provide career services to assist students in finding part-time work, and job portals like Seek, Indeed, and LinkedIn are also helpful resources. Working while studying not only provides financial support but also enriches students' professional and cultural experiences in Australia.",
    para3: "Post-study opportunities in Australia are plentiful for international graduates, making it an attractive destination for those looking to establish their careers. The Temporary Graduate Visa (Subclass 485) allows students to stay and work in Australia for up to 18 months to four years after completing their studies, depending on their qualification level and location of study. This visa offers graduates the chance to gain valuable work experience, enhance their skills, and improve their employability. Australia's robust job market, particularly in industries like healthcare, engineering, IT, and education, provides numerous career prospects. Graduates can also explore pathways to permanent residency through the Skilled Migration program if they possess skills that are in demand. Additionally, many universities and institutions offer strong alumni networks and career support services to assist graduates in securing employment. Overall, Australia's post-study work options provide a solid foundation for building a successful career and potentially making Australia a long-term home.",
    conclution: "Studying abroad in Australia offers a myriad of benefits, from world-class education and diverse cultural experiences to extensive post-study work opportunities. The Temporary Graduate Visa (Subclass 485) allows graduates to gain significant work experience, enhancing their career prospects. Australia's thriving job market, particularly in key industries like healthcare, engineering, IT, and education, provides ample employment opportunities. Additionally, the potential for pathways to permanent residency makes Australia an attractive option for long-term career and life plans. With strong institutional support, vibrant student communities, and a high quality of life, Australia stands out as an excellent choice for international students aiming for academic success and professional growth. The multicultural environment fosters a sense of belonging and enriches personal development, helping students build a global network of peers and professionals. The country's commitment to innovation and research provides students with cutting-edge knowledge and skills applicable in a global context. Moreover, Australia’s emphasis on practical learning ensures that graduates are well-prepared to meet the demands of the global workforce. Support services, such as career counseling and job placement assistance, further enhance students' transition from education to employment. Overall, Australia's dynamic education system and robust support structures make it a prime destination for those seeking to excel academically and professionally while experiencing a vibrant, inclusive culture.",
  },
  {
    id: 2,
    image: uk,
    heading: "United Kingdom",
    para: "Studying in the UK offers an unparalleled academic experience characterized by world-renowned universities, diverse cultural exposure, and a rich historical backdrop. With institutions like Oxford, Cambridge, and the London School of Economics consistently ranking among the top in the world, students have access to exceptional faculty and cutting-edge research opportunities. The UK's multicultural society ensures a vibrant learning environment, where students from all corners of the globe come together to exchange ideas and perspectives. Additionally, the UK's proximity to Europe facilitates easy travel and exploration. Whether it's immersing oneself in Shakespearean literature, delving into groundbreaking scientific research, or simply experiencing life in a dynamic and cosmopolitan setting, studying in the UK offers a transformative journey of personal and academic growth.",
    list_item: [
      { id: 1, sub: "World-Class Education: ", list: "The UK is home to some of the oldest and most prestigious universities in the world, renowned for their high academic standards and rigorous curriculum." },
      { id: 2, sub: "Diverse Course Options: ", list: "With a wide range of courses and degrees available, students have the flexibility to tailor their education to suit their interests and career goals." },
      { id: 3, sub: "Internationally Recognized Qualifications: ", list: "UK degrees are highly respected and recognized worldwide, providing students with a competitive edge in the global job market." },
      { id: 4, sub: "Cultural Diversity: ", list: "Studying in the UK exposes students to a rich tapestry of cultures, languages, and perspectives, fostering a deeper understanding of the world around them." },
      { id: 5, sub: "Research Opportunities: ", list: "The UK is a hub for cutting-edge research and innovation, offering students the chance to work alongside leading academics and researchers in their field." },
      { id: 6, sub: "Career Prospects: ", list: "Graduates from UK universities often benefit from strong employment prospects, with many institutions offering career support services and opportunities for internships and work placements." },
      { id: 7, sub: "Quality of Life: ", list: "From bustling cities to picturesque countryside, the UK offers a high quality of life with excellent healthcare, transportation, and cultural amenities." },
      { id: 8, sub: "Networking Opportunities: ", list: "Studying in the UK allows students to build a global network of contacts and connections, which can be invaluable for future career opportunities." }
    ],
    list_item2: [{ id: 1, sub: "Diverse Funding Options: ", list: "UK universities offer a variety of scholarships, grants, and bursaries specifically designed for international students, covering a range of academic disciplines and backgrounds." },
    { id: 2, sub: "Merit-Based Awards: ", list: "Many scholarships in the UK are awarded based on academic merit, recognizing outstanding achievements in academics, extracurricular activities, or leadership potential." },
    { id: 3, sub: "Need-Based Support: ", list: "In addition to merit-based scholarships, some institutions offer financial assistance based on demonstrated financial need, ensuring that students from all socioeconomic backgrounds have access to higher education." },
    { id: 4, sub: "Support for Underrepresented Groups: ", list: "Some scholarships in the UK are targeted towards specific groups, such as women, minorities, or students from developing countries, aiming to promote diversity and inclusion within higher education." }
    ],
    para2: "International students in the UK on a Tier 4 (General) Student Visa can work up to 20 hours per week during term time and full-time during holidays. Most jobs are permitted, except for professional sports or entertainment roles. To work, students need a National Insurance Number, which can be obtained online or by phone upon arrival in the UK. Job opportunities include on-campus roles in libraries and cafes, as well as off-campus positions in retail, hospitality, and internships. University career services and job portals like Indeed and LinkedIn are useful resources for finding part-time employment.",
    para3: "Post-study opportunities in the UK are abundant for international graduates. The UK offers the Graduate Route visa, allowing students to stay and work, or look for work, for two years after completing a degree (three years for PhD graduates). This route doesn't require a sponsor, giving graduates the freedom to work in most jobs or be self-employed. Additionally, the UK's vibrant job market and strong industries in finance, technology, healthcare, and creative sectors provide diverse career prospects. Graduates can also consider further studies or research opportunities. For those interested in long-term stay, transitioning to skilled worker visas is a viable option, enabling a pathway to permanent residency.",
    conclution: "Studying abroad in the UK offers a wealth of opportunities, from world-renowned educational institutions and diverse cultural experiences to robust post-study work options. The Graduate Route visa provides a pathway to gain valuable work experience, enhancing career prospects globally. With access to extensive resources, support systems, and vibrant job markets, the UK stands out as a premier destination for international students seeking quality education and professional growth. Students benefit from exposure to cutting-edge research, innovative teaching methods, and the chance to network with professionals and peers from around the world. The multicultural environment fosters personal growth and intercultural understanding, preparing graduates for a globalized workforce. Whether aiming for further studies, research, or a successful career, the UK provides a solid foundation for future success. For those considering a long-term stay, the transition to skilled worker visas opens up avenues for permanent residency, making the UK not just a place to study, but a potential home for the future.",
  },
  {
    id: 3,
    image: us,
    heading: "United States",
    para: "Studying in the United States offers an unparalleled educational experience that attracts students from all corners of the globe. Renowned for its top-tier universities, cutting-edge research facilities, and diverse academic programs, the U.S. remains a beacon of innovation and opportunity. With a vast array of disciplines to choose from, students can tailor their education to their interests and career goals, gaining valuable skills and knowledge that are highly regarded worldwide. Beyond academics, the U.S. offers a rich cultural tapestry, providing students with opportunities to engage with people from diverse backgrounds and perspectives. Additionally, the country's strong emphasis on experiential learning, internships, and networking opportunities prepares students for success in the global job market. Whether pursuing undergraduate, graduate, or professional studies, choosing to study in the United States opens doors to endless possibilities and fosters personal and intellectual growth.",
    list_item: [{ id: 1, sub: "World-Class Education:  ", list: "The U.S. boasts some of the world's top-ranked universities and colleges renowned for their academic excellence and cutting-edge research facilities." },
    { id: 2, sub: "Diverse Academic Programs: ", list: "Students have access to a wide range of academic disciplines and majors, allowing them to tailor their education to their interests and career aspirations." },
    { id: 3, sub: "Innovative Teaching Methods: ", list: "U.S. institutions often employ innovative teaching methods, including hands-on learning, research opportunities, and interdisciplinary approaches, fostering critical thinking and creativity." },
    { id: 4, sub: "Vibrant Cultural Experience: ", list: "Studying in the U.S. provides students with exposure to a diverse and multicultural society, offering opportunities to engage with people from various backgrounds and perspectives." },
    { id: 5, sub: "Networking Opportunities: ", list: "The U.S. offers extensive networking opportunities, including access to industry leaders, internships, and job fairs, helping students build valuable connections for their future careers." },
    { id: 6, sub: "Flexibility and Choice: ", list: "U.S. universities offer flexible academic structures, allowing students to customize their course schedules and explore diverse academic interests." },
    { id: 7, sub: "Career Opportunities: ", list: "Graduates from U.S. institutions are highly sought after by employers globally, thanks to the reputation of American education and the skills acquired during their studies." },
    { id: 8, sub: "Quality of Life: ", list: "The U.S. offers a high standard of living with access to world-class amenities, cultural attractions, and recreational activities, ensuring a well-rounded and fulfilling student experience." },
    ],
    list_item2: [{ id: 1, sub: "Merit-Based Scholarships: ", list: "Many U.S. universities offer merit-based scholarships to academically outstanding students, both domestic and international. These scholarships are typically awarded based on academic achievements, extracurricular activities, leadership potential, and other criteria. Merit scholarships can significantly reduce tuition costs and sometimes cover living expenses as well." },
    { id: 2, sub: "Need-Based Financial Aid: ", list: "U.S. colleges and universities also provide need-based financial aid to students who demonstrate financial need. This assistance can come in the form of grants, loans, and work-study programs. The amount of aid awarded is determined based on the student's and their family's financial situation, as assessed through the Free Application for Federal Student Aid (FAFSA) or the College Scholarship Service (CSS) Profile." },
    { id: 3, sub: "External Scholarships and Fellowships: ", list: "Additionally, international students can explore external scholarship opportunities offered by government agencies, private organizations, foundations, and international institutions. These scholarships may be based on academic merit, field of study, country of origin, or other criteria. Conducting thorough research and applying to relevant scholarship programs can significantly offset the cost of studying in the U.S." }
    ],
    para2: "Working while studying in the United States is a common practice among international students, offering opportunities to gain valuable work experience and supplement their finances. International students holding an F-1 visa are typically eligible to work on campus for up to 20 hours per week during the academic year and full-time during scheduled breaks and holidays. On-campus jobs may include positions in libraries, cafeterias, administrative offices, or student organizations, providing convenient work opportunities and valuable networking experiences. Additionally, under certain circumstances, international students may be eligible for off-campus employment through Curricular Practical Training (CPT) or Optional Practical Training (OPT). CPT allows students to gain practical work experience directly related to their field of study, while OPT permits temporary employment in the student's field of study for up to 12 months after completing their academic program. However, it's crucial for international students to adhere to specific regulations regarding employment to maintain their F-1 visa status, as working without proper authorization or exceeding allowable work hours can result in serious consequences, including visa violations and deportation. Therefore, it's essential for students to familiarize themselves with the rules and regulations governing employment for international students in the U.S. and consult with their designated school official (DSO) for guidance.",
    para3: "Post-study opportunities in the United States offer international students a pathway to leverage their education and skills for career advancement. One significant option is the Optional Practical Training (OPT) program, which allows F-1 visa holders to work in their field of study for up to 12 months after completing their academic program. STEM (Science, Technology, Engineering, and Mathematics) degree holders may qualify for a 24-month extension, providing an additional opportunity to gain practical experience and establish themselves in the U.S. job market. Additionally, international graduates may pursue employment through the H-1B visa program, which enables U.S. employers to sponsor foreign workers in specialty occupations. Securing employment through the H-1B visa pathway can lead to long-term career prospects and potentially permanent residency in the United States. Furthermore, international students may explore opportunities for further education or research through advanced degree programs or academic exchanges, enhancing their skills and expertise in their chosen field. With its diverse job market, thriving economy, and opportunities for professional development, the United States offers international students a wealth of options to pursue their career aspirations and contribute to the global workforce.",
    conclution: "Studying abroad in the United States offers unparalleled opportunities for academic excellence, cultural enrichment, and professional development. With world-renowned universities and diverse academic programs, students can tailor their education to their personal and career goals. The innovative teaching methods and state-of-the-art research facilities available in U.S. institutions ensure that students receive a top-tier education that is both comprehensive and forward-thinking. Beyond academics, the multicultural environment in the U.S. fosters personal growth and global networking, allowing students to engage with peers from diverse backgrounds and perspectives. This exposure not only enriches their cultural understanding but also prepares them for the global job market. Extensive support services, including academic advising, career counseling, and student organizations, contribute to a holistic educational experience. Additionally, vibrant campus life offers numerous opportunities for extracurricular involvement and personal development. Post-study opportunities, such as Optional Practical Training (OPT) and H-1B visas, provide pathways for international students to gain valuable work experience and establish successful careers in the U.S. These programs enable students to apply their knowledge in real-world settings, enhancing their employability and professional growth. Overall, the U.S. stands out as a premier destination for international students, offering a dynamic and enriching environment that helps them achieve their academic ambitions and build a bright future.",
  },
  {
    id: 4,
    image: switzerland,
    heading: "Switzerland",
    para: "Studying abroad in Switzerland offers an exceptional educational experience characterized by academic excellence, cultural diversity, and breathtaking natural landscapes. Renowned for its top-tier universities and cutting-edge research facilities, Switzerland provides a broad range of programs in fields such as business, engineering, hospitality, and the sciences. The country's multilingual environment—where German, French, Italian, and English are commonly spoken—enhances students' linguistic skills and cultural understanding. Switzerland's strong emphasis on innovation and practical learning ensures that students gain both theoretical knowledge and hands-on experience. Additionally, the country’s strategic location in the heart of Europe offers unparalleled opportunities for travel and international networking. With its high quality of life, safe environment, and vibrant student communities, Switzerland stands out as an ideal destination for those seeking a comprehensive and enriching study abroad experience.",
    list_item: [{ id: 1, sub: "World-Class Education: ", list: "Switzerland is home to prestigious universities and research institutions known for their academic excellence and cutting-edge facilities, offering high-quality education across various disciplines." },
    { id: 2, sub: "Multilingual Environment: ", list: "With German, French, Italian, and English commonly spoken, students have the opportunity to improve their language skills and gain a deeper cultural understanding, enhancing their global communication abilities." },
    { id: 3, sub: "Innovation and Practical Learning: ", list: "Swiss education places a strong emphasis on innovation and practical learning, ensuring that students acquire both theoretical knowledge and hands-on experience, making them highly competitive in the job market." },
    { id: 4, sub: "High Quality of Life: ", list: "Switzerland is renowned for its high standard of living, offering a safe and clean environment, excellent healthcare, and reliable public transportation, contributing to a comfortable and secure student experience." },
    { id: 5, sub: "Cultural Diversity: ", list: "The country’s central location in Europe attracts students from all over the world, creating a diverse and inclusive environment that enriches the cultural experience and provides extensive networking opportunities." },
    { id: 6, sub: "Scenic Beauty and Travel Opportunities: ", list: "Surrounded by stunning landscapes, including the Alps and numerous lakes, Switzerland offers ample opportunities for outdoor activities and travel, allowing students to explore and enjoy its natural beauty and neighboring European countries." }
    ],
    list_item2: [{ id: 1, sub: "University Scholarships: ", list: "Many Swiss universities offer scholarships specifically for international students. These scholarships are often merit-based and can cover tuition fees, living expenses, or both. Examples include the ETH Zurich Excellence Scholarship and the University of Geneva Excellence Master Fellowships, which provide substantial financial support to outstanding students." },
    { id: 2, sub: "Swiss Government Excellence Scholarships: ", list: "The Swiss government offers the Swiss Government Excellence Scholarships for Foreign Scholars and Artists. These scholarships are available to postgraduate researchers in any discipline and to artists pursuing a master's degree. They provide recipients with a monthly stipend, tuition fee waivers, health insurance, and other benefits, promoting academic exchange and research collaboration." },
    { id: 3, sub: "External Scholarships and Financial Aid: ", list: "International students can also explore scholarships and grants from external organizations, foundations, and home country governments. Organizations like the Fulbright Commission, Erasmus+ program, and various international foundations offer financial aid to students pursuing education in Switzerland. These scholarships can significantly offset the cost of studying abroad and make higher education in Switzerland more affordable." }
    ],
    para2: "Working while studying in Switzerland is a viable option for international students, providing valuable work experience and financial support. Students from EU/EFTA countries can work up to 15 hours per week during term time and full-time during holidays without needing a special permit. Non-EU/EFTA students, however, are allowed to work up to 15 hours per week during term time only after residing in Switzerland for six months, and they must obtain a work permit. This work permit requires the employer to apply on the student's behalf. Common part-time job opportunities include roles in hospitality, retail, and university positions such as research assistants or administrative support. Balancing work and studies in Switzerland not only helps with financial sustainability but also enhances students' professional skills and integration into Swiss society, providing a well-rounded and enriching educational experience.",
    para3: "Post-study opportunities in Switzerland are abundant for international graduates, making it an attractive destination for those looking to advance their careers. Graduates from Swiss universities can apply for a six-month residence permit to search for a job related to their field of study, providing a valuable window to secure employment. Switzerland’s robust economy and strong industries, particularly in finance, pharmaceuticals, engineering, and technology, offer numerous job prospects. Many international companies and research institutions are headquartered in Switzerland, increasing the likelihood of finding relevant and rewarding positions. Additionally, Switzerland’s emphasis on innovation and high-quality education means that graduates are well-prepared to meet the demands of the job market. Networking opportunities, career services, and university-industry collaborations further support students in their job search. Overall, Switzerland's dynamic job market, coupled with its high standard of living and professional growth opportunities, makes it an excellent place for graduates to launch their careers and potentially settle long-term.",
    conclution: "Studying abroad in Switzerland offers a unique combination of world-class education, cultural diversity, and unparalleled quality of life. Renowned for its prestigious universities and cutting-edge research facilities, Switzerland provides students with a comprehensive academic experience across various disciplines. The multilingual environment, where German, French, Italian, and English are commonly spoken, enhances students' language skills and cultural understanding. Financial assistance and scholarships make education in Switzerland more accessible, with many universities offering merit-based scholarships and the Swiss Government Excellence Scholarships providing substantial support for postgraduate researchers. Additionally, the ability to work part-time while studying allows students to gain valuable work experience and supplement their finances. Post-graduation, Switzerland's robust economy and thriving industries, such as finance, pharmaceuticals, engineering, and technology, offer numerous job opportunities. International graduates can apply for a six-month residence permit to search for employment related to their field of study, providing a critical window to secure a position. The country’s high standard of living, safe environment, and strong professional networks support a smooth transition from education to career. Overall, Switzerland stands out as a premier destination for international students seeking academic excellence and personal growth, making it an ideal place to study and build a successful future.",
  },
  {
    id: 5,
    image: sweden,
    heading: "Sweden",
    para: "Studying in Sweden offers an enriching educational experience characterized by academic excellence, innovation, and inclusivity. Renowned for its world-class universities and research-intensive programs, Sweden provides a dynamic learning environment with a strong emphasis on critical thinking and creativity. The country's commitment to sustainability, equality, and social welfare permeates its education system, fostering a supportive and inclusive atmosphere for students from diverse backgrounds. With a wide range of programs taught in English, including undergraduate, graduate, and doctoral degrees, Sweden attracts students from around the globe seeking high-quality education in fields such as engineering, technology, social sciences, and the humanities. Beyond academics, Sweden's vibrant cultural scene, breathtaking natural landscapes, and progressive society offer students a unique opportunity to immerse themselves in Swedish culture while pursuing their academic and personal aspirations.",
    list_item: [{ id: 1, sub: "High-Quality Education: ", list: "Sweden is home to top-ranked universities and colleges renowned for their academic excellence, innovative teaching methods, and research-intensive programs." },
    { id: 2, sub: "English-Taught Programs: ", list: "A significant advantage for international students is the availability of a wide range of programs taught in English, providing access to quality education without language barriers." },
    { id: 3, sub: "Innovation and Sustainability: ", list: "Sweden is a global leader in innovation and sustainability, with many programs focusing on cutting-edge research and practices in fields such as environmental science, renewable energy, and technology." },
    { id: 4, sub: "Inclusive and Equal Society: ", list: "Sweden is known for its progressive values, promoting inclusivity, diversity, and equality. International students can expect a welcoming and supportive environment conducive to personal and academic growth." },
    { id: 5, sub: "Work-Life Balance: ", list: "The Swedish approach to education emphasizes a healthy work-life balance, with flexible study schedules, ample vacation time, and a supportive social welfare system ensuring students can pursue their studies while enjoying life outside of academia." },
    { id: 6, sub: "International Experience: ", list: "Studying in Sweden provides an excellent opportunity for cultural immersion and global networking, as students from diverse backgrounds come together in a multicultural environment, fostering cross-cultural understanding and lifelong friendships." }
    ],
    list_item2: [{ id: 1, sub: "Swedish Institute Scholarships: ", list: "The Swedish Institute offers various scholarship programs for international students, including the Swedish Institute Scholarships for Global Professionals (SISGP) and the Swedish-Turkish Scholarship Program. These scholarships cover tuition fees, living expenses, and insurance costs for master's degree studies in Sweden." },
    { id: 2, sub: "University Scholarships: ", list: "Many universities in Sweden offer scholarships and tuition waivers to both domestic and international students based on academic merit, financial need, or other criteria. Examples include the Uppsala University Global Scholarships and the Lund University Global Scholarship Program, which provide financial support to outstanding students pursuing bachelor's or master's degrees." },
    { id: 3, sub: "Erasmus+ and Exchange Programs: ", list: "Students from EU/EEA countries can benefit from the Erasmus+ program, which provides grants for study abroad and internships within Europe, including Sweden. Additionally, universities often have exchange agreements with institutions worldwide, allowing students to study abroad while paying reduced or no tuition fees. These programs provide valuable financial support and enhance students' international experience." }
    ],
    para2: "Working while studying in Sweden is a common practice among international students, offering opportunities to gain valuable work experience and supplement living expenses. Students from EU/EEA countries, as well as Switzerland, are generally allowed to work in Sweden without needing a work permit. They can work part-time for up to 20 hours per week during the academic year and full-time during scheduled breaks. Non-EU/EEA students are permitted to work alongside their studies for up to 20 hours per week during term time and full-time during holidays, provided they have a valid residence permit for studies and the right to work. Common part-time job opportunities include positions in hospitality, retail, tutoring, or administrative support. The Swedish labor market values international experience, and working part-time can enhance students' language skills, cultural integration, and professional network, contributing to a well-rounded educational experience.",
    para3: "Post-study opportunities in Sweden offer international graduates avenues to utilize their education and skills for career advancement. One significant option is the extended residence permit for job seekers, allowing graduates to stay in Sweden for up to six months to search for employment related to their field of study. During this time, graduates have access to the Swedish labor market and can apply for various job openings. Sweden's strong economy, particularly in sectors like technology, engineering, healthcare, and renewable energy, provides numerous job prospects for international graduates. Additionally, graduates from non-EU/EEA countries may have the opportunity to apply for a work permit extension if they secure a job offer relevant to their studies. This extension allows them to remain in Sweden and work in their chosen field. Moreover, Sweden's favorable work-life balance, high quality of life, and progressive work environment make it an attractive destination for international professionals seeking long-term career opportunities. Overall, post-study opportunities in Sweden offer international graduates a pathway to build successful careers and contribute to the country's thriving economy and society.",
    conclution: "Studying in Sweden offers a transformative educational experience characterized by academic excellence, innovation, and inclusivity. Renowned for its top-ranked universities, cutting-edge research facilities, and diverse range of programs taught in English, Sweden provides students with a dynamic learning environment that fosters critical thinking, creativity, and cultural understanding. The country's commitment to sustainability, equality, and social welfare permeates its education system, creating a supportive and inclusive atmosphere for students from around the world. Financial assistance and scholarships, coupled with opportunities for part-time work during studies, make higher education in Sweden accessible and affordable for international students. Post-graduation, Sweden's strong economy, progressive work culture, and favorable job market offer abundant opportunities for career advancement and professional growth. With its vibrant cultural scene, breathtaking natural landscapes, and emphasis on work-life balance, Sweden stands out as an ideal destination for those seeking not only academic excellence but also personal and professional fulfillment.",
  },
  {
    id: 6,
    image: ireland,
    heading: "Ireland",
    para: "Studying in Ireland offers a rich and rewarding educational experience marked by academic excellence, cultural immersion, and vibrant student life. Renowned for its world-class universities and colleges, Ireland provides a diverse range of programs across various disciplines, including science, technology, humanities, and business. With a strong emphasis on research and innovation, students have the opportunity to engage in cutting-edge research projects and collaborate with leading experts in their fields. Beyond academics, Ireland's warm and welcoming atmosphere, friendly locals, and rich cultural heritage make it an attractive destination for international students. Additionally, Ireland's strong ties to industry and growing economy offer graduates excellent prospects for employment and career advancement both in Ireland and internationally. Overall, studying in Ireland offers students a unique blend of academic rigor, cultural diversity, and unforgettable experiences.",
    list_item: [{ id: 1, sub: "High-Quality Education: ", list: "Ireland boasts world-class universities and colleges known for their academic excellence and research contributions across various fields." },
    { id: 2, sub: "Global Recognition: ", list: "Irish qualifications are highly regarded worldwide, offering graduates excellent prospects for employment and further education internationally." },
    { id: 3, sub: "Innovation Hub: ", list: "Ireland is a leading hub for innovation and technology, attracting top companies and providing students with opportunities for internships, research projects, and networking." },
    { id: 4, sub: "Rich Cultural Experience: ", list: "With its vibrant cultural scene, historical landmarks, and welcoming locals, Ireland offers international students a unique and enriching cultural experience." },
    { id: 5, sub: "Safe and Friendly Environment: ", list: "Ireland is known for its safe and friendly environment, making it an ideal destination for students seeking a supportive and inclusive atmosphere to pursue their studies." }
    ],
    list_item2: [{ id: 1, sub: "Government Support: ", list: "The Irish government provides financial aid through schemes such as the Student Universal Support Ireland (SUSI). These grants are awarded based on criteria like income, academic performance, and residency status, helping cover tuition fees, living expenses, and study-related costs for eligible students." },
    { id: 2, sub: "Institutional Scholarships: ", list: "Universities and colleges in Ireland offer a range of scholarships and bursaries to domestic and international students. These awards may be merit-based, need-based, or specific to particular fields of study or extracurricular achievements, offering financial assistance to deserving candidates." },
    { id: 3, sub: "External Funding Sources: ", list: "Students can explore external scholarship opportunities from organizations, foundations, and governments worldwide. These scholarships may be available for specific nationalities, academic disciplines, or research areas, supplementing students' financial resources and supporting their educational pursuits in Ireland." },
    { id: 4, sub: "Part-Time Employment: ", list: "International students in Ireland can work part-time during term time and full-time during vacations, subject to certain restrictions. Part-time work can help students cover living expenses and gain valuable work experience, contributing to their personal and professional development while studying in Ireland. Additionally, post-graduation, the Third Level Graduate Scheme allows non-EU/EEA graduates to remain in Ireland for up to two years to seek employment, providing further financial stability and career opportunities." }
    ],
    para2: "Working while studying in Ireland is a feasible option for international students, offering opportunities to gain valuable work experience and supplement living expenses. Students from EU/EEA countries, as well as Switzerland, have the right to work part-time during term time and full-time during holidays without needing a work permit. Non-EU/EEA students are allowed to work part-time up to 20 hours per week during term time and full-time during scheduled breaks, provided they have a valid study visa. Common part-time job opportunities include positions in retail, hospitality, tutoring, or administrative support. Additionally, Ireland's strong economy and vibrant job market offer students diverse employment opportunities. Working part-time not only provides financial support but also allows students to enhance their language skills, gain practical experience, and expand their professional network, contributing to a well-rounded educational experience.",
    para3: "Post-study opportunities in Ireland offer international graduates various avenues for career advancement and professional development. With a thriving economy and a strong emphasis on innovation and technology, Ireland provides a conducive environment for graduates to explore rewarding career paths. The country is home to numerous multinational companies across sectors such as technology, pharmaceuticals, finance, and healthcare, offering abundant job opportunities. Additionally, Ireland's favorable immigration policies, such as the Third Level Graduate Scheme, allow non-EU/EEA graduates to remain in the country after completing their studies to seek employment. This scheme provides graduates with a two-year stay-back option, during which they can work and gain valuable industry experience. Furthermore, Ireland's strategic location within the European Union and its English-speaking environment make it an attractive destination for international professionals seeking global career prospects. Overall, post-study opportunities in Ireland provide graduates with the chance to leverage their education and skills, embark on fulfilling career journeys, and contribute to the country's dynamic workforce and economy.",
    conclution: "Studying abroad in Ireland offers students a transformative experience marked by academic excellence, cultural immersion, and abundant opportunities for personal and professional growth. With world-class universities and colleges, Ireland provides a diverse range of programs across various disciplines, fostering critical thinking, creativity, and innovation. The country's rich cultural heritage, warm hospitality, and vibrant student life create a welcoming and inclusive environment for international students. Financial assistance and scholarships, coupled with opportunities for part-time work and post-graduation employment, make higher education in Ireland accessible and rewarding. Post-study opportunities further enhance the appeal of studying in Ireland, with graduates benefiting from a thriving job market, favorable immigration policies, and access to global career prospects. Overall, studying abroad in Ireland equips students with the knowledge, skills, and experiences needed to succeed in an increasingly interconnected world, making it an ideal destination for those seeking a truly enriching educational journey.",
  },
  {
    id: 7,
    image: georgia,
    heading: "Georgia",
    para: "Studying in Georgia offers a compelling blend of academic excellence, cultural richness, and affordability, making it an increasingly popular destination for international students. Situated at the crossroads of Europe and Asia, Georgia boasts a rich history, diverse landscapes, and a unique blend of Eastern and Western influences. Its capital, Tbilisi, is a vibrant cultural hub known for its ancient architecture, modern amenities, and welcoming atmosphere. Georgia's higher education system is characterized by a growing number of English-taught programs, particularly in fields such as medicine, engineering, business, and the humanities. With a focus on quality education and internationalization, Georgian universities and colleges offer a wide range of academic opportunities, research facilities, and extracurricular activities. Moreover, the cost of living and tuition fees in Georgia are relatively affordable compared to many Western countries, making it an attractive option for students seeking a high-quality education without breaking the bank. Overall, studying in Georgia promises an enriching educational experience amidst a unique cultural backdrop, providing students with the skills, knowledge, and experiences to succeed in a globalized world.",
    list_item: [{ id: 1, sub: "Cultural Diversity: ", list: "Georgia's rich history and strategic location at the crossroads of Europe and Asia offer students a unique opportunity to immerse themselves in a diverse and vibrant cultural environment, fostering cross-cultural understanding and appreciation." },
    { id: 2, sub: "Affordability: ", list: "Compared to many Western countries, the cost of living and tuition fees in Georgia are relatively low, making it an affordable option for international students seeking a high-quality education without accumulating significant debt." },
    { id: 3, sub: "Quality Education: ", list: "Georgian universities and colleges offer a range of academic programs taught in English, with a focus on quality education, modern facilities, and experienced faculty members, ensuring students receive a comprehensive and competitive education." },
    { id: 4, sub: "Strategic Location: ", list: "Situated between Europe and Asia, Georgia serves as a gateway to both regions, providing students with access to diverse opportunities for travel, internships, and networking, enhancing their global perspective and professional prospects." },
    { id: 5, sub: "Warm Hospitality: ", list: "Georgians are known for their warm hospitality and friendly demeanor, creating a welcoming and inclusive atmosphere for international students. Students can expect to feel at home and build meaningful connections with locals during their time in Georgia." }
    ],
    list_item2: [{ id: 1, sub: "Government Grants: ", list: "The Georgian government offers various grants and financial aid programs to support students' educational expenses. These grants may cover tuition fees, living expenses, or both, based on financial need, academic performance, and other criteria." },
    { id: 2, sub: "Institutional Scholarships: ", list: "Many universities and colleges in Georgia offer scholarships and bursaries to both domestic and international students. These scholarships may be merit-based, need-based, or awarded for specific achievements or areas of study, providing financial assistance to deserving candidates." },
    { id: 3, sub: "Private Foundations and Organizations: ", list: "Students can explore scholarships and financial aid opportunities provided by private foundations, organizations, and corporations in Georgia. These external funding sources may offer scholarships based on academic merit, field of study, or other criteria, supplementing students' financial resources and supporting their educational pursuits." }
    ],
    para2: "Working while studying in Georgia is a feasible option for international students, providing opportunities to gain practical experience and supplement their finances. Students from EU/EEA countries and Switzerland can work in Georgia without needing a work permit, while non-EU/EEA students are allowed to work part-time for up to 20 hours per week during the academic year and full-time during holidays with a valid residence permit for studies. Common part-time job opportunities for students include roles in hospitality, retail, tutoring, or administrative support. Working part-time not only helps students cover living expenses but also allows them to gain valuable work experience, develop professional skills, and build a network in their field of interest. Additionally, working while studying can contribute to students' overall academic and personal growth, providing a well-rounded educational experience in Georgia.",
    para3: "Post-study opportunities in Georgia offer graduates various pathways to embark on successful careers and further their professional development. With its growing economy and strategic location at the crossroads of Europe and Asia, Georgia presents abundant opportunities for employment and entrepreneurship. Graduates can explore career options in sectors such as finance, technology, hospitality, tourism, and international development, leveraging their skills and knowledge gained during their studies. Additionally, Georgia's favorable business environment and government initiatives to attract foreign investment create favorable conditions for graduates to launch startups or pursue entrepreneurial ventures. Furthermore, Georgia's increasing integration into global markets and its partnerships with international organizations offer graduates opportunities for international employment and collaboration. Overall, post-study opportunities in Georgia provide graduates with the chance to contribute to the country's economic growth and innovation while advancing their own professional aspirations.",
    conclution: "Studying abroad in Georgia offers a unique and enriching educational experience marked by cultural diversity, affordability, and academic excellence. With its vibrant culture, welcoming atmosphere, and strategic location, Georgia provides students with a dynamic environment to pursue their academic goals and personal growth. The country's affordable cost of living and tuition fees make it an accessible destination for international students seeking a high-quality education without financial strain. Furthermore, Georgia's modern universities and colleges offer a range of English-taught programs, ensuring students receive a competitive and comprehensive education. The warm hospitality of the Georgian people and the country's rich history create a welcoming environment for students from around the world, fostering friendships and cultural understanding. Overall, studying abroad in Georgia promises an unforgettable experience that equips students with the skills, knowledge, and global perspective to succeed in an increasingly interconnected world.",
  },
  {
    id: 8,
    image: france,
    heading: "France",
    para: "Studying in France offers an unparalleled educational experience characterized by academic excellence, cultural richness, and innovation. As one of the world's leading destinations for higher education, France boasts a long history of intellectual achievement and cultural heritage. From the historic streets of Paris to the picturesque landscapes of the French countryside, France offers students a diverse and vibrant environment to pursue their academic aspirations. With a wide range of universities, grandes écoles, and specialized institutions offering programs in various fields, including engineering, humanities, sciences, arts, and business, students have access to world-class education and cutting-edge research facilities. Furthermore, France's commitment to internationalization is evident through its English-taught programs, multicultural campuses, and numerous opportunities for exchange and collaboration with students and scholars from around the globe. Whether exploring its rich cultural heritage, engaging in academic pursuits, or immersing oneself in the French way of life, studying in France promises an enriching and transformative experience for students seeking to broaden their horizons and pursue their dreams.",
    list_item: [{ id: 1, sub: "Academic Excellence: ", list: " France is home to some of the world's most prestigious universities and grandes écoles, known for their rigorous academic standards, innovative research, and globally recognized degrees." },
    { id: 2, sub: "Cultural Enrichment: ", list: "With its rich history, diverse arts scene, and culinary delights, France offers students a unique opportunity to immerse themselves in one of the world's most vibrant and culturally rich societies." },
    { id: 3, sub: "Internationalization: ", list: "France is a leading destination for international students, with a growing number of English-taught programs, multicultural campuses, and opportunities for exchange and collaboration with students from around the world." },
    { id: 4, sub: "Affordability: ", list: "Despite its reputation for excellence, France remains relatively affordable for international students, with reasonable tuition fees, access to scholarships and financial aid, and a high quality of life." },
    { id: 5, sub: "Career Opportunities: ", list: "Studying in France opens doors to a wide range of career opportunities, both in France and internationally. With its strong economy, thriving industries, and global connections, France offers graduates a competitive edge in the job market." }
    ],
    list_item2: [{ id: 1, sub: "Government Scholarships: ", list: "The French government offers a range of scholarships to both domestic and international students. These scholarships may cover tuition fees, living expenses, or both, and are awarded based on academic merit, financial need, or specific criteria related to the student's field of study." },
    { id: 2, sub: "Institutional Scholarships: ", list: "Many universities and colleges in France offer scholarships and financial aid packages to support students throughout their studies. These scholarships may be merit-based, need-based, or awarded for specific achievements or areas of study, providing valuable assistance to deserving candidates." },
    { id: 3, sub: "External Funding Sources: ", list: "Students can also explore external scholarship opportunities provided by private organizations, foundations, and international institutions. These scholarships may be available for specific nationalities, academic disciplines, or research areas, supplementing students' financial resources and supporting their educational pursuits in France." }
    ],
    para2: "Working while studying in France is a feasible option for international students, providing opportunities to gain valuable work experience and supplement living expenses. Students from the European Union, European Economic Area, and Switzerland can work in France without needing a work permit. For non-EU/EEA students, a student visa allows part-time work up to 964 hours per year, which corresponds to roughly 20 hours per week. Common part-time job opportunities for students include roles in hospitality, retail, tutoring, or administrative support. Working part-time not only helps students cover living expenses but also allows them to gain practical experience, develop language skills, and build professional networks. Additionally, France's strong economy and diverse job market offer students opportunities to explore internships and gain industry-specific experience relevant to their field of study, enriching their educational journey in France.",
    para3: "Post-study opportunities in France offer graduates a multitude of avenues for career advancement and professional development. With its strong economy, diverse industries, and thriving job market, France provides ample opportunities for employment and entrepreneurship. Graduates can explore career options in sectors such as technology, engineering, finance, healthcare, arts, and culture, leveraging their skills and knowledge acquired during their studies. Furthermore, France's strategic location within the European Union and its global connections create favorable conditions for international employment and collaboration. Additionally, France's favorable immigration policies, such as the Talent Passport and the Post-Graduation Work Permit, allow graduates to stay and work in France after completing their studies. Moreover, the country's extensive network of alumni associations, career services, and industry partnerships offer valuable support and resources for graduates as they navigate their career paths in France and beyond. Overall, post-study opportunities in France provide graduates with the chance to thrive professionally and contribute to the country's economic growth and innovation.",
    conclution: "Studying abroad in France offers students a transformative educational experience characterized by academic excellence, cultural immersion, and personal growth. With its prestigious universities, innovative research facilities, and vibrant cultural scene, France provides a dynamic environment for students to pursue their academic aspirations and broaden their horizons. The country's rich history, diverse landscapes, and culinary delights create a unique backdrop for learning and exploration. Moreover, France's commitment to internationalization, with a growing number of English-taught programs and multicultural campuses, fosters global citizenship and cross-cultural understanding among students. Financial assistance and scholarships, coupled with opportunities for part-time work and post-graduation employment, make studying in France accessible and rewarding for students from around the world. Overall, studying abroad in France promises an enriching and unforgettable experience that equips students with the skills, knowledge, and cultural fluency to thrive in an increasingly interconnected world.",
  },
  {
    id: 9,
    image: china,
    heading: "China",
    para: "Studying in China offers a compelling blend of academic excellence, cultural richness, and innovation, making it an increasingly popular destination for international students. With a history spanning thousands of years, China boasts a rich cultural heritage and a diverse array of landscapes, from bustling metropolises to serene countryside. As one of the world's fastest-growing economies, China has emerged as a global leader in various fields, including technology, engineering, business, and medicine. Its universities and colleges offer a wide range of programs taught in English, providing students with access to world-class education and cutting-edge research opportunities. Furthermore, China's strategic location in Asia and its growing influence on the global stage present students with unique opportunities for cross-cultural exchange, networking, and career development. Whether exploring its ancient traditions, delving into its modern innovations, or immersing oneself in its vibrant society, studying in China promises an enriching and transformative educational experience.",
    list_item: [{ id: 1, sub: "Academic Excellence: ", list: "China is home to some of the world's top universities and research institutions, offering a wide range of high-quality programs across various disciplines. Students can benefit from cutting-edge research facilities, experienced faculty members, and innovative teaching methods." },
    { id: 2, sub: "Cultural Enrichment: ", list: "With a history spanning thousands of years, China offers students a unique opportunity to immerse themselves in one of the world's oldest and richest cultures. From ancient traditions to modern innovations, students can explore China's diverse cultural heritage and gain a deeper understanding of its society." },
    { id: 3, sub: "Affordability: ", list: "Compared to many Western countries, the cost of living and tuition fees in China are relatively low, making it an affordable option for international students. Scholarships and financial aid opportunities are also available to support students with their educational expenses." },
    { id: 4, sub: "Language Learning: ", list: "Studying in China provides students with the opportunity to learn Mandarin Chinese, one of the world's most widely spoken languages. Proficiency in Chinese can open up doors to career opportunities and enhance students' global competitiveness in an increasingly interconnected world." },
    { id: 5, sub: "Global Opportunities: ", list: "As one of the world's fastest-growing economies, China offers students access to a dynamic job market and numerous internship and career opportunities. Studying in China also provides students with valuable cross-cultural skills and international experience, making them more attractive to employers worldwide." }
    ],
    list_item2: [{ id: 1, sub: "Chinese Government Scholarships: ", list: "The Chinese government offers various scholarships to international students to study in China. These scholarships, such as the Chinese Government Scholarship (CGS) and the Silk Road Scholarship, cover tuition fees, accommodation, and living expenses, and are awarded based on academic merit, financial need, and other criteria." },
    { id: 2, sub: "University Scholarships: ", list: "Many universities in China offer scholarships and financial aid packages to international students. These scholarships may be merit-based, need-based, or awarded for specific achievements or areas of study, providing valuable assistance to deserving candidates pursuing undergraduate, master's, or doctoral degrees." },
    { id: 3, sub: "External Funding Sources: ", list: "Students can also explore external scholarship opportunities provided by private organizations, foundations, and governments worldwide. These scholarships may be available for specific nationalities, academic disciplines, or research areas, supplementing students' financial resources and supporting their educational pursuits in China." }
    ],
    para2: "Working while studying in China is a feasible option for international students, providing opportunities to gain practical experience and supplement living expenses. Students on a student visa (X visa) are permitted to work part-time during their studies, usually up to 20 hours per week during the academic term and full-time during holidays. Common part-time job opportunities for students include roles in teaching English, tutoring, hospitality, or administrative support. Additionally, some universities offer on-campus employment opportunities for international students, such as research assistant positions or language teaching assistantships. Working part-time not only helps students cover living expenses but also allows them to gain valuable work experience, develop language skills, and build professional networks. However, it's essential for students to ensure they comply with visa regulations and obtain the necessary permits before engaging in any employment activities.",
    para3: "Post-study opportunities in China offer graduates a wealth of possibilities for career advancement and professional development. As one of the world's largest and fastest-growing economies, China presents a dynamic job market with diverse employment opportunities across various sectors. Graduates can explore career options in fields such as technology, finance, engineering, healthcare, education, and international trade. China's strategic position as a global hub for innovation and entrepreneurship further enhances post-study prospects, with numerous opportunities for graduates to launch startups, collaborate on research projects, or pursue leadership roles in multinational companies. Additionally, China's extensive network of alumni associations, career services, and industry partnerships provides valuable support and resources for graduates as they navigate their career paths. Moreover, proficiency in Mandarin Chinese and cross-cultural skills acquired during their studies in China can enhance graduates' competitiveness in the global job market, making them highly sought after by employers worldwide. Overall, post-study opportunities in China offer graduates the chance to thrive professionally and contribute to the country's ongoing economic growth and development.",
    conclution: "Studying abroad in China offers students an unparalleled opportunity for personal, academic, and cultural growth. With its rich history, vibrant culture, and rapidly expanding economy, China provides a dynamic and transformative educational experience. Students not only benefit from world-class academic programs and cutting-edge research facilities but also immerse themselves in one of the world's oldest and most diverse cultures. The affordability of living costs and tuition fees, combined with numerous scholarship opportunities, makes China an accessible destination for students from around the globe. Moreover, studying in China equips students with invaluable cross-cultural skills, language proficiency in Mandarin Chinese, and global perspectives, enhancing their competitiveness in the international job market. Overall, studying abroad in China promises an enriching and unforgettable journey that fosters intellectual curiosity, cultural understanding, and lifelong friendships.",
  },
  {
    id: 10,
    image: canada,
    heading: "Canada",
    para: "Studying in Canada offers a compelling blend of academic excellence, cultural diversity, and natural beauty, making it an increasingly popular destination for international students. Renowned for its world-class universities and colleges, Canada provides a wide range of programs across various disciplines, from science and engineering to arts and humanities. With a strong emphasis on research and innovation, Canadian institutions offer students access to cutting-edge facilities, renowned faculty members, and opportunities for hands-on learning and collaboration. Moreover, Canada's multicultural society welcomes students from all backgrounds, fostering an inclusive and vibrant community on campuses across the country. Beyond academics, Canada's breathtaking landscapes, vibrant cities, and high quality of life make it an attractive destination for students seeking both educational and personal enrichment. Whether exploring its diverse cultural scene, engaging in outdoor activities, or pursuing academic excellence, studying in Canada promises an unforgettable experience that prepares students for success in a globalized world.",
    list_item: [{ id: 1, sub: "High-Quality Education: ", list: "Canada is renowned for its world-class education system, with top-ranking universities and colleges offering a wide range of programs across various disciplines. Canadian institutions emphasize academic excellence, innovation, and research, providing students with a comprehensive and competitive education." },
    { id: 2, sub: "Cultural Diversity: ", list: "Canada's multicultural society welcomes students from all over the world, creating a rich and diverse learning environment. Students have the opportunity to interact with people from different backgrounds, cultures, and perspectives, fostering cross-cultural understanding and global citizenship." },
    { id: 3, sub: "Safe and Welcoming Environment: ", list: "Canada is consistently ranked as one of the safest countries in the world, offering students a secure and supportive environment to live and study. Canadians are known for their friendliness and hospitality, making international students feel welcome and at home. " },
    { id: 4, sub: "Work Opportunities: ", list: "Canada offers various work opportunities for international students, allowing them to gain valuable work experience while studying. Students can work part-time during the academic year and full-time during scheduled breaks, helping them cover living expenses and gain practical skills." },
    { id: 5, sub: "Post-Graduation Pathways: ", list: "Canada offers excellent post-graduation pathways for international students. Graduates may be eligible for post-graduation work permits, allowing them to gain valuable work experience in Canada after completing their studies. Furthermore, Canada's immigration policies are designed to attract skilled workers, offering pathways to permanent residency for eligible graduates." }
    ],
    list_item2: [{ id: 1, sub: "Government Funding: ", list: "The Canadian government offers various forms of financial aid, including grants, loans, and bursaries, to eligible students. Programs such as the Canada Student Loans Program (CSLP) and Canada Student Grants Program provide financial assistance based on factors such as financial need, academic performance, and province of residence." },
    { id: 2, sub: "Institutional Scholarships: ", list: "Many universities and colleges in Canada offer scholarships, awards, and bursaries to both domestic and international students. These scholarships may be merit-based, need-based, or awarded for specific achievements or areas of study. Each institution sets its own criteria and application process for scholarships, so students should research and apply for opportunities offered by their chosen institution."},
    { id: 3, sub: "External Scholarships and Grants: ", list: "Students can also explore external scholarship opportunities provided by private organizations, foundations, businesses, and government agencies. These scholarships may be available based on factors such as academic merit, field of study, extracurricular activities, or demographic characteristics. Students are encouraged to research and apply for external scholarships early, as deadlines and eligibility criteria vary." }
    ],
    para2: "Working while studying in Canada is a viable option for international students, providing opportunities to gain valuable work experience, earn income, and offset living expenses. Canada allows international students with a valid study permit to work part-time during the academic year and full-time during scheduled breaks, such as summer and winter holidays. Common part-time job opportunities for students include positions in retail, hospitality, tutoring, or administrative support. Additionally, many Canadian universities and colleges offer on-campus employment opportunities, such as research assistantships or positions within campus facilities. Working part-time not only helps students cover living expenses but also allows them to gain practical skills, expand their professional network, and enhance their overall educational experience. Moreover, Canada's strong economy and diverse job market offer students numerous opportunities to explore internships, co-op placements, and post-graduation employment opportunities relevant to their field of study.",
    para3: "Post-study opportunities in Canada offer graduates a range of pathways for career advancement and professional development. As one of the world's leading economies, Canada boasts a diverse job market with opportunities across various industries, including technology, finance, healthcare, engineering, and education. Graduates can explore career options in both urban centers and rural communities, leveraging their skills and knowledge acquired during their studies. Canada's favorable immigration policies, such as the Post-Graduation Work Permit (PGWP), allow eligible graduates to gain valuable work experience in Canada after completing their studies. The PGWP enables graduates to work full-time in Canada for up to three years, providing them with an opportunity to gain practical experience, build professional networks, and enhance their career prospects. Furthermore, graduates may also be eligible to apply for permanent residency through various immigration pathways, such as the Express Entry system, provincial nominee programs, or employer-sponsored immigration streams. Overall, post-study opportunities in Canada offer graduates the chance to thrive professionally and contribute to Canada's ongoing economic growth and development.",
    conclution: "Studying abroad in Canada offers students an enriching and transformative educational experience marked by academic excellence, cultural diversity, and ample opportunities for personal and professional growth. With its world-class universities, vibrant multicultural society, and high quality of life, Canada provides a welcoming and inclusive environment for students from around the world. The country's commitment to innovation and research, coupled with its strong economy and diverse job market, creates favorable conditions for students to pursue their academic and career aspirations. Moreover, Canada's stunning natural landscapes, vibrant cities, and diverse cultural offerings make it an ideal destination for students seeking both educational and personal enrichment. Whether exploring its bustling urban centers, immersing oneself in its rich cultural heritage, or embarking on outdoor adventures, studying in Canada promises an unforgettable experience that equips students with the skills, knowledge, and global perspectives to thrive in an interconnected world.",
  },
];

export default function Country_Details() {
  // const [modalOpen, setModalOpen] = useState(false);

  // useEffect(() => {
  //   setModalOpen(true);
  // }, []);
  const { id } = useParams();
  const country = data.find((country) => country.id === parseInt(id));
  return (
    <div className="mainDiv">
      <div className="Navbar">
        <Navbar />
      </div>
      {/* <ModalEvent isOpenInitially={modalOpen} /> */}
      <br />
      <br />
      <div className="container">
        <Wrapper>
          <MainTitle>Study In {country.heading}</MainTitle>
          <FlexRow>
            <div>
              <p className='para'>{country.para}</p>
            </div>
            <img className='img' src={country.image} alt='' />
          </FlexRow>
          <Title>Key Benefits of Studying in {country.heading}</Title>
          <ListWrapper>
            {country.list_item.map((list, i) => {
              return (
                <div className='flex-row' key={i}><i style={{ color: "#38b772" }} className="fa fa-check-circle-o text" /><p className='text'><b>{list.sub}</b>{list.list}</p></div>
              )
            })}
          </ListWrapper>
          <Title>Financial Assistance and Scholarships</Title>
          <ListWrapper>
            {country.list_item2.map((list, i) => {
              return (
                <div className='flex-row' key={i}><i style={{ color: "#38b772" }} className="fa fa-check-circle-o text" /><p className='text'><b>{list.sub}</b>{list.list}</p></div>
              )
            })}
          </ListWrapper>
          <Title>Working While Studying</Title>
          <SubTitle>{country.para2}</SubTitle>
          <Title>Post-Study Opportunities</Title>
          <SubTitle>{country.para3}</SubTitle>
          <Title>Conclusion</Title>
          <SubTitle>{country.conclution}</SubTitle>
        </Wrapper>
      </div>
      <Footer /></div>
  )
}
const Wrapper = styled.div`
  margin-top: 120px;
`;
const MainTitle = styled.h1`
font-size: 35px;
font-weight: 700;
color: #38b772;
text-align:center;
`;
const FlexRow = styled.div`;
padding-top:60px;
display:flex;
flex-direction:row;
gap:20px;
@media (max-width: 768px){
  flex-direction:column;
}
.para {
  text-align:left;
  color: #879b8f;
  line-height:30px;

}
.img {
  width: 40%;
  border-radius: 12px;
  @media (max-width: 1024px) {
    width:45%;
  }
  @media (max-width: 768px) {
    width: 100%;
  }
}
`;
const SubWrapper = styled.div`
padding:60px 0;
`;
const Title = styled.h1`
color:#494949;
font-weight:600;
text-align:left;
font-size: 35px;
margin-top:50px;
`;
const SubTitle = styled.p`
color: #879b8f;
text-align:left;
margin-top:30px;
line-height:30px;
`;
const ListWrapper = styled.div`
display:flex;
flex-direction:column;
gap:20px;
margin-top:30px;
&:first-child {
  margin-top:50px;
}
.flex-row{
  display:flex;
  flex-direction:row;
  gap:20px;
  align-items:baseline;
}
.text {
  color: #879b8f;
  text-align:left;
  // line-height:30px;
}
`;