import React from 'react';
import styled from 'styled-components';
import { service6 } from '../../assets/images';

let blogData = [
    { id: 1, image: service6, date: "12 Jan 2024", title: "Rahul Gandhi and his masters from Harvard University" },
    { id: 2, image: service6, date: "12 Jan 2024", title: "Rahul Gandhi and his masters from Harvard University" },
    { id: 3, image: service6, date: "12 Jan 2024", title: "Rahul Gandhi and his masters from Harvard University" },
    { id: 4, image: service6, date: "12 Jan 2024", title: "Rahul Gandhi and his masters from Harvard University" },
    { id: 5, image: service6, date: "12 Jan 2024", title: "Rahul Gandhi and his masters from Harvard University" },
]
let eventData = [
    { id: 1, date: "22 April 2024", title: "Study in France Without IELTS - Admission Day" },
    { id: 2, date: "22 April 2024", title: "Rahul Gandhi and his masters from Harvard University" },
    { id: 3, date: "22 April 2024", title: "Study in France Without IELTS - Admission Day" },
    { id: 4, date: "22 April 2024", title: "Rahul Gandhi and his masters from Harvard University" },
    { id: 5, date: "22 April 2024", title: "Rahul Gandhi and his masters from Harvard University" },
    { id: 6, date: "22 April 2024", title: "Study in France Without IELTS - Admission Day" },
]

export default function Events_Blogs() {
    return (
        <Wrapper>

                    <Title>Events <span className='text-aeron'>&</span> Blogs</Title>
                    <SubPara>Stay updated with the latest news and events happening in our consultancy, providing valuable insights and exciting opportunities for students pursuing their global education dreams. Join us to be part of a vibrant community focused on international education excellence.</SubPara>
                    <FlexRow>
                        <FlexColumn>
                            <h1 className='column-title'>Blogs</h1>
                            <h2 className='sub-title'>Latest Blogs</h2>
                            {blogData.map((item, i) => {
                                return (
                                    <BlogWrapper key={i}>
                                        <img src={item.image} className='img' alt='' />
                                        <div className='content'>
                                            <div className='date'>
                                                <i className="fa fa-clock-o" style={{ fontSize: "16px", color: "#000" }}></i>
                                                <span>{item.date}</span>
                                            </div><br />
                                            <a href='' className='text'>{item.title}</a>
                                        </div>
                                    </BlogWrapper>
                                )
                            })}
                        </FlexColumn>
                        <FlexColumn>
                            <h1 className='column-title'>Events</h1>
                            <h2 className='sub-title'>Latest Events</h2>
                            {eventData.map((item, i) => {
                                return (
                                    <EventWrapper key={i}>
                                        <div className='date'>
                                            <span>{item.date}</span>
                                        </div><br />
                                        <div className='content'>
                                            <h1>Events</h1>
                                            <a href='' className='text'>{item.title}</a>
                                        </div>
                                    </EventWrapper>
                                )
                            })}
                        </FlexColumn>
                    </FlexRow>

        </Wrapper>
    )
}
const Wrapper = styled.div`
margin-top:60px;
display:flex;
flex-direction:column;
gap:20px;
`;
const Title = styled.h1`
font-size:35px;
font-weight:700;
color:#494949;
text-align:center;
.text-aeron {
    color:#38b772;
    text-align:center;
}
`;
const SubPara = styled.p`
color:#879b8f; 
text-align:left;
line-height: 30px;
`;
const FlexRow = styled.div`
display:flex;
flex-direction:row;
justify-content:center;
// align-items:center;
gap:20px;
@media (max-width: 768px){
    gap:10px;
}
@media (max-width:576px){
    flex-direction:column;
    alignitems:center;
}
`;
const FlexColumn = styled.div`
display:flex;
flex-direction:column;
// justify-content:center;
gap:30px;
width:50%;
padding:40px 0;
@media (max-width:576px){
    width:100%;
}
@media (max-width: 768px){
    align-items:center;
}
.column-title {
    font-size:20px;
    font-weight:500;
    color:#38b772;
    text-align:left; 
    margin:0; 
}
.sub-title {
    font-size:35px;
    font-weight:500;
    color:#494949;
    text-align:left;  
    margin:0;  
}
`;
const BlogWrapper = styled.div`
width:350px;
height:350px;
box-shadow: rgba(0, 0, 0, 0.25) 0px 5px 15px;
border-radius:12px;
.img {
width:350px;
height:200px;
@media (max-width: 576px){
    width:100%;
}
}
.content {
    padding:20px;
    text-align: left;
    .date {
        display:flex;
        flex-direction:row;
        align-items:center;
        gap:10px;
    }
    .text {
        font-size:16px;
        font-weight:500;
        color:#879b8f;
        text-decoration:none;
        &:hover {
            color:#38b772;
        }
    }
}
@media (max-width: 576px){
    width:90%;
}
`;
const EventWrapper = styled.div`
width:400px;
box-shadow: rgba(0, 0, 0, 0.25) 0px 5px 15px;
border-radius:12px;
padding:20px;
text-align: left;
.date {
padding:10px;
background-color:#656766;
color:#fff;
border-radius:4px;
width:fit-content;
span {
    font-size:25px;
    font-weight:400;
}
}
.content {
    margin-top:10px;
    h1 {
        font-size:20px;
        color:#494949;
    }
    .text {
        font-size:16px;
        font-weight:500;
        color:#879b8f;
        text-decoration:none;
        &:hover {
            color:#38b772;
        }
    }
}
@media (max-width: 768px){
    width:300px;
}
@media (max-width: 576px){
    width:90%;
}
`;