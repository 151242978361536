import "./App.css";
import { Route, BrowserRouter, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Contact from "./pages/Contact";
import About from "./pages/About/About";
import Privacy from "./pages/Privacy";
import Terms from "./pages/Terms";
import Service from "./pages/Service";
import Course from "./pages/Course/Course";
import Team from "./pages/About/Team";
import Core_Values from "./pages/About/Core_Values";
import Review from "./pages/About/Review";
import Gallery from "./pages/About/Gallery";
import Course_Detail from "./pages/Course/Course_Detail";
import Country from "./pages/Country/Country";
import Country_Details from "./pages/Country/Country_Details";
import Career from "./pages/About/Career";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/about/about" element={<About />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/service" element={<Service />} />
          <Route path="/course/course" element={<Course />} />
          <Route path="/about/team" element={<Team />} />
          <Route path="/about/core-values" element={<Core_Values />} />
          <Route path="/about/testimonial" element={<Review />} />
          <Route path="/about/gallery" element={<Gallery />} />
          <Route path="/about/career" element={<Career />} />
          <Route path="/course/:id" element={<Course_Detail />} />
          <Route path="/country/country" element={<Country />} />
          <Route path="/country/:id" element={<Country_Details />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
