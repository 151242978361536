import React from "react";
import { Navbar, Footer } from "../components";
import styled from "styled-components";

function Terms() {
  return (
    <div className="mainDiv">
      <div className="Navbar">
        <Navbar />
      </div><br /><br /><br />
      <div className="container">
        <Wrapper>
          <Title>Aeron Educational Consultancy - Terms and Conditions</Title>
          <Sub>
            Welcome to Aeron Educational Consultancy! We are delighted to offer our services to assist you in your journey to study abroad. Please read the following terms and conditions carefully before using our website or services:
          </Sub><br /><br />
          <Title>1. Acceptance of Terms</Title>
          <Sub>By accessing our website or using our services, you agree to be bound by these terms and conditions. If you do not agree with any part of these terms, please refrain from using our services.</Sub>
          <br /><br />
          <Title>2. Services Offered</Title>
          <Sub>Aeron Educational Consultancy provides guidance and assistance to individuals seeking to study abroad. Our services include, but are not limited to:</Sub>
          <ul>
            <li>
              Consultations on study programs
            </li>
            <li>Application assistance</li>
            <li>Visa guidance</li>
            <li>Pre-departure orientation</li>
            <li>Other related services</li>
          </ul><br /><br />
          <Title>3. Accuracy of Information</Title>
          <Sub>While we strive to provide accurate and up-to-date information, we cannot guarantee the completeness or accuracy of all content on our website. We encourage users to verify information independently, especially regarding academic programs, universities, visa requirements, and other details.</Sub>
          <br /><br /><Title>4. User Responsibilities</Title>
          <Sub>Users of our services are responsible for providing accurate and truthful information to Aeron Educational Consultancy. Any false or misleading information provided may result in the termination of services.</Sub>
          <br /><br /><Title>5. Fees and Payments</Title>
          <Sub>Fees for our services will be communicated to you before the commencement of any service. Payments are to be made as per the agreed terms. Aeron Educational Consultancy reserves the right to modify fees with prior notice.</Sub>
          <br /><br /><Title>6. Refund Policy</Title>
          <Sub>Our refund policy is as follows:</Sub>
          <ul>
            <li>Refunds are provided in accordance with the terms agreed upon during the initial engagement.</li>
            <li>Any costs incurred by Aeron Educational Consultancy on behalf of the client are non-refundable.</li>
            <li>Refund requests must be made in writing and will be processed within a reasonable time frame.</li>
          </ul>
          <br/><br/><Title>7. Limitation of Liability</Title>
          <Sub>Aeron Educational Consultancy shall not be liable for any direct, indirect, incidental, special, or consequential damages resulting from the use or inability to use our services.</Sub>
          <br/><br/><Title>8. Privacy Policy</Title>
          <Sub>Please refer to our Privacy Policy for information on how we collect, use, and protect your personal information.</Sub>
          <br/><br/><Title>9. Intellectual Property</Title>
          <Sub>All content, including but not limited to logos, text, images, and videos, on the Aeron Educational Consultancy website is the property of Aeron Educational Consultancy. Users are not permitted to use, reproduce, or distribute any content without prior written consent.</Sub>
          <br/><br/><Title>10. Governing Law</Title>
          <Sub>These terms and conditions are governed by the laws of India. Any disputes arising from the use of our services shall be subject to the exclusive jurisdiction of the courts of India.</Sub>
          <br /><br /><Title>11. Changes to Terms</Title>
          <Sub>Aeron Educational Consultancy reserves the right to update or modify these terms and conditions at any time. Users are encouraged to review these terms periodically for changes.</Sub>
          <Sub>If you have any questions or concerns regarding these terms and conditions, please contact us at </Sub>
          <Sub style={{ marginTop: 0 }}>Aeron Educational Consultancy<br />
            Aeron Education Services<br />
            Third Floor, Aysiha Commercial Building,<br />
            Perinthalmanna<br />
            Email: sample@gmail.com<br />
            Phone: +91 8301829642</Sub>
            <Sub>Thank you for choosing Aeron Educational Consultancy for your study abroad journey!</Sub>
        </Wrapper>
      </div>
      <Footer />
    </div>
  );
}
const Wrapper = styled.div`
 display:flex;
 flex-direction:column;
 padding-top:120px;
 text-align:left;
 li {
  color:#879b8f;
line-height:30px;
 }
`;
const Title = styled.h1`
font-size:35px;
font-weight:700;
color:#494949;
`;
const Sub = styled.p`
color:#879b8f;
line-height:30px;
margin-top:40px;
`;
export default Terms;
