import React from 'react';
import styled from 'styled-components';
import { Navbar, Footer } from '../../components';
import { useParams } from 'react-router-dom';
import { course1, course2, course3, course4, course5, course6, course9, course_accounting, course_agriculture, course_architec, course_arts, course_bio, course_chemistry, course_communication, course_culture, course_design, course_economics, course_geography, course_health_medicine, course_history, course_info, course_information, course_it, course_law, course_maths, course_media, course_nurse, course_physics, course_social, course_teacher, course_welfair } from '../../assets/images';

let data = [
  {
    id: 1,
    heading: "Business and Management",
    para: "Business and management are pivotal aspects of our abroad studies company. With a strategic approach to business development and meticulous management practices, we ensure seamless operations while delivering exceptional educational experiences to our clients. From navigating complex regulatory frameworks to fostering strong partnerships with educational institutions worldwide, our focus on effective business strategies and efficient management processes enables us to thrive in the competitive landscape of the abroad studies industry.",
    image: course1,
    why_list: ["Thinking innovatively, producing, and navigating different cultures.", "Involvement in international trade and engagement in global economics.", "Developing different viewpoints, widening horizons, and performing a variety of tasks in business and management.", "Internship possibilities that bring out the best in you as a world leader."],
    course_list: ["Undergraduate", "Postgraduate", "Level 4 Apprenticeship", "Level 3 Apprenticeship", "BA", "MA", "Degree with Foundation Year", "Masters", "Masters Degree", "Bachelors Degree", "Double degree including honours", "MScPOSTGRADUATE", "BA (Hons)UNDERGRADUATE", "BSc (Hons)UNDERGRADUATE", "BA (Hons)DEGREE APPRENTICESHIP", "BA (Hons) Top UpUNDERGRADUATE", "MBAPOSTGRADUATE", "Ontario College Diploma", "Ontario College Certificate", "Honours Bachelor Degree", "Honours Baccalaureate Degree", "Advanced Diploma", "PG Diploma", "Post-Baccalaureate Diploma", "Diploma", "Baccalaureate Degree", "Post Degree Diploma", "Ontario College Diploma, Co-op", "Ontario College Advanced Diploma, Co-op", "Ontario Graduate Certificate", "Honours Degree"]
  },
  {
    id: 2,
    heading: "Computer & IT",
    para: "In the context of our abroad studies focus, computer and information technology (IT) are indispensable for seamless operations and enriching educational experiences. We prioritize technology integration to streamline tasks, manage student databases efficiently, and provide access to cutting-edge resources. Leveraging technology transcends geographical barriers, offering immersive educational experiences, and facilitating effective communication with partner institutions worldwide.",
    image: course_it,
    why_list: ["Exposure to diverse technological landscapes fosters adaptability and innovation.", "Access to top-notch institutions and resources accelerates learning and collaboration.", "Enhances language skills and cultural competencies essential for global tech careers."],
    course_list: ["Ontario College Advanced Diploma", "Degree", "Degree with Foundation Year", "Masters", "MSc", "BSc", "BA", "UG", "PG", "Bachelors Degree", "Honours Bachelors Degree", "Masters Degree", "GRADUATE DIPLOMA", "Graduate Certificate", "Diploma", "MA", "Honours Degree", "Honours year", "Double degree", "UniLink Diploma", "BSc (Hons) UNDERGRADUATE", "Post-Graduate Certificate", "Graduate Certificate", "Advanced Diploma", "Honours Bachelor Degree", "Honours Baccalaureate Degree", "POST-DIPLOMA CERTIFICATE", "Post-secondary program", "CERTIFICATION", "Post-Degree Diploma", "Post-Degree", "Post-Baccalaureate Diploma", "Ontario College Diploma", "Ontario College graduate certificate", "Ontario College Diploma, Co-op"]
  },
  {
    id: 3,
    heading: "Business and Finance",
    para: "In the context of our abroad studies company, business and finance are instrumental in shaping educational opportunities for students worldwide. By strategically managing finances, we offer competitive tuition fees and financial aid options, making international education accessible. Our expertise enables partnerships with leading institutions, innovative programs, and seamless navigation of regulatory frameworks, ensuring transformative educational experiences.",
    image: course3,
    why_list: ["Studying abroad provides invaluable insights into diverse business practices, fostering a deeper understanding of international markets and strategies.", "Immersion in leading financial hubs offers firsthand experience and networking opportunities crucial for building a successful career in finance.", "Engaging with different business cultures enhances adaptability and cross-cultural communication skills, valuable assets in today's globalized business environment.", "International exposure in business and finance opens doors to diverse career paths and increases competitiveness in the global job market."],
    course_list: ["Undergraduate", "bach hons", "postgraduate"]
  },
  {
    id: 4,
    heading: "Nurse",
    para: "In the realm of abroad studies, nursing emerges as a vital field with immense opportunities for growth and development. Our company recognizes the significance of nursing education in addressing global healthcare needs and fostering cross-cultural understanding. Through our programs, aspiring nurses gain valuable international experience, learning from diverse healthcare systems and practices around the world.",
    image: course_nurse,
    why_list: ["Studying nursing abroad offers exposure to diverse healthcare systems, enhancing clinical skills.", "Immersion in different cultures fosters empathy and cultural sensitivity essential for patient-centered care.", "Building global networks provides opportunities for collaboration and career advancement.", "Living abroad nurtures personal growth, fostering independence and resilience in diverse healthcare environments."],
    course_list: ["NURSING DEGREE", "Bachelor of Science in Nursing"]
  },
  {
    id: 5,
    heading: "Health",
    para: "In the realm of abroad studies, the pursuit of health-related fields stands as a cornerstone of our company's mission. Through our programs, students gain invaluable insights into diverse healthcare systems, practices, and cultural approaches to wellness. Immersion in different healthcare environments abroad enhances clinical skills and fosters adaptability, empathy, and cross-cultural communication—qualities vital for delivering effective and culturally sensitive care.",
    image: course5,
    why_list: ["International exposure broadens nurses' understanding of diverse healthcare systems and practices.", "Cultural immersion enhances empathy and communication skills crucial for patient-centered care.", "Networking opportunities foster collaboration and career advancement in the global healthcare community.", "Personal growth through adaptation to diverse environments nurtures resilience essential for nursing professionals."],
    course_list: ["BA"]
  },
  {
    id: 6,
    heading: "Engineering",
    para: "Engineering stands as a cornerstone in our abroad studies company. Through our programs, students gain exposure to diverse technologies and methodologies. Immersion in different engineering environments abroad fosters adaptability and cross-cultural collaboration—traits essential for success in today's interconnected world.",
    image: course6,
    why_list: ["International exposure broadens understanding of diverse engineering practices and technologies.", "Cultural immersion enhances adaptability and fosters cross-cultural collaboration skills.", "Networking opportunities provide access to global industry insights and career prospects.", "Personal growth through navigating diverse environments cultivates resilience essential for engineering professionals."],
    course_list: ["Postgraduate", "Undergraduate", "BA", "MA", "PG Diploma", "PG Diploma /MA", "Degree", "Integrated Master's", "Degree with Foundation Yea", "Foundation Degree", "Masters", "Honours Bachelors Degree", "Bachelors Degree", "Masters Degree", "Graduate Diploma", "Associate Degree", "Honours Degree", "Graduate Certificate", "Expert master degree", "Professional entry master degree", "Degree including honours", "UniLink Diploma", "Advanced Diploma", "Double degree including honours", "Degree including honours and work placement", "UniLink Diplomas and Foundation Years", "Degrees and double degrees degree including honours", "BSc (Hons)DEGREE APPRENTICESHIP", "MEngUNDERGRADUATE", "Ontario College Diploma", "Ontario College Advanced Diploma", "Ontario College Graduate Certificate", "Baccalaureate Degree", "Diploma/Advanced Diploma (3) N/A", "Traditional apprenticeship program", "Post Baccalaureate Diploma", "Ontario College Diploma, Co-op"]
  },
  {
    id: 7,
    heading: "Hospitality & Tourism",
    para: "In the realm of abroad studies, Hospitality and Tourism represent vibrant fields our company embraces. Through experiential learning abroad, students gain insights into diverse cultures and management strategies. Immersion fosters adaptability, cultural competence, and language skills essential for success.",
    image: course2,
    why_list: ["International exposure provides insights into diverse hospitality and tourism practices worldwide.", "Cultural immersion enhances adaptability and fosters cross-cultural communication skills essential in the industry.", "Networking opportunities offer access to global industry insights and career prospects.", "Personal growth through navigating diverse environments cultivates resilience crucial for success in hospitality and tourism professions."],
    course_list: ["BA","MA", "Degree", "Degree with Foundation Year", "UG", "PG", "Graduate certificate", "Masters Degree", "Bachelors Degree", "Diploma", "Associate Degree", "Advanced Diploma", "MScPOSTGRADUATE", "MAPOSTGRADUATE", "BA (Hons)UNDERGRADUATE", "BA (Hons) Top UpUNDERGRADUATE", "Ontario College Diploma", "Honours Degree", "GRADUATION", "Post-secondary program", "Post - Degree Diploma","Post - Baccalaureate Diploma","Ontario College Certificate", "Ontario College Diploma, Co-op", "Ontario College Graduate Certificate", "Ontario College Advanced Diploma, Co-op", "Ontario Graduate Certificate"]
  },
  {
    id: 8,
    heading: "Accounting & Finance",
    para: "Accounting and Finance are integral components of our abroad studies curriculum, reflecting the global significance of these disciplines. Through immersive experiences abroad, students gain exposure to diverse financial markets, enhancing their analytical skills and global perspective. Additionally, our emphasis on experiential learning ensures students apply theoretical knowledge in real-world settings, preparing them for rewarding careers.",
    image: course_accounting,
    why_list: ["Overseas exposure enriches comprehension of varied accounting and finance methodologies.", "Cultural immersion hones adaptability and fosters cross-cultural communication vital for navigating international financial landscapes.", "Networking in global hubs offers access to diverse financial markets and lucrative career avenues.", "Personal development through navigating multifaceted financial environments nurtures resilience pivotal for thriving in accounting and finance fields."],
    course_list: ["Postgraduate","Undergraduate","BA","MA","Integrated Master's","Degree","Degree + Foundation year","Masters","BSc","MSc","TOP-UP","Diploma","Advanced Diploma","Bachelor degree","Master degree","Graduate Certificate","MAPOSTGRADUATE","BA (Hons)UNDERGRADUATE","MScPOSTGRADUATE","Post-Graduate Certificate","Graduate Certificate","Honours Bachelor Degree","Advanced Diploma","N/A","Post-Degree Diploma","Post-Baccalaureate Diploma","Post-Baccalaureate Diploma","Ontario College Diploma","Honours Degree","Ontario Graduate Certificate","Honours Bachelors Degree","Graduate Diploma"]
  },
  {
    id: 9,
    heading: "Pharmacy",
    para: "Studying pharmacy abroad offers a unique and enriching experience for students aspiring to enter the pharmaceutical field. It combines high-quality education, access to cutting-edge research, and exposure to diverse healthcare systems and practices. International pharmacy programs often provide advanced facilities, experienced faculty, and a multicultural environment that fosters personal and professional growth. Additionally, studying abroad can enhance language skills, cultural competency, and global networking opportunities, making graduates more versatile and competitive in the global job market. By embarking on this educational journey, students not only gain a comprehensive understanding of pharmaceutical sciences but also develop a broader perspective on global health challenges and innovations.",
    image: course9,
    why_list: ["Access to internationally renowned institutions with top-tier education and advanced research facilities.", "Exposure to diverse healthcare systems, clinical practices, and cultural perspectives, fostering greater adaptability and cultural competency.", "Enhanced career prospects through global networking opportunities, increased marketability, and the ability to navigate complex global healthcare environments effectively."],
    course_list: ["Postgraduate","Undergraduate","Degree","Degree with Foundation Year","Masters","Honours Bachelors Degree","Masters Degree","Bachelor degree","Bachelor degree (honours)","Graduate Certificate","Associate Degree","Diploma","Ontario College Diploma","Ontario College Certificate"]
  },
  {
    id: 10,
    heading: "Health & Medicine",
    para: "Studying health and medicine abroad offers a transformative educational experience, blending academic rigor with cultural immersion and global perspectives. Students gain access to world-class institutions renowned for their innovative research, cutting-edge technology, and esteemed faculty. Beyond the classroom, they navigate diverse healthcare systems, gaining firsthand insights into various medical practices and public health challenges. This international journey not only broadens academic horizons but also cultivates cultural competency, empathy, and a deeper understanding of global health disparities. Ultimately, studying health and medicine abroad equips students with the skills, knowledge, and perspective needed to make meaningful contributions to healthcare on a global scale.",
    image: course_health_medicine,
    why_list: ["Access to diverse medical practices and healthcare systems, broadening understanding and adaptability.", "Exposure to global health challenges, fostering empathy and a broader perspective.", "Opportunities for international networking, enhancing career prospects and professional growth."],
    course_list: ["UG","PG","Apprenticeship","BA","MA","Degree","Degree with Foundation Year","Masters","BSc","MChem","MSci","Honours program","Honours Bachelors Degree","Master degree","Graduate Diploma","Graduate Certificate","Diploma","Associate Degree","Bachelor degree (honours)","Expert master degree","Double degree","BSc (Hons)DEGREE APPRENTICESHIP"]
  },
  {
    id: 11,
    heading: "Building & Architecture",
    para: "Studying building and architecture abroad offers a dynamic blend of academic excellence, cultural immersion, and hands-on learning. Students delve into the rich history and contemporary innovations of architectural design, urban planning, and construction techniques. They gain access to renowned institutions equipped with state-of-the-art facilities and expert faculty. Beyond the classroom, studying abroad exposes students to diverse architectural styles, cultural influences, and societal needs, providing invaluable insights into the complexities of designing for different communities. This international experience not only nurtures creativity and technical proficiency but also cultivates cross-cultural understanding and global perspectives essential for addressing the evolving challenges of the built environment.",
    image: course_architec,
    why_list: ["Immersive cultural experiences, providing firsthand insights into architectural heritage and urban environments.", "Opportunities for hands-on learning and practical application of architectural principles in different contexts.", "Access to cutting-edge research and innovative approaches to sustainable design and construction practices."],
    course_list: ["UG","PG","MA","BA","MSc","BSc","Diploma","Degree with foundation year"]
  },
  {
    id: 12,
    heading: "Economics",
    para: "Studying economics abroad offers students a unique opportunity to gain a comprehensive understanding of global markets, financial systems, and economic theories within an international context. This experience provides access to renowned universities with distinguished faculty and advanced research facilities, fostering academic excellence. By engaging with diverse cultures and economic practices, students develop critical thinking skills and a deeper appreciation for the interconnectedness of global economies. Additionally, studying abroad enhances cultural competency, language skills, and international networking opportunities, equipping graduates with the knowledge and adaptability needed to navigate and succeed in an increasingly globalized economic landscape.",
    image: course_economics,
    why_list: ["Immersion in different cultural and economic environments, fostering adaptability and real-world application of economic theories.", "Enhanced language skills through study in multilingual settings, improving communication in global markets.", "Participation in international internships and practical experiences, providing hands-on learning and professional growth."],
    course_list: ["UG","PG","MA","BA","MSc","BSc","Diploma","Degree with foundation year"]
  },
  {
    id: 13,
    heading: "Welfair Studies & Services",
    para: "Studying Welfare Studies and Services abroad offers a transformative educational experience that combines academic rigor with practical, real-world applications. Students gain access to top-tier universities and institutions known for their innovative research and expert faculty in social welfare, public policy, and human services. Immersing in diverse cultural and social contexts, students develop a comprehensive understanding of global welfare systems, policies, and practices. This international perspective not only broadens their academic horizons but also enhances their ability to address complex social issues with empathy and cultural sensitivity. By studying abroad, students are equipped with the skills, knowledge, and global network necessary to make a meaningful impact in the field of welfare and human services.",
    image: course_welfair,
    why_list: ["Immersion in different cultural and social contexts, fostering empathy and cultural sensitivity.", "Enhanced language skills and communication abilities, essential for global welfare work.", "Development of a global professional network, expanding career opportunities and collaborations."],
    course_list: ["UG","PG","MA","BA","MSc","BSc","Diploma","Degree with foundation year"]
  },
  {
    id: 14,
    heading: "Environmental Science",
    para: "Studying Environmental Science abroad offers students a unique opportunity to engage with diverse ecosystems, environmental policies, and sustainability practices on a global scale. This international educational experience provides access to leading universities and research institutions renowned for their innovative approaches to environmental challenges. By studying in different cultural and ecological settings, students gain a deeper understanding of global environmental issues and the interconnectedness of natural and human systems. This immersion fosters critical thinking, problem-solving skills, and a broadened perspective essential for addressing the complex environmental challenges of the 21st century. Additionally, studying abroad enhances students' cultural competency, international collaboration skills, and prepares them for impactful careers in environmental science and sustainability.",
    image: course4,
    why_list: ["Study in diverse ecosystems, gaining firsthand experience with varied environmental challenges and conservation efforts.", "Access to international research projects and cutting-edge technologies in environmental science.", "Exposure to global environmental policies and sustainability practices, enhancing understanding and advocacy skills."],
    course_list: ["UG","PG","MA","BA","MSc","BSc","Diploma","Degree with foundation year"]
  },
  {
    id: 15,
    heading: "Law & Legal Studies",
    para: "Studying Law and Legal Studies abroad offers students an enriching educational journey that combines rigorous academic training with a global perspective. International law programs provide access to prestigious universities, expert faculty, and a diverse curriculum that encompasses various legal systems and traditions. This experience allows students to immerse themselves in different legal cultures, enhancing their understanding of international law, human rights, and comparative legal practices. Beyond academics, studying law abroad fosters critical thinking, analytical skills, and cross-cultural communication, all of which are essential for a successful legal career in an increasingly globalized world. By studying law abroad, students not only gain comprehensive legal knowledge but also develop the cultural sensitivity and adaptability necessary to navigate complex legal landscapes worldwide.",
    image: course_law,
    why_list: ["Exposure to diverse legal systems and practices, broadening comparative law understanding.", "Opportunities to learn international law and human rights in a global context.", "Development of a global professional network, enhancing career prospects and cross-border legal expertise."],
    course_list: ["UG","PG","MA","BA","MSc","BSc","Diploma","Degree with foundation year"]
  },
  {
    id: 16,
    heading: "Media & Communication",
    para: "Studying Media and Communication abroad offers a dynamic educational experience that combines theoretical knowledge with practical skills in a global context. International media programs provide access to cutting-edge technology, expert faculty, and diverse cultural perspectives. This experience allows students to explore various forms of media, including digital, print, and broadcast, while gaining insights into how media shapes societies and influences public discourse. By immersing themselves in different cultural and media landscapes, students develop a nuanced understanding of communication dynamics and the impact of media on diverse audiences. Studying abroad not only expands students' professional networks and career opportunities but also cultivates cross-cultural competency and adaptability, essential traits in today's interconnected media environment.",
    image: course_media,
    why_list: ["Exposure to diverse media landscapes, fostering a broader understanding of global communication dynamics.", "Access to international internships and industry connections, enhancing practical skills and career prospects.", "Immersion in different cultural contexts, fostering cross-cultural communication skills and cultural sensitivity."],
    course_list: ["UG","PG","MA","BA","MSc","BSc","Diploma","Degree with foundation year"]
  },
  {
    id: 17,
    heading: "Chemistry",
    para: "Studying Chemistry abroad offers a unique and enriching academic experience that combines cutting-edge scientific research with a global perspective. International chemistry programs provide access to state-of-the-art laboratories, renowned faculty, and innovative research opportunities in various subfields of chemistry. By studying in diverse cultural and academic environments, students gain exposure to different scientific approaches and methodologies, enhancing their problem-solving skills and critical thinking. This global experience not only broadens their scientific knowledge but also fosters cross-cultural collaboration and communication, essential for tackling complex global challenges. Additionally, studying chemistry abroad opens up a wide range of career opportunities and networks, preparing students for success in an increasingly interconnected scientific community.",
    image: course_chemistry,
    why_list: ["Access to cutting-edge research facilities and advanced scientific techniques.", "Exposure to diverse academic perspectives and innovative approaches in chemical sciences.", "Opportunities for international collaboration and networking, enhancing career prospects and professional growth."],
    course_list: ["UG","PG","MA","BA","MSc","BSc","Diploma","Degree with foundation year"]
  },
  {
    id: 18,
    heading: "Physics",
    para: "Studying Physics abroad offers an exceptional educational experience, blending rigorous academic coursework with exposure to cutting-edge research and diverse scientific perspectives. International physics programs provide access to world-class universities, state-of-the-art laboratories, and distinguished faculty members who are leaders in their fields. By immersing themselves in different cultural and academic environments, students gain a deeper understanding of fundamental and applied physics, enhancing their analytical and problem-solving skills. This global experience not only expands their scientific knowledge but also fosters cross-cultural collaboration and communication, crucial for addressing complex physical phenomena and global scientific challenges. Additionally, studying physics abroad opens up a wealth of career opportunities and professional networks, preparing students for successful careers in academia, industry, and research on an international scale.",
    image: course_physics,
    why_list: ["Access to advanced research facilities and groundbreaking scientific projects.", "Exposure to diverse theoretical approaches and experimental techniques.", "Opportunities for international collaboration and networking, enhancing career development and global scientific engagement."],
    course_list: ["UG","PG","MA","BA","MSc","BSc","Diploma","Degree with foundation year"]
  },
  {
    id: 19,
    heading: "Teaching & Education",
    para: "Studying Teaching and Education abroad provides a transformative educational experience that combines theoretical knowledge with practical insights in a global context. International education programs offer access to renowned universities, innovative teaching methodologies, and diverse classroom environments. By engaging with different educational systems and cultures, students gain a broader perspective on pedagogical practices and educational challenges. This experience enhances their ability to adapt teaching strategies to various cultural and social contexts, fostering inclusivity and effective communication. Additionally, studying abroad enriches personal growth, cultural competency, and global networking opportunities, equipping future educators with the skills and knowledge needed to excel in an increasingly interconnected world.",
    image: course_teacher,
    why_list: ["Exposure to diverse educational systems and teaching methodologies, broadening pedagogical skills.", "Opportunities to learn from leading education experts and innovative programs.", "Enhanced cultural competency and adaptability, essential for teaching in multicultural environments."],
    course_list: ["UG","PG","MA","BA","MSc","BSc","Diploma","Degree with foundation year"]
  },
  {
    id: 20,
    heading: "Performing Arts & Music",
    para: "Studying Performing Arts and Music abroad offers a vibrant and enriching educational experience that blends creative expression with cultural immersion. International programs in these fields provide access to prestigious conservatories, theaters, and music schools, where students can learn from renowned artists and instructors. By studying in different cultural settings, students gain diverse perspectives on artistic traditions and contemporary practices, enhancing their creativity and performance skills. This global exposure fosters collaboration, innovation, and a deeper appreciation for the arts' role in different societies. Additionally, studying abroad opens doors to unique performance opportunities and professional networks, preparing students for successful and versatile careers in the international arts scene.",
    image: course_arts,
    why_list: ["Exposure to diverse artistic traditions and styles, enriching creative expression and performance techniques.", "Opportunities to study with renowned international artists and instructors, enhancing artistic development.", "Access to unique performance opportunities and cultural experiences, broadening professional horizons and networks."],
    course_list: ["UG","PG","MA","BA","MSc","BSc","Diploma","Degree with foundation year"]
  },
  {
    id: 21,
    heading: "Info-Science & Libarianship",
    para: "Studying Information Science and Librarianship abroad offers a comprehensive educational experience that integrates advanced technology, information management, and cultural perspectives. International programs provide access to cutting-edge research, innovative information systems, and esteemed faculty members, equipping students with the latest skills and knowledge in the field. Immersing in diverse academic and cultural environments allows students to explore different approaches to information organization, digital libraries, and knowledge dissemination. This global experience not only enhances their technical proficiency and critical thinking but also fosters cross-cultural understanding and collaboration. Additionally, studying abroad broadens career opportunities and professional networks, preparing students to excel in the dynamic and evolving landscape of information science and librarianship on a global scale.",
    image: course_info,
    why_list: ["Exposure to diverse information management systems and practices, enhancing adaptability and innovation.", "Opportunities to study cutting-edge technologies and methodologies in leading international institutions.", "Development of a global professional network, broadening career prospects and collaborative opportunities."],
    course_list: ["UG","PG","MA","BA","MSc","BSc","Diploma","Degree with foundation year"]
  },
  {
    id: 22,
    heading: "Language & Culture",
    para: "Studying Language and Culture abroad provides a transformative educational experience that goes beyond language proficiency to encompass a deep understanding of diverse cultural perspectives. International language programs offer immersive language learning opportunities coupled with cultural immersion experiences. By studying in different cultural contexts, students gain firsthand insights into the nuances of language usage, social norms, and cultural practices. This global exposure not only enhances linguistic skills but also fosters cross-cultural understanding, empathy, and adaptability. Additionally, studying abroad offers opportunities for personal growth, international networking, and a broader worldview, preparing students to thrive in an increasingly interconnected and multicultural world.",
    image: course_culture,
    why_list: ["Immersive language learning in authentic cultural contexts, enhancing fluency and cultural competency.", "Access to diverse cultural experiences and perspectives, fostering a deeper understanding of global societies.", "Opportunities for cross-cultural interaction and networking, enriching personal growth and global perspectives."],
    course_list: ["UG","PG","MA","BA","MSc","BSc","Diploma","Degree with foundation year"]
  },
  {
    id: 23,
    heading: "Political & Social Science",
    para: "Studying Political and Social Science abroad presents an exciting opportunity to explore the intricate dynamics of governance, societal structures, and human behavior on a global scale. International programs in these fields provide access to world-class education, renowned scholars, and diverse perspectives that enrich understanding. By immersing oneself in different cultural and political environments, students gain invaluable insights into the complexities of global issues and the interconnectedness of societies. This international exposure not only broadens intellectual horizons but also nurtures critical thinking, empathy, and cross-cultural communication skills essential for effective engagement in today's diverse and interconnected world.",
    image: course_social,
    why_list: ["Immersive learning experiences in different cultural and political contexts, fostering adaptability and global perspectives.", "Access to unique fieldwork and internship opportunities, providing practical insights into real-world social and political dynamics.", "Development of a global professional network, expanding career prospects and collaborative opportunities in diverse sectors."],
    course_list: ["UG","PG","MA","BA","MSc","BSc","Diploma","Degree with foundation year"]
  },
  {
    id: 24,
    heading: "Art & Design",
    para: "Studying Art and Design abroad offers an inspiring and enriching educational experience that combines creative exploration with cultural immersion. International programs in these fields provide access to world-class institutions, renowned faculty, and diverse artistic perspectives. By studying in different cultural contexts, students gain a deeper understanding of art history, contemporary trends, and diverse artistic practices. This international exposure not only fosters creativity and innovation but also promotes cross-cultural understanding and appreciation. Additionally, studying abroad offers opportunities for personal growth, artistic experimentation, and networking with fellow creatives from around the globe, preparing students for successful careers in the dynamic and interconnected world of art and design.",
    image: course_design,
    why_list: ["Exposure to diverse artistic traditions and cultural influences, inspiring creativity and innovation.", "Access to world-renowned art institutions and expert mentors, fostering artistic growth and development.", "Opportunities for cross-cultural collaboration and networking, expanding artistic horizons and professional opportunities."],
    course_list: ["UG","PG","MA","BA","MSc","BSc","Diploma","Degree with foundation year"]
  },
  {
    id: 25,
    heading: "Geography",
    para: "Studying Geography abroad offers an exciting educational journey that combines exploration of the earth's physical and cultural landscapes with cross-cultural immersion. International geography programs provide access to diverse environments, renowned faculty, and cutting-edge research opportunities. By studying in different geographical contexts, students gain a comprehensive understanding of global patterns, processes, and human-environment interactions. This international exposure not only enhances spatial analysis and critical thinking skills but also fosters cross-cultural understanding and appreciation. Additionally, studying abroad offers opportunities for fieldwork, experiential learning, and networking with geographers from around the world, preparing students for meaningful careers in various sectors, from environmental conservation to urban planning and beyond.",
    image: course_geography,
    why_list: ["Access to diverse geographical environments and landscapes, enriching fieldwork experiences and research opportunities.", "Exposure to different cultural perspectives on environmental issues, enhancing cross-disciplinary understanding and collaboration.", "Opportunities for international networking and collaboration, broadening career prospects and global perspectives in the field of geography."],
    course_list: ["UG","PG","MA","BA","MSc","BSc","Diploma","Degree with foundation year"]
  },
  {
    id: 26,
    heading: "History",
    para: "Studying History abroad offers an enriching educational experience that delves into the complexities of human civilization, cultural heritage, and global perspectives. International history programs provide access to renowned institutions, esteemed scholars, and diverse historical narratives. By studying in different cultural and historical contexts, students gain a comprehensive understanding of the past, its impact on the present, and its implications for the future. This international exposure not only fosters critical thinking and analytical skills but also promotes cross-cultural understanding and empathy. Additionally, studying abroad offers opportunities for firsthand research, archival exploration, and cultural immersion, preparing students for meaningful careers in academia, public service, and various sectors where historical knowledge is valued.",
    image: course_history,
    why_list: ["Access to diverse historical archives and resources, enriching research opportunities and academic exploration.", "Exposure to different cultural perspectives and interpretations of historical events, broadening understanding and analysis.", "Opportunities for international collaboration and networking with historians from around the world, expanding career prospects and scholarly connections."],
    course_list: ["UG","PG","MA","BA","MSc","BSc","Diploma","Degree with foundation year"]
  },
  {
    id: 27,
    heading: "Marketing Media & Communications",
    para: "Studying Marketing, Media & Communications abroad opens doors to a dynamic educational journey that blends theoretical insights with practical applications in a global setting. International programs provide access to cutting-edge technology, diverse cultural perspectives, and renowned industry experts. By immersing in different cultural and media environments, students gain a comprehensive understanding of consumer behavior, media trends, and effective communication strategies. This international exposure not only sharpens their analytical and creative skills but also fosters cross-cultural understanding and adaptability, essential in today's interconnected world. Moreover, studying abroad offers invaluable opportunities for personal growth, international networking, and professional development, preparing students for rewarding careers in the rapidly evolving field of marketing, media, and communications.",
    image: course_communication,
    why_list: ["Exposure to diverse cultural perspectives and media landscapes, fostering creativity and innovation in marketing strategies.", "Access to international networks and industry connections, enhancing career opportunities and global market insights.", "Opportunities for cross-cultural collaboration and hands-on experience, refining communication skills and adaptability in diverse settings."],
    course_list: ["UG","PG","MA","BA","MSc","BSc","Diploma","Degree with foundation year"]
  },
  {
    id: 28,
    heading: "Mathematics",
    para: "Studying Mathematics abroad offers an exciting educational opportunity that blends theoretical knowledge with practical applications in a global context. International mathematics programs provide access to esteemed institutions, renowned faculty, and diverse mathematical perspectives. By studying in different cultural and academic environments, students gain a comprehensive understanding of mathematical concepts, problem-solving techniques, and applications across various fields. This international exposure not only enhances analytical and critical thinking skills but also fosters cross-cultural communication and collaboration. Additionally, studying abroad offers opportunities for personal growth, international networking, and research collaboration, preparing students for successful careers in academia, industry, and research on a global scale.",
    image: course_maths,
    why_list: ["Access to diverse mathematical perspectives and teaching methodologies, broadening understanding and problem-solving skills.", "Exposure to international research collaborations and opportunities, enhancing academic growth and career prospects.", "Opportunities for cross-cultural interaction and networking with mathematicians from around the world, fostering collaboration and exchange of ideas."],
    course_list: ["UG","PG","MA","BA","MSc","BSc","Diploma","Degree with foundation year"]
  },
  {
    id: 29,
    heading: "Agriculture Science",
    para: "Studying Agriculture Science abroad offers a rich educational experience that combines scientific knowledge with hands-on learning in diverse agricultural settings. International programs provide access to cutting-edge research facilities, expert faculty, and a wide range of agricultural landscapes. By studying in different countries, students gain insights into various agricultural practices, crop management techniques, and sustainable farming methods. This global exposure not only enhances their understanding of global food systems and environmental sustainability but also fosters cross-cultural understanding and collaboration. Additionally, studying abroad offers opportunities for fieldwork, internships, and research projects, preparing students for careers in agriculture, agribusiness, and environmental science on an international scale.",
    image: course_agriculture,
    why_list: ["Exposure to diverse agricultural practices and climates, enriching practical knowledge and adaptability.", "Access to international research collaborations and cutting-edge technologies, enhancing academic growth and innovation.", "Opportunities for cross-cultural exchange and networking with experts from around the world, fostering collaboration and global perspectives in agricultural science."],
    course_list: ["UG","PG","MA","BA","MSc","BSc","Diploma","Degree with foundation year"]
  },
  {
    id: 30,
    heading: "Information Science",
    para: "Studying Information Science abroad offers an enriching educational experience that combines theoretical knowledge with practical applications in a global context. International programs provide access to cutting-edge technology, expert faculty, and diverse cultural perspectives. By immersing themselves in different cultural and academic environments, students gain a comprehensive understanding of information management, digital technologies, and their impact on society. This global exposure not only enhances their technical skills but also fosters cross-cultural communication and adaptability, essential for navigating today's digital world. Additionally, studying abroad offers opportunities for personal growth, international networking, and a broader worldview, preparing students for successful careers in information science, technology, and related fields on an international scale.",
    image: course_information,
    why_list: ["Exposure to diverse technological landscapes and digital environments, fostering adaptability and innovation in information management.", "Access to international research collaborations and cutting-edge advancements in information science, enhancing academic growth and professional development.", "Opportunities for cross-cultural exchange and networking with experts from around the world, expanding career prospects and global perspectives in the field of information science."],
    course_list: ["UG","PG","MA","BA","MSc","BSc","Diploma","Degree with foundation year"]
  },
  {
    id: 31,
    heading: "Biological & Life Science",
    para: "Studying Biological and Life Sciences abroad offers an immersive educational journey that delves into the complexities of living organisms and their environments on a global scale. International programs provide access to leading research facilities, esteemed faculty, and diverse ecosystems. By studying in different countries, students gain firsthand experience with diverse species, ecosystems, and research methodologies. This global exposure not only enhances their understanding of biological processes but also fosters cross-cultural collaboration and environmental stewardship. Additionally, studying abroad offers opportunities for fieldwork, internships, and research projects, preparing students for careers in biology, ecology, conservation, and related fields with a global perspective.",
    image: course_bio,
    why_list: ["Immersive learning experiences in different cultural and environmental contexts, fostering adaptability and global perspectives.", "Access to unique fieldwork and research opportunities, providing hands-on experience with diverse species and ecosystems.", "Development of a global professional network, expanding career prospects and collaborative opportunities in biological and life sciences."],
    course_list: ["UG","PG","MA","BA","MSc","BSc","Diploma","Degree with foundation year"]
  },
]


export default function Course_Detail() {
  // const [modalOpen, setModalOpen] = useState(false);

  //   useEffect(() => {
  //     setModalOpen(true);
  //   }, []);
  const { id } = useParams();
  const course = data.find((course) => course.id === parseInt(id));
  return (
    <div className="mainDiv">
      <div className="Navbar">
        <Navbar />
      </div>
      {/* <ModalEvent isOpenInitially={modalOpen} /> */}
      <br />
      <br />
      <div className="container">
        <Wrapper>
          <MainTitle>{course.heading}</MainTitle>
          <FlexRow>
            <div>
              <div>
                <p className='para'>{course.para}</p>
                <Title>Why Abroad ?</Title>
                <SubTitle>Studying abroad offers unique advantages for those interested in {course.heading}:</SubTitle>
                {course.why_list.map((list, i) => {
                  return (
                    <ul key={i}>
                      <li style={{ color: "#879b8f", textAlign: "left" }}>{list}</li>
                    </ul>
                  )
                })}
              </div>
            </div>
            <img className='img' src={course.image} alt='' />
          </FlexRow>
        </Wrapper>
        <SubWrapper>
          <Title>Scope & Benefits</Title>
          <ListWrapper>
            <div className='flex-row'><i style={{ color: "#38b772" }} className="fa fa-check-circle-o text" /><span className='text'>The scope of abroad studies opens doors to a wide range of academic disciplines and career paths.</span></div>
            <div className='flex-row'><i style={{ color: "#38b772" }} className="fa fa-check-circle-o text" /><span className='text'>Immersion in diverse cultures fosters understanding, empathy, and appreciation for global diversity.</span></div>
            <div className='flex-row'><i style={{ color: "#38b772" }} className="fa fa-check-circle-o text" /><span className='text'>Exposure to foreign languages enhances communication skills and opens up international job opportunities.</span></div>
            <div className='flex-row'><i style={{ color: "#38b772" }} className="fa fa-check-circle-o text" /><span className='text'>Access to top-tier universities and educational resources enhances academic growth and achievement.</span></div>
            <div className='flex-row'><i style={{ color: "#38b772" }} className="fa fa-check-circle-o text" /><span className='text'>International experience and a global perspective are highly valued by employers in today's interconnected world.</span></div>
            <div className='flex-row'><i style={{ color: "#38b772" }} className="fa fa-check-circle-o text" /><span className='text'>Overcoming challenges in a foreign environment fosters resilience, independence, and self-discovery.</span></div>
            <div className='flex-row'><i style={{ color: "#38b772" }} className="fa fa-check-circle-o text" /><span className='text'>Building connections with peers, professors, and professionals from around the world expands one's global network.</span></div>
            <div className='flex-row'><i style={{ color: "#38b772" }} className="fa fa-check-circle-o text" /><span className='text'>Experiencing different cultural, social, and economic contexts broadens one's worldview and critical thinking abilities.</span></div>
            <div className='flex-row'><i style={{ color: "#38b772" }} className="fa fa-check-circle-o text" /><span className='text'>Developing a sense of responsibility and engagement with global issues prepares individuals to be effective global citizens.</span></div>
          </ListWrapper>
        </SubWrapper>
        <SubWrapper>
          <Title>Top Courses</Title>
          <ListWrapper>
            {course.course_list.map((list, i) => {
              return (
                <div className='flex-row' key={i}>
                  <span class="material-icons-outlined text">description</span>
                  <span className='text'>{list}</span>
                </div>
              )
            })}
          </ListWrapper>
        </SubWrapper>
      </div>
      <Footer />
    </div>
  )
}
const Wrapper = styled.div`
  margin-top: 120px;
`;
const MainTitle = styled.h1`
font-size: 35px;
font-weight: 700;
color: #38b772;
text-align:center;
`;
const FlexRow = styled.div`;
padding:60px 0;
display:flex;
flex-direction:row;
gap:20px;
@media (max-width: 768px){
  flex-direction:column;
}
.para {
  text-align:left;
  color: #879b8f;
  line-height:30px;

}
.img {
  width: 40%;
  border-radius: 12px;
  @media (max-width: 1024px) {
    width:45%;
  }
  @media (max-width: 768px) {
    width: 100%;
  }
}
`;
const SubWrapper = styled.div`
padding:60px 0;
`;
const Title = styled.h1`
color:#494949;
font-weight:600;
text-align:left;
font-size: 35px;
`;
const SubTitle = styled.p`
color: #879b8f;
text-align:left;
margin-top:30px;
`;
const ListWrapper = styled.div`
display:flex;
flex-direction:column;
gap:20px;
margin-top:30px;
.flex-row{
  display:flex;
  flex-direction:row;
  gap:20px;
  align-items:center;
}
.text {
  color: #879b8f;
  text-align:left;
}
`;