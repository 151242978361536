import React from "react";
import styled from "styled-components";
import {
  australia,
  canada,
  china,
  class_color,
  france,
  georgia,
  ireland,
  sweden,
  switzerland,
  uk,
  us,
} from "../../assets/images";
import { Link } from "react-router-dom";
let countryImage = [
  { id: 1, un: australia, flag: "Australia", courses: 3061, university: 41 },
  { id: 2, un: uk, flag: "United Kingdom", courses: 3612, university: 89 },
  { id: 3, un: us, flag: "United States", courses: 2000, university: 20 },
  { id: 4, un: switzerland, flag: "Switzerland", courses: 5, university: 2 },

];
export default function Countries() {
  return (
    <Wrapper>
      <div className="row">
        <div className="rowDiv1">
          <h1 className="title">
            Choose Your{" "}
            <span className="text-aeron">Favourite Destination</span>!
          </h1><br />
          <span className="para">
            It is a long established fact that a reader will be distracted by
            the readable content of a page when looking at its layout. The point
            of using Lorem Ipsum is that it has a more-or-less normal
            distribution of letters, as opposed to using 'Content here, content
            here',making it look like readable English. Many desktop publishing
            packages and web page editors now use Lorem Ipsum as their default
            model text, and a search for 'lorem ipsum' will uncover many web
            sites still in their infancy.
          </span>
        </div>
        <img className="img" src={class_color} alt="" />
      </div>
      <FlexRow>
        {countryImage.map((item, i) => {
          return (
            <div className="border-box">
              <img key={i} className="un-img" src={item.un} alt="" />
              <div className="column">
                <div style={{ display: "flex", flexDirection: "row", gap: "15px", alignItems: "center", }}>
                  <div className="flex-row">
                    <i class="fa fa-file-text-o" style={{ color: "#38b772" }} />
                    <span className="text">{item.courses} Courses</span>
                  </div>
                  <div className="flex-row">
                    <i class="fa fa-university" style={{ color: "#38b772" }} />
                    <span className="text">{item.university} University</span>
                  </div>
                </div>
                <span className="text" style={{ fontWeight: 700, fontSize: "20px" }}>{item.flag}</span>
                <ViewBtn to={`/country/${item.id}`}>
                  <span>Explore</span>
                  <span className="material-icons">arrow_forward</span>
                </ViewBtn>
              </div>
            </div>
          );
        })}
      </FlexRow>
      <br /><br />
      <Button to="/country/country">More Countries</Button>
    </Wrapper>
  );
}
const Wrapper = styled.div`
  // margin-top: 60px;
  padding: 20px;
  .row {
    display: flex;
    flex-direction: row;
    // justify-content: center;
    // gap: 25px;
    align-items: center;
    @media (max-width: 768px) {
      flex-direction: column;
    }
  }
  .rowDiv1 {
    width: 50%;
    text-align: left;
    @media (max-width: 768px) {
      width: 100%;
    }
  }
  .img {
    width: 50%;
    @media (max-width: 768px) {
      width: 100%;
    }
  }
  .title {
    font-size: 45px;
    font-weight: 700;
    color: #494949;
    @media (max-width: 576px) {
      font-size: 35px;
    }
  }
  .text-aeron {
    color: #38b772;
    font-size: 45px;
    font-weight: 700;
    @media (max-width: 576px) {
      font-size: 35px;
    }
  }
  .para {
    font-size: 16px;
    font-weight: 400;
    color: #879b8f;
    line-height: 30px;
  }
`;
const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  .border-box {
    border-radius: 12px;
    border: 1px solid #879b8f;
    padding: 25px;
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: center;
    @media (max-width: 576px) {
      width:100%;
      flex-direction:column;
    }
    .column {
      display: flex;
    flex-direction: column;
    gap:10px;
    }
    .flex-row {
      display: flex;
    flex-direction: row;
    gap:10px;
    align-items:center;
    color: #879b8f;
    @media (max-width: 576px) {
      flex-direction:column;
    }
    }
  }
  .text {
    font-size: 16px;
    color: #879b8f;
  }
  .un-img {
    width: 150px;
    height:150px;
    border-radius:50%;
  }
`;
const ViewBtn = styled(Link)`
display: flex;
  align-items: center;
  justify-content: center;
  background-color: #38b772;
  color: #ffffff;
  padding: 8px 15px;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s;
  text-decoration:none;
  span.material-icons {
    font-size: 16px;
    margin-left: 5px;
  }
  &:hover {
    background-color: #228f58;
  }
  filter: brightness(100%);
`;

const Button = styled(Link)`
  cursor: pointer;
  background-color: #38b772;
  color: #fff;
  border-radius: 10px;
  padding: 10px 20px;
  width: fit-content;
  border: 0px solid;
  text-decoration: none;
  box-shadow: inset 0 0 0 0 #38b772;
  transition: color .5s ease-in-out, box-shadow .5s ease-in-out;
  &:hover {
    background-color: #fff;
    border: 1px solid #38b772;
    color: #38b772;
    box-shadow: inset 200px 0 0 0 #fff;
  }
`;