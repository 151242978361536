import React from 'react';
import styled from 'styled-components';
import { founder, team1, team2, team3, team4 } from '../../assets/images';

let data = [
  {
    "id": 1,
    "name": "Shahil P",
    "post": "Founder & CEO",
    "img": team1,
    "sub": "Visionary leader with a passion for innovation."
  },
  {
    "id": 2,
    "name": "Thahani V",
    "post": "Director",
    "img": team2,
    "sub": "Strategic thinker with a creative edge."
  },
  {
    "id": 3,
    "name": "Adhil",
    "post": "Manager",
    "img": team3,
    "sub": "Efficiency expert with a problem-solving mindset."
  },
  {
    "id": 4,
    "name": "Saneen",
    "post": "Admin",
    "img": team4,
    "sub": "Organizational guru making workspaces a joy."
  }
]
export default function ProfileCard() {
  return (
    <>{data.map((item, i) => {
      return (
        <Wrapper key={i}>
          <PImage src={item.img} alt='' />
          <div>
            <Name_Position>{item.name}</Name_Position>
            <Name_Position>{item.post}</Name_Position>
          </div>
          <Line />
          <Sub>{item.sub}</Sub>
        </Wrapper>
      )
    })}</>
  )
}
const Wrapper = styled.div`
display:flex;
flex-direction:column;
gap:20px;
align-items:center;
`;
const PImage = styled.img`
height:150px;
width:150px;
border-radius:50%;
`;
const Name_Position = styled.h1`
font-size:20px;
font-weight:600;
color:#879b8f;
margin:5px 0;
`;
const Line = styled.div`
width:100%;
height:3px;
border-radius:12px;
background-color:#38b772;
`;
const Sub = styled.span`
font-size:16px;
font-weight:400;
color:#879b8f;
width:200px;
@media (max-width: 576px){
  width:100%;
}
`;