import React, { useState } from 'react';
import styled from 'styled-components';
import InputBox from '../InputBox/InputBox';
import SelectNavbar from '../SelectBox/SelectNavbar';
import SelectBox from '../SelectBox/SelectBox';

const countryData = [
  { id: 1, label: "Australia" },
  { id: 2, label: "United Kingdom" },
  { id: 3, label: "United States" },
  { id: 4, label: "Switzerland" },
  { id: 5, label: "Sweden" },
  { id: 6, label: "Ireland" },
  { id: 7, label: "Georgia" },
  { id: 8, label: "France" },
  { id: 8, label: "China" },
  { id: 8, label: "Canada" },
];
export default function Support({ isOpen, isClose }) {
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const [country, setCountry] = useState(null);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleSendEmail = () => {
    const recipientEmail = "saneen577@gmail.com"; // Update with the recipient's email address
    const subject = "New Message from contact us page";
    const body = `Name: ${formData.name}\nEmail: ${formData.email}\nPhone: ${formData.phone}\Country: ${country}`;

    // Open default email client with pre-filled email template
    window.open(
      `mailto:${recipientEmail}?subject=${encodeURIComponent(
        subject
      )}&body=${encodeURIComponent(body)}`
    );
    // Clear form data
    setFormData({
      name: "",
      email: "",
      phone: "",
    });
    setCountry(null);

    // Optionally, you can redirect or perform any other action after sending the email
  };
  return (
    <Wrapper isOpen={isOpen}>
      <Header>
        <CloseIcon onClick={isClose} className="material-icons">cancel</CloseIcon>
        <span className='title'>Book Appoinment</span>
      </Header>
      <div style={{ borderBottom: "1px solid #879b8f" }} />
      <Footer>
        <div className="row">
          <div className="col-12">
            <InputBox
              type="text"
              placeholder="Name"
              value={formData.name}
              onChange={handleChange}
              name="name"
            />
          </div>
          <div className="col-12">
            <InputBox
              type="text"
              placeholder="Phone"
              value={formData.phone}
              onChange={handleChange}
              name="phone"
            />
          </div>
          <div className="col-12">
            <InputBox
              type="email"
              placeholder="Enter Your Email"
              value={formData.email}
              onChange={handleChange}
              name="email"
            />
          </div>
          <div className="col-12" style={{ marginTop: "10px" }}>
            <SelectBox
              placeholder="Prefered Country"
              data={countryData}
              value={country}
              onSelect={setCountry}
            />
          </div>
        </div>
      </Footer>
      <div style={{ padding: 20 }}>
        <Button onClick={handleSendEmail}>Submit</Button>
      </div>
    </Wrapper>
  )
}
const Wrapper = styled.div`
width: 400px;
  height: auto;
  background-color: #fff;
  position: fixed;
  top: 20%;
  display:${(p) => (p.isOpen ? "block" : "none")};
  right: 0;
  transition: right 0.5s ease-in-out;
  z-index: 999;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-top-left-radius:12px;
  border-bottom-left-radius:12px;
  @media (max-width: 768px) {
    width:60%;
    top:17%;
  }
  @media (max-width: 576px) {
    width:90%;
    top:20px;
  }
`;
const Header = styled.div`
display:flex;
flex-direction:row;
justify-content:space-between;
padding:20px;
.title {
  font-size:20px;
color:#494949;
}
`;
const Footer = styled.div`
display:flex;
flex-direction:column;
gap:20px;
padding:20px;
`;
const CloseIcon = styled.span`
color:red;
font-size:30px;
font-weight:700;
text-align:left;
cursor:pointer;
`;
const Button = styled.button`
  cursor: pointer;
  background-color: #38b772;
  color: #fff;
  border-radius: 10px;
  padding: 10px 20px;
  width: 100%;
  border: 0px solid;
  box-shadow: inset 0 0 0 0 #38b772;
  transition: color .5s ease-in-out, box-shadow .5s ease-in-out;
  &:hover {
    background-color: #fff;
    border: 1px solid #38b772;
    color: #38b772;
    box-shadow: inset 200px 0 0 0 #fff;
  }
`;
