import React from 'react';
import styled from 'styled-components';
import { Footer, Navbar, ProfileCard } from '../../components';
import { founder } from '../../assets/images';

export default function Team() {
    // const [modalOpen, setModalOpen] = useState(false);

    // useEffect(() => {
    //   setModalOpen(true);
    // }, []);
    return (
        <div className="mainDiv">
            <div className="Navbar">
                <Navbar />
            </div><br /><br />
            {/* <ModalEvent isOpenInitially={modalOpen} /> */}
            <div className='container'>
                <Wrapper>
                    <Img src={founder} alt='' />
                    <Content>
                        <h1>Our Mission at Aeron Education</h1>
                        <h2>Empowering Futures Globally</h2>
                        <p>At Aeron Education Consultancy, our vision is rooted in the belief
                            that education knows no borders. As the founder, I am passionate about
                            providing students with the tools and guidance they need to explore
                            the world through learning. Our consultancy stands as a beacon for
                            those who dare to dream beyond their horizons, offering comprehensive
                            support to navigate the intricate process of studying abroad.</p>
                        <p>Our team is dedicated to fostering a community where aspirations take flight.
                            We understand the transformative power of international education, and
                            our mission is to empower individuals to embrace diverse cultures, languages,
                            and experiences. Whether it's finding the right university, securing
                            scholarships, or ensuring a smooth transition, Aeron Education Consultancy
                            is committed to shaping tomorrow's global leaders.</p>
                    </Content>
                </Wrapper>
            </div>
            <TeamWrapper>
                <div className='container'>
                    <Title>Meet Our Team</Title>
                    <SubTitle>Guiding your journey to global education excellence with Aeron Educational Consultancy.</SubTitle><br /><br /><br />
                    <FlexRow>
                        <ProfileCard />
                    </FlexRow>
                </div><br />
            </TeamWrapper>
            <Footer />
        </div>
    )
}
const Wrapper = styled.div`
margin-top:150px;
display:flex;
flex-direction:row;
justify-content:space-between;
gap:20px;
align-items:center;
@media (max-width:768px){
    flex-direction:column;
}
`;
const Img = styled.img`
width:30%;
height:70vh;
@media (max-width: 576px){
    width:100%;
}
`;
const Content = styled.div`
width:60%;
display:flex;
flex-direction:column;
gap:20px;
text-align:left;
@media (max-width: 768px){
    width:100%;
}
h1 {
    font-size:20px;
    font-weight:400;
    color:#38b772;
    margin:0;
}
h2 {
    font-size:35px;
    font-weight:600;
    color:#494949;
    letter-spacing:2px;
}
p {
    font-size:16px;
    font-weight:400;
    color:#879b8f;
    line-height:30px;
}
`;
const TeamWrapper = styled.div`
background-color:#F4F4F4;
padding:60px 0;
margin-top:90px;
`;
const Title = styled.h1`
font-size:35px;
    font-weight:600;
    color:#38b772;
`;
const SubTitle = styled.h2`
font-size:16px;
    font-weight:400;
    color:#494949;
`;
const FlexRow = styled.div`
display:flex;
flex-direction:row;
gap:50px;
flex-wrap:wrap;
justify-content:center;
`;