import React, { useState } from "react";
import { Location, viber } from "../assets/images";
import { Navbar, Footer, InputBox, TextArea } from "../components";
import styled from "styled-components";

function ContactUs() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    city: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSendEmail = () => {
    const recipientEmail = "saneen577@gmail.com"; // Update with the recipient's email address
    const subject = "New Message from contact us page";
    const body = `Name: ${formData.name}\nEmail: ${formData.email}\nPhone: ${formData.subject}\nCity: ${formData.city}\nMessage: ${formData.message}`;

    // Open default email client with pre-filled email template
    window.open(
      `mailto:${recipientEmail}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`
    );

    // Clear form data
    setFormData({
      name: "",
      email: "",
      subject: "",
      message: "",
      city: "",
    });

    // Optionally, you can redirect or perform any other action after sending the email
  };

  return (
    <Container>
      <NavbarWrapper>
        <Navbar />
      </NavbarWrapper><br/><br/><br/><br/>
      <MainContent>
        <ContactInfo>
          <ContactItem>
            <Icon className="material-icons">location_on</Icon>
            <h2>Address</h2>
            <p>
              Aeron Education Services <br />
              Third Floor, Aysiha Commercial Building, <br />
              Perinthalmanna
            </p>
          </ContactItem>
          <ContactItem>
            <Icon className="material-icons">email</Icon>
            <h2>Email</h2>
            <p>aeroneducationpmna@gmail.com</p>
          </ContactItem>
          <ContactItem>
            <Icon className="material-icons">phone</Icon>
            <h2>Phone Number</h2>
            <p>
              <a href="tel:+919847997755">+91 8301829642</a>
              <br />
              <a href="tel:+919847997755">+91 9072004334</a>
              <br />
              <a href="tel:+919847997755">+91 9496339439</a>
            </p>
          </ContactItem>
        </ContactInfo>
        <Divider />
        <FormSection>
          <FormTitle>Get in Touch</FormTitle>
          <FormRow>
            <InputBox
              type="text"
              placeholder="Enter Your Name"
              value={formData.name}
              onChange={handleChange}
              name="name"
            />
            <InputBox
              type="text"
              placeholder="Enter Your Email"
              value={formData.email}
              onChange={handleChange}
              name="email"
            />
            <InputBox
              type="text"
              placeholder="Enter Your Subject"
              value={formData.subject}
              onChange={handleChange}
              name="subject"
            />
            <InputBox
              type="text"
              placeholder="Enter Your City"
              value={formData.city}
              onChange={handleChange}
              name="city"
            />
            <TextArea
              name="message"
              id="message"
              placeholder="Enter Message"
              value={formData.message}
              onChange={handleChange}
            />
          </FormRow>
          <SubmitButton onClick={handleSendEmail}>Submit</SubmitButton>
        </FormSection>
      </MainContent>
      <FooterWrapper>
        <Footer />
      </FooterWrapper>
    </Container>
  );
}

export default ContactUs;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const NavbarWrapper = styled.div`
  flex-shrink: 0;
`;

const MainContent = styled.div`
  flex: 1;
  padding: 20px;
  background-color: #f9f9f9;
`;

const ContactInfo = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 40px;
  flex-wrap: wrap;
  gap: 20px;
`;

const ContactItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-width: 300px;
  h2 {
    font-size: 1.5rem;
    color: #333;
  }
  p {
    font-size: 1rem;
    color: #666;
  }
  a {
    color: #38b772;
    text-decoration: none;
  }
`;

const Icon = styled.span`
  font-size: 50px;
  color: #38b772;
`;

const Divider = styled.div`
  border-bottom: 1px solid #ccc;
  margin: 40px 0;
`;

const FormSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const FormTitle = styled.h1`
  font-size: 2rem;
  color: #333;
  margin-bottom: 20px;
`;

const FormRow = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  max-width: 600px;
`;

const SubmitButton = styled.button`
  cursor: pointer;
  background-color: #38b772;
  color: #fff;
  border-radius: 10px;
  padding: 10px 20px;
  border: none;
  margin-top: 20px;
  font-size: 1rem;
  transition: background-color 0.3s ease, color 0.3s ease;
  &:hover {
    background-color: #2e8b57;
  }
`;

const FooterWrapper = styled.div`
  flex-shrink: 0;
`;
