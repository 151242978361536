import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { Navbar, Footer } from '../../components';

// Dummy data for testimonials
const testimonials = [
    {
        id: 1,
        name: 'Emily Parker',
        rating: 5,
        text: 'Studying abroad was one of the best decisions I\'ve made, and it wouldn\'t have been possible without Aeron Education. They provided expert guidance, from university selection to visa procedures, making the entire process smooth and stress-free.',
    },
    {
        id: 2,
        name: 'Michael Chen',
        rating: 4,
        text: 'Thanks to Aeron Education, I\'m now pursuing my dream of studying aviation abroad. Their team not only helped me with university applications but also provided valuable insights into the aviation industry. Highly recommend!',
    },
    {
        id: 3,
        name: 'Sophie Patel',
        rating: 5,
        text: 'Aeron Education made my study abroad journey unforgettable. Their personalized approach ensured I found the perfect university and course. Their support continued even after I arrived, making me feel at home in a new country.',
    },
    {
        id: 4,
        name: 'Carlos Ramirez',
        rating: 4.5,
        text: 'Choosing Aeron Education was the best decision I made for my aviation studies abroad. Their team of experts guided me through the entire process, from selecting the right program to preparing for my departure. I\'m grateful for their dedication.',
    },
    {
        id: 5,
        name: 'Olivia Nguyen',
        rating: 5,
        text: 'I couldn\'t have asked for better support than what I received from Aeron Education. They went above and beyond to ensure my study abroad experience was exceptional. I\'ve returned home with not just a degree but also amazing memories.',
    },
    {
        id: 6,
        name: 'Jacob Lee',
        rating: 4.8,
        text: 'Aeron Education truly cares about their students\' success. From the moment I reached out to them, they provided detailed information and guidance. I\'m now studying aerospace engineering abroad, all thanks to their assistance.',
    },
    {
        id: 7,
        name: 'Isabella Silva',
        rating: 4.7,
        text: 'Studying abroad with Aeron Education was an incredible experience. They helped me choose a university that perfectly matched my interests and career goals. I highly recommend their services to anyone looking to study aviation or aerospace abroad.',
    },
    {
        id: 8,
        name: 'Ethan Khan',
        rating: 4.2,
        text: 'I am extremely satisfied with the services provided by Aeron Education. Their team made the complex process of studying aviation abroad simple and straightforward. I am now pursuing my passion thanks to their support.',
    },
    {
        id: 9,
        name: 'Lily Wang',
        rating: 4.6,
        text: 'Aeron Education\'s expertise in aviation studies abroad is unmatched. They guided me through every step, from applications to settling into my new country. I\'m grateful for their professionalism and dedication.',
    },
];

const Review = () => {
    // const [modalOpen, setModalOpen] = useState(false);

    // useEffect(() => {
    //   setModalOpen(true);
    // }, []);
    return (
        <div className="mainDiv">
            <div className="Navbar">
                <Navbar />
            </div><br /><br />
            {/* <ModalEvent isOpenInitially={modalOpen} /> */}
            <div className='container'>
                <Wrapper>
                    <h1>Client Testimonials</h1>
                    <h2>What Our Customers Are Saying</h2>
                    <TestimonialContainer>
                        {testimonials.map((testimonial) => (
                            <Testimonial key={testimonial.id}>
                                <motion.div
                                    whileHover={{ scale: 1.05 }}
                                    whileTap={{ scale: 0.95 }}
                                >
                                    <StarRating rating={testimonial.rating} />
                                    <TestimonialText>{testimonial.text}</TestimonialText>
                                    <TestimonialAuthor>- {testimonial.name}</TestimonialAuthor>
                                </motion.div>
                            </Testimonial>
                        ))}
                    </TestimonialContainer>
                </Wrapper>
            </div>
            <Footer />
        </div>
    );
};

const Wrapper = styled.div`
    margin-top: 120px;
    h1 {
        font-size: 35px;
        font-weight: 700;
        color: #38b772;
    }
    h2 {
        font-size: 16px;
        font-weight: 400;
        color: #494949;
    }
`;

const TestimonialContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    justify-content: center;
    padding: 40px 0;
`;

const Testimonial = styled.div`
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    width: 300px;
    text-align: center;
`;

const TestimonialText = styled.p`
    font-size: 16px;
    line-height: 1.6;
    margin-bottom: 10px;
`;

const TestimonialAuthor = styled.p`
    font-size: 14px;
    font-style: italic;
    color: #888;
`;

const StarRating = ({ rating }) => {
    const stars = [];
    for (let i = 1; i <= 5; i++) {
        if (i <= rating) {
            stars.push(<span key={i} className="material-icons" style={{ color: '#f9a825' }}>star</span>);
        } else {
            stars.push(<span key={i} className="material-icons" style={{ color: '#f9a825' }}>star_border</span>);
        }
    }
    return <div>{stars}</div>;
};

export default Review;
